import React from 'react'
import Div from '@jumbo/shared/Div'
import { Typography } from '@mui/material'
import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList'
import useReportApp from '../../../hooks/useReportApp'
import ReportViewItem from '../../ReportViewItem'
import reportServices from 'app/services/pages/reports/report-services'

const OrderReportList = (props) => {
    const { salesReportListRef: orderReportListRef } = useReportApp()

    const renderReportView = React.useCallback((reportItem) => {
        return <ReportViewItem reportViewItem={reportItem} />
    })
    return (
        <React.Fragment>
            <Div
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: (theme) => theme.spacing(1.5, 2),
                    bgcolor: (theme) => theme.palette.action.hover,
                }}>
                <Typography
                    sx={{ letterSpacing: 1.5, textTransform: 'uppercase' }}
                    variant='h6'
                    color='text.secondary'
                    mb={0}>
                    <small>Order Reports</small>
                </Typography>
            </Div>
            <JumboRqList
                ref={orderReportListRef}
                service={reportServices.getOrderReportList}
                primaryKey={'id'}
                queryOptions={{
                    queryKey: 'order-report-list',
                    dataKey: 'reports',
                    queryParams: { branchType: props.branchType },
                }}
                renderItem={renderReportView}
                componentElement={'div'}
            />
        </React.Fragment>
    )
}

export default OrderReportList
