import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'

export const firebase = initializeApp({
    apiKey: process.env.REACT_APP_FirebaseApiKey, // 'AIzaSyAHnKXGazP5f--aJnYvCTnT-BEMFi4t21w', // Octopus_GcpFirebaseApiKey
    authDomain: process.env.REACT_APP_FirebaseAuthDomain, //'spiraldemo-au-id.firebaseapp.com', // Octopus_GcpFirebaseDomain
})
//const analytics = getAnalytics(app);
export const auth = getAuth(firebase)
