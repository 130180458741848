import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend, CartesianGrid } from 'recharts'
import { Chip, Typography } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import { formattedDate } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import LoaderOverlay from 'app/utils/components/LoaderOverlay'
import LoaderOverlayDashboard from 'app/utils/components/LoaderOverlayDashboard'

const BannerRight = () => {
    const [yAxisDomain, setYAxisDomain] = useState([0, 100])
    const { sale, loading } = useSelector((state) => state.dashboard)

    const formatYAxis = (tickItem) => {
        return `$${tickItem}`
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip'>
                    <p className='label'>{`${formattedDate(label)}`}</p>
                    <p className='data'>{`Weekly Sale: ${formatCurrency(payload[0].value)}`}</p>
                </div>
            )
        }

        return null
    }

    const renderTick = (props) => {
        const { x, y, payload } = props
        return (
            <text
                x={x}
                y={y}
                dy={4}
                textAnchor='end'
                style={{
                    color: 'info',
                    fontSize: 'small',
                }}>
                {formatYAxis(payload.value)}
            </text>
        )
    }

    useEffect(() => {
        let minTotalSale = Infinity
        let maxTotalSale = -Infinity

        sale.rightPanel.data.forEach((item) => {
            if (item.totalSale < minTotalSale) {
                minTotalSale = item.totalSale
            }
            if (item.totalSale > maxTotalSale) {
                maxTotalSale = item.totalSale
            }
        })

        setYAxisDomain([Math.floor(minTotalSale)])
    }, [sale.rightPanel.data])

    return (
        <Grid>
            <JumboCardQuick
                title={
                    <Typography fontFamily='Lobster, cursive' variant={'h3'} mb={0} color={'info.dark'}>
                        <LoaderOverlayDashboard loading={loading} />
                        Weekly Sale
                    </Typography>
                }
                action={<Chip label={'Last ' + 7 + ' weeks'} color={'info'} size={'small'} />}
                wrapperSx={{ pt: 0 }}>
                <ResponsiveContainer height={200} debounce={200}>
                    {sale.rightPanel ? (
                        <BarChart data={sale.rightPanel.data}>
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis
                                dataKey='saleDate'
                                tickLine={false}
                                axisLine={false}
                                tickFormatter={(tick) => formattedDate(tick, false)}
                            />
                            <YAxis domain={yAxisDomain} margin={{ bottom: 20 }} tick={renderTick} />
                            <Tooltip content={<CustomTooltip />} cursor={false} />
                            <Legend />
                            <Bar dataKey='totalSale' fill='#3EC3D0' name='Weekly  Sale' />
                        </BarChart>
                    ) : (
                        <div>Loading...</div>
                    )}
                </ResponsiveContainer>
            </JumboCardQuick>
        </Grid>
    )
}

export default BannerRight
