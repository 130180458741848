import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import TextBox from 'devextreme-react/text-box'
import notify from 'devextreme/ui/notify'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import Div from '@jumbo/shared/Div'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import RadioGroup from 'devextreme-react/radio-group'
import { updateTransferStatus } from 'app/redux/store/features/transfers'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

const ChangeStatusForm = ({ transfer }) => {
    const { hideDialog } = useJumboDialog()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.transfers)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) {
            handleError(error)
            return
        }
        hideDialogAndRefreshList()
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                notify({ message: error.detail, width: 300, position: 'top right' }, 'error', 1500)
            } else {
                notify({ message: error.detail, width: 300, position: 'top right' }, 'error', 1500)
            }
        },
        [loading]
    )

    const hideDialogAndRefreshList = () => {
        hideDialog()
        notify({ message: 'Transfer Status Updated successfully', width: 300, position: 'top right' }, 'success', 1500)
    }

    const updateStatusMutation = useMutation((data) => {
        setApiCallInProgress(true)
        dispatch(updateTransferStatus(data))
    })

    const onFormSubmit = () => {
        updateStatusMutation.mutate({
            transferStatus: selectionPriority,
            transferInventoryId: transfer.transferInventoryId,
        })
    }

    const branchSelection = {
        'Transfer Draft Created': [
            { id: 20, text: 'Approve' },
            { id: 60, text: 'Cancel' },
        ],
        'Ready for Dispatch': [{ id: 60, text: 'Cancel' }],
        'Shipment Received': [{ id: 50, text: 'Transfer Completed' }],
        'Received With Adjustments': [{ id: 50, text: 'Transfer Completed' }],
    }

    const initialStatus = ''
    const [selectionPriority, setSelectionPriority] = useState(initialStatus)

    const changeSelectionPriority = useCallback(
        (e) => {
            setSelectionPriority(e.value)
        },
        [setSelectionPriority]
    )

    return (
        <Div>
            <Div sx={{ borderBottom: 1, borderColor: 'success.main' }}>
                <Div sx={{ m: 3 }}>
                    <Typography>Current Status</Typography>
                    <TextBox value={transfer.transferStatusName} height={40} readOnly={true} />
                </Div>
            </Div>
            {transfer.transferStatusName !== 'Transfer Completed' &&
                transfer.transferStatusName !== 'Dispatch in Progress' &&
                transfer.transferStatusName !== 'Shipment Dispatched' &&
                transfer.transferStatusName !== 'Shipment Delivered' &&
                transfer.transferStatusName !== 'Transfer Cancelled' && (
                    <Div>
                        <Div sx={{ m: 3 }}>
                            <Typography sx={{ mb: 1 }}>New Transfer Status</Typography>
                            <RadioGroup
                                className='horizontalRadioGroup'
                                items={branchSelection[transfer.transferStatusName] || []}
                                value={selectionPriority}
                                layout='vertical'
                                valueExpr='id'
                                defaultValue={selectionPriority}
                                displayExpr='text'
                                onValueChanged={changeSelectionPriority}
                            />
                        </Div>
                        <Stack direction={'row'} spacing={2} sx={{ m: 'auto', ml: 10.5, mb: 2 }}>
                            <LoadingButton
                                variant='contained'
                                color='success'
                                onClick={onFormSubmit}
                                disabled={!selectionPriority} // Disable button if no selection is made
                                size='large'
                                sx={{ width: 100 }}>
                                Save
                            </LoadingButton>
                            <LoadingButton variant='outlined' color='success' onClick={hideDialog} size='large'>
                                Cancel
                            </LoadingButton>
                        </Stack>
                    </Div>
                )}

            {(transfer.transferStatusName === 'Transfer Cancelled' ||
                transfer.transferStatusName === 'Transfer Completed' ||
                transfer.transferStatusName === 'Dispatch in Progress' ||
                transfer.transferStatusName === 'Shipment Dispatched' ||
                transfer.transferStatusName === 'Shipment Delivered') && (
                <Stack direction={'row'} spacing={2} sx={{ m: 'auto', ml: 16.5, mb: 2, mt: 2 }}>
                    <LoadingButton variant='outlined' color='success' onClick={hideDialog} size='large'>
                        Cancel
                    </LoadingButton>
                </Stack>
            )}
        </Div>
    )
}

export default ChangeStatusForm
