import DateUtils from 'app/utils/components/DateUtils/DateUtils'

export const defaultBranch = {
    branchName: '',
    address: '',
    phoneNo: '',
    branchType: 0,
    portalUrl: '',
    reportXClientId: '',
    reportXClientSecret: '',
    fkCompanyId: null,
    licenseStartDate: DateUtils.getStartDate(0).toISOString().slice(0, 10),
    licenseEndDate: DateUtils.getEndDate(365).toISOString().slice(0, 10),
    timezoneId: 'Asia/Karachi',
}
