import React from 'react'
import { useNavigate } from 'react-router-dom'
import Select from '@mui/material/Select'
import { MenuItem } from '@mui/material'
import useApp from '../../../../../hooks/useApp'
import Div from '@jumbo/shared/Div'

const BranchSelection = () => {
    const { branchData, companySelection, branchSelection, setBranchSelection } = useApp()
    const navigate = useNavigate()
    const onBranchChange = (branchData) => {
        let branchInfo = JSON.parse(branchData.target.value)
        setBranchSelection({
            branchId: branchInfo.branchId,
            branchType: branchInfo.branchType,
            branchName: branchInfo.branchName,
        })
        navigate(0)
    }

    return (
        <Div>
            <Select
                defaultValue=''
                value={JSON.stringify({
                    branchId: branchSelection?.branchId,
                    branchType: branchSelection?.branchType,
                    branchName: branchSelection?.branchName
                })}
                sx={{ mt: 0.5, width: 250, height: 37 }}
                onChange={onBranchChange}>
                {branchData?.companyList
                    ?.filter((company) => company.companyId === companySelection?.companyId)
                    .map((list) =>
                        list.branchList.map((name) => (
                            <MenuItem
                                key={name.branchId}
                                value={JSON.stringify({
                                    branchId: name.branchId,
                                    branchType: name.branchType,
                                    branchName: name.branchName,
                                })}>
                                {name.branchName}
                            </MenuItem>
                        ))
                    )}
            </Select>
        </Div>
    )
}
export default BranchSelection
