import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    VirtualTable,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useJumboTheme from '../../../../../../../../../@jumbo/hooks/useJumboTheme'

const ChartOfAccountGirdData = ({
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { chartOfAccounts, loading, error } = useSelector((state) => state.definitions)
    const { enqueueSnackbar } = useSnackbar()
    const dateColumns = ['dateUpdated', 'dateSynched']
    const [sorting, setSorting] = useState([{ columnName: 'accountName', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )
    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }
    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const Cell = (props) => {
        let style = {
            height: '42px',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const columns = [
        { name: 'accountNum', title: 'Account No' },
        { name: 'accountCode', title: 'Account Code' },
        { name: 'accountName', title: 'Account Name' },
        { name: 'description', title: 'Description' },
        { name: 'dateUpdated', title: 'Date Updated' },
        { name: 'dateSynched', title: 'Date Synched' },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'accountNum', width: min ? '180px' : '18%', minWidth: '180px' },
            { columnName: 'accountCode', width: min ? '180px' : '18%', minWidth: '180px' },
            { columnName: 'accountName', width: min ? '180px' : '18%', minWidth: '180px', wordWrapEnabled: true },
            { columnName: 'description', wordWrapEnabled: true, width: min ? '190px' : '19%', minWidth: '190px' },
            { columnName: 'dateUpdated', width: min ? '140px' : '14%', minWidth: '140px', wordWrapEnabled: true },
            { columnName: 'dateSynched', width: min ? '150px' : '15%', minWidth: '150px', wordWrapEnabled: true },
        ],
        [min]
    )
    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={chartOfAccounts.data} columns={columns}>
                <DateTypeProvider for={dateColumns} />
                <SortingState sorting={sorting} onSortingChange={onSortingChange} />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={chartOfAccounts.totalCount} />
                <Table
                    columnExtensions={columnWidths}
                    noDataCellComponent={NoDataCellWithLoadingType}
                    cellComponent={Cell}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default ChartOfAccountGirdData
