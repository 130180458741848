import React, { useCallback, useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
    CustomPaging,
    PagingState,
    SortingState,
    SelectionState,
    SummaryState,
    IntegratedSummary,
    DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSummaryRow,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import { CurrencyFormatterSummary } from 'app/utils/components/formatCurrency/formatCurrency'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { useSelector } from 'react-redux'

const OrderItemsListDataGrid = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
    txnItemListResponseData,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { loading, error } = useSelector((state) => state.orders)
    const [sorting, setSorting] = useState([{ columnName: 'invCode', direction: 'asc' }])
    const pageSize = 10

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const CurrencyTypeProvider = (props) => (
        <DataTypeProvider formatterComponent={CurrencyFormatterSummary} {...props} />
    )

    const QtyTypeProvider = (props) => <DataTypeProvider {...props} />

    const columns = [
        { name: 'invCode', title: 'Inventory No' },
        { name: 'invName', title: 'Product Name' },
        { name: 'qtyOrdered', title: 'Qty Ordered' },
        { name: 'qtyReceived', title: 'Qty Received' },
        {
            name: 'inventoryBasePrice',
            title: 'Base Price',
        },
        { name: 'marketPrice', title: 'Market Price', getCellValue: (row) => formatCurrency(row.marketPrice) },
        { name: 'orderPrice', title: 'Order Price', getCellValue: (row) => formatCurrency(row.orderPrice) },
        {
            name: 'orderPricePercentage',
            title: 'Order %',
            getCellValue: (row) => formatCurrency(row.orderPricePercentage),
        },
        { name: 'salePrice', title: 'Sale Price', getCellValue: (row) => formatCurrency(row.salePrice) },
        {
            name: 'salePricePercentage',
            title: 'Sale %',
            getCellValue: (row) => formatCurrency(row.salePricePercentage),
        },
        { name: 'discountAmount', title: 'Discount', getCellValue: (row) => formatCurrency(row.discountAmount) },
        { name: 'taxAmount', title: 'Tax', getCellValue: (row) => formatCurrency(row.taxAmount) },
        { name: 'totalAmount', title: 'Total' },
    ]

    const tableColumnExtensions = [
        { columnName: 'invCode', width: '9%' },
        { columnName: 'invName', width: '9%' },
        { columnName: 'qtyOrdered', align: 'center', width: '8%' },
        { columnName: 'qtyReceived', align: 'center', width: '9%' },
        { columnName: 'inventoryBasePrice', align: 'right', width: '8%' },
        { columnName: 'marketPrice', align: 'right', width: '8%' },
        { columnName: 'orderPrice', align: 'right', width: '8%' },
        { columnName: 'orderPricePercentage', align: 'right', width: '7%' },
        { columnName: 'salePrice', align: 'right', width: '7%' },
        { columnName: 'salePricePercentage', align: 'right', width: '6%' },
        { columnName: 'discountAmount', align: 'right', width: '7%' },
        { columnName: 'taxAmount', align: 'right', width: '6%' },
        { columnName: 'totalAmount', align: 'right', width: '8%' },
    ]

    const Cell = (props) => {
        let style = {
            height: '42px',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const totalSummaryItems = [
        { columnName: 'qtyOrdered', type: 'sum' },
        { columnName: 'qtyReceived', type: 'sum' },
        { columnName: 'totalAmount', type: 'sum' },
    ]

    const qtyColumns = ['qtyOrdered', 'qtyReceived']
    const currencyColumns = ['totalAmount']

    const messages = {
        sum: 'Total',
    }
    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={txnItemListResponseData.data} columns={columns}>
                    <CurrencyTypeProvider for={currencyColumns} />
                    <QtyTypeProvider for={qtyColumns} />
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={txnItemListResponseData.totalCount} />
                    <SummaryState totalItems={totalSummaryItems} />
                    <IntegratedSummary />
                    <Table columnExtensions={tableColumnExtensions} cellComponent={Cell} />
                    <TableHeaderRow showSortingControls />
                    <TableSummaryRow messages={messages} />
                    <SelectionState />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default OrderItemsListDataGrid
