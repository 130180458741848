import React from 'react'
import { Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import { Card, CardContent, ListItemText, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import CommentIcon from '@mui/icons-material/Comment'
import Span from '@jumbo/shared/Span'

const RightInfoCard = ({ order }) => {
    return (
        <Grid>
            <Card sx={{ height: 250 }}>
                <CardContent>
                    <Stack direction={'row'}>
                        <Grid
                            item
                            sx={{ width: '45%', display: 'flex', alignItems: 'center', marginRight: '5%' }}></Grid>
                        <Grid item sx={{ width: '45%', display: 'flex', alignItems: 'right', ml: 1.9 }}>
                            {' '}
                            <List sx={{ width: '100%' }}>
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <CommentIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Comments
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2, width: '100%' }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='secondary.dark'>
                                        {order.comments && (
                                            <pre>
                                                <Span
                                                    style={{
                                                        fontSize: '1rem',
                                                        color: 'secondary.dark',
                                                        fontFamily: 'Lobster, cursive',
                                                        fontWeight: '400',
                                                        lineHeight: 1.5,
                                                        whiteSpace: 'pre-wrap',
                                                    }}>
                                                    {order.comments + ' '}
                                                </Span>
                                            </pre>
                                        )}
                                    </Typography>
                                </ListItemText>
                            </List>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default RightInfoCard
