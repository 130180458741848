import React from 'react'
import RequestTimeLineList from './RequestTimeLineCard/components/RequestTimeLineList'

const RequestTimeLineCard = ({ statusList, request }) => {
    return (
        <React.Fragment>
            <RequestTimeLineList statusList={statusList} request={request} />
        </React.Fragment>
    )
}

export default RequestTimeLineCard
