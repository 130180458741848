import React, { useEffect, useState } from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useDispatch } from 'react-redux'
import { getTransactionItemsList } from 'app/redux/store/features/transactions'
import TxnItemsDataGrid from './TxnItemsDataGrid'
import { Grid } from '@mui/material'

const TxnItemsGrid = ({ txnItemsData, posTxId }) => {
    const pageSize = 10
    const [filter, setFilter] = useState({
        posTxId: posTxId,
        onlineTxn: false,
    })
    const [currentPage, setCurrentPage] = useState(0)
    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getTransactionItemsList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getTransactionItemsList(currentFilter))
        setFilter(currentFilter)
    }

    return (
        <JumboCardQuick
            noWrapper
            headerSx={{
                height: 40,
                bgcolor: 'secondary.main',
                pl: 1,
            }}>
            <Grid>
                <TxnItemsDataGrid
                    onPageChange={onPageChange}
                    onColumnSort={onColumnSort}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    apiCallInProgress={apiCallInProgress}
                    setApiCallInProgress={setApiCallInProgress}
                    txnItemsData={txnItemsData}
                />
            </Grid>
        </JumboCardQuick>
    )
}

export default TxnItemsGrid
