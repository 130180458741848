import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomPaging, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    VirtualTable,
    PagingPanel,
    TableSelection,
} from '@devexpress/dx-react-grid-material-ui'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { getCompanyConfig, updateCompanyConfig } from 'app/redux/store/features/company'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import { makeStyles } from '@mui/styles'

const CompanyGridSidebarData = ({
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { companies, companyConfig, loading, error } = useSelector((state) => state.companies)
    const { enqueueSnackbar } = useSnackbar()
    const [sorting, setSorting] = useState([{ columnName: 'companyName', direction: 'asc' }])
    const pageSize = 25
    const [selectedRowIds, setSelectedRowIds] = useState([])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)

        if (companies.data.length > 0) {
            let selectedCompanyId

            if (companyConfig.companyId) {
                // If companyConfig.companyId is truthy, find the corresponding company
                const selectedCompany = companies.data.find((company) => company.companyId === companyConfig.companyId)

                if (selectedCompany) {
                    selectedCompanyId = selectedCompany.companyId
                } else {
                    // Handle the case where the company with companyConfig.companyId is not found
                    selectedCompanyId = companies.data[0].companyId
                }
            } else {
                // If companyConfig.companyId is falsy, select the first company in the list
                selectedCompanyId = companies.data[0].companyId
            }
            dispatch(getCompanyConfig({ companyId: selectedCompanyId }))
            setSelectedRowIds([selectedCompanyId])
        }
    }, [loading, companyConfig])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onSelectionChange = (selectedRowIds) => {
        setSelectedRowIds(selectedRowIds)
    }

    const Cell = (props) => {
        let style = {
            cursor: 'pointer',
            height: '42px',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const useStyles = makeStyles((theme) => ({
        selectedRow: {
            backgroundColor: theme.palette.action.selected + ' !important',
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))

    const TableRow = ({ tableRow, ...restProps }) => {
        const classes = useStyles()
        return (
            <TableSelection.Row
                {...restProps}
                onClick={() => {
                    dispatch(
                        updateCompanyConfig({
                            companyId: tableRow.row.companyId,
                        })
                    )

                    setSelectedRowIds([tableRow.row.companyId])
                }}
                selected={selectedRowIds.includes(tableRow.row.companyId)}
                className={selectedRowIds.includes(tableRow.row.companyId) ? classes.selectedRow : null}
            />
        )
    }

    const columns = [
        { name: 'companyName', title: 'Company Name' },
        { name: 'contactName', title: 'Contact Name' },
    ]

    const columnWidths = [
        { columnName: 'companyName', width: '50%' },
        { columnName: 'contactName', width: '50%' },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={companies.data} columns={columns} rowSelection={'single'}>
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={companies.totalCount} />
                    <Table
                        cellComponent={Cell}
                        columnExtensions={columnWidths}
                        noDataCellComponent={NoDataCellWithLoadingType}
                    />
                    <TableHeaderRow showSortingControls />
                    <SelectionState selectedRowIds={selectedRowIds} onSelectionChange={onSelectionChange} />
                    <TableSelection
                        highlightRow={true}
                        selectByRowClick={true}
                        showSelectAll={false}
                        rowComponent={TableRow}
                        showSelectionColumn={false}
                        rowSelection={'single'}
                    />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default CompanyGridSidebarData
