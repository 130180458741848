import React from 'react'
import Div from '@jumbo/shared/Div'
import { Typography } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList/JumboRqList'
import useDefinitionApp from '../../hooks/useDefinitionApp'
import DefinitionViewItem from '../DefintionViewItem/DefinitionViewItem'
import DefinitionServices from '../../../../../services/pages/store/definitions/definitionServices'

const InventoryDefinitionList = () => {
    const { inventoryDefinitionListRef: inventoryDefinitionListRef } = useDefinitionApp()

    const renderDefinitionView = React.useCallback((definitionItem) => {
        return <DefinitionViewItem definitionViewItem={definitionItem} />
    })

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'info.contrastText'} variant={'h5'} fontWeight={400}>
                        Inventory Defintions
                    </Typography>
                }
                headerSx={{
                    pt: 3,
                    height: 40,
                    bgcolor: 'success.main',
                }}
                sx={{ borderRadius: 2, m: 1 }}>
                <JumboRqList
                    ref={inventoryDefinitionListRef}
                    service={DefinitionServices.getInventoryDefinitionList}
                    primaryKey={'id'}
                    queryOptions={{
                        queryKey: 'inventory-definition-list',
                        dataKey: 'definitions',
                    }}
                    renderItem={renderDefinitionView}
                    componentElement={'div'}
                />
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default InventoryDefinitionList
