import React, { useState } from 'react'
import Div from '@jumbo/shared/Div'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import { TabContext, TabPanel } from '@mui/lab'
import SalesReportList from '../epos/SalesReportList/SalesReportList'
import OrderReportList from '../epos/OrderReportList'
import InventoryReportList from '../epos/InventoryReportList/InventoryReportList'
import CashReportList from '../epos/CashReportList/CashReportList'
import SalesReportListSpiral from '../spiral/SalesReportList/SalesReportList'
import OrderReportListSpiral from '../spiral/OrderReportList/OrderReportList'
import InventoryReportListSpiral from '../spiral/InventoryReportList/InventoryReportList'
import CashReportListSpiral from '../spiral/CashReportList/CashReportList'
import useApp from '../../../../hooks/useApp'

const ReportAppSideBar = () => {
    const ePosReports = 'epos'
    const spiralReports = 'spiral'
    const [activeTab, setActiveTab] = useState(ePosReports)
    const { branchSelection } = useApp()

    return (
        <React.Fragment>
            <TabContext value={activeTab}>
                <Div sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList variant='fullWidth' onChange={(event, newTab) => setActiveTab(newTab)}>
                        {branchSelection.branchType === 'ePOS' && <Tab label='ePOS' value={ePosReports} />}
                        {branchSelection.branchType === 'Spiral' && <Tab label='Spiral' value={spiralReports} />}
                    </TabList>
                </Div>
                <JumboScrollbar
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                    className='data-grid-height'>
                    <TabPanel value={ePosReports} sx={{ p: 0 }}>
                        {<SalesReportList branchType={ePosReports} />}
                        {<InventoryReportList branchType={ePosReports} />}
                        {<OrderReportList branchType={ePosReports} />}
                        {<CashReportList branchType={ePosReports} />}
                    </TabPanel>

                    <TabPanel value={spiralReports} sx={{ p: 0 }}>
                        {<SalesReportListSpiral branchType={spiralReports} />}
                        {<InventoryReportListSpiral branchType={spiralReports} />}
                        {<OrderReportListSpiral branchType={spiralReports} />}
                        {<CashReportListSpiral branchType={spiralReports} />}
                    </TabPanel>
                </JumboScrollbar>
            </TabContext>
        </React.Fragment>
    )
}
export default ReportAppSideBar
