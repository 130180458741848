import React, { useEffect, useState } from 'react'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import PersonIcon from '@mui/icons-material/Person'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import StyledMenu from '../../../../../shared/StyledMenu'
import UserTypeItem from './UserTypeItem'
import { useDispatch, useSelector } from 'react-redux'
import { getUserTypeLookupList } from 'app/redux/store/features/users'

const UserTypeList = ({ handleError }) => {
    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { userTypeConfig, userTypes, loading, error } = useSelector((state) => state.users)

    useEffect(() => {
        dispatch(getUserTypeLookupList())
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
    }, [loading])

    return (
        <JumboCardQuick
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <SupervisorAccountIcon fontSize={'large'} sx={{ mb: -1.3, ml: -2, mr: 1 }} />
                    User Types
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'primary.main',
            }}>
            <StyledMenu sx={{ mb: 2 }}>
                {userTypes.data.map((item) => (
                    <UserTypeItem
                        key={item.typeName}
                        path={`/security/permissions/${item.typeName.toLowerCase()}`}
                        label={item.typeName}
                        icon={<PersonIcon />}
                        selected={userTypeConfig.id === item.id}
                        userTypeId={item.id}
                        permissionsReadOnly={item.readOnly}
                    />
                ))}
            </StyledMenu>
        </JumboCardQuick>
    )
}

export default React.memo(UserTypeList)
