import jwtAuthAxios from '../../../auth/jwtAuth'

const inventoryService = {}

inventoryService.getInventoryTypeLookupList = async (filter) => {
    let url = filter?.typeName ? `/Inventory/type/lookup/list?search=${filter.typeName}` : `Inventory/type/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getInventoryTypesLookupById = async (key) => {
    let url = `/Inventory/type/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getInventoryList = async (filter) => {
    let url = `/Inventory/list`
    const { data } = await jwtAuthAxios.post(url, filter).catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getInventoryById = async (filter) => {
    let url = `/Inventory/${filter.invClassId}`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}
inventoryService.updateInventory = async (invData, handleError) => {
    let url = `/Inventory/update`
    const { data } =
        (await jwtAuthAxios.put(url, invData).catch(function (error) {
            if (error.response) {
                console.log(error.response.data)
                handleError(error.response.data)
            } else if (error.request) {
                console.log(error.request)
                throw Error('request timed out')
            } else {
                console.log('Error', error.message)
            }
            console.log(error.config)
        })) || {}
    return data
}
inventoryService.addInventory = async (invData) => {
    let url = `/Inventory/add`
    const { data } = await jwtAuthAxios.post(url, invData).catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getCategoryLookupList = async (filter) => {
    let url = filter?.categoryName
        ? `/Inventory/category/lookup/list?search=${filter.categoryName}`
        : `/Inventory/category/lookup/list`

    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}
inventoryService.getCategoryById = async (key) => {
    let url = `/Inventory/category/${key}`

    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getSubCategoryLookupList = async (filter) => {
    let url = filter?.subCategoryName
        ? `/Inventory/subcategory/lookup/list?fkCategoryId=${filter.categoryId}&search=${filter.subCategoryName}`
        : filter?.categoryId
          ? `/Inventory/subcategory/lookup/list?fkCategoryId=${filter.categoryId}`
          : `/Inventory/subcategory/lookup/list`

    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}
inventoryService.getSubCategoryById = async (key) => {
    let url = `/Inventory/subcategory/${key}`

    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getInventoryTypeLookupList = async (filter) => {
    let url = filter?.typeName ? `/Inventory/type/lookup/list?search=${filter.typeName}` : `Inventory/type/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getInventoryTypesLookupById = async (key) => {
    let url = `/Inventory/type/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getChartAccountLookupList = async (filter) => {
    let url = filter?.accountName
        ? `/Accounting/chartofaccounts/lookup/list?search=${filter.accountName}`
        : `Accounting/chartofaccounts/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getChartAccountLookupById = async (key) => {
    let url = `/Accounting/chartofaccounts/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getUOMLookupList = async (filter) => {
    let url = filter?.uomName ? `/Inventory/uom/lookup/list?search=${filter.uomName}` : `/Inventory/uom/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getUOMLookupById = async (key) => {
    let url = `/Inventory/uom/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getChartTaxLookupList = async (filter) => {
    let url = filter?.taxName
        ? `/Accounting/charttax/lookup/list?search=${filter.taxName}`
        : `/Accounting/charttax/lookup/list`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getChartTaxLookupById = async (key) => {
    let url = `/Accounting/charttax/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getInventoryCategoriesLookupList = async (filter) => {
    let url = filter?.categoryName
        ? `/Inventory/category/lookup/list?search=${filter.categoryName}`
        : `/Inventory/category/lookup/list`
    const { data } = await jwtAuthAxios.get(url, { filter }).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

inventoryService.getInventoryCategoriesLookupById = async (key) => {
    let url = `/Inventory/category/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default inventoryService
