import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const IdleTimeoutDialog = ({ showDialog, handleStay, handleLogout }) => {
    return (
        <Dialog open={showDialog} onClose={handleLogout}>
            <DialogTitle>Idle Timeout</DialogTitle>
            <DialogContent>
                <DialogContentText>You've been idle for some time. Do you want to stay logged in?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleStay} color='primary'>
                    Stay
                </Button>
                <Button onClick={handleLogout} color='primary' autoFocus>
                    Logout
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default IdleTimeoutDialog
