if (!String.prototype.toFullName) {
    String.prototype.toFullName = function (lastName) {
        if (this === undefined || this === null) return ''
        if (lastName === undefined || lastName === null) return this
        return this + ' ' + lastName
    }
}

const utilServices = {}

utilServices.emptyList = async () => {
    return {
        totalCount: 0,
        data: [],
    }
}

utilServices.isNullOrUndefined = (value) => {
    return value === undefined || value === null
}

export default utilServices
