let AuditSourceService = {}

AuditSourceService.AuditSourcesList = [
    { id: 0, auditSource: 'Default' },
    { id: 1, auditSource: 'User' },
    { id: 11, auditSource: 'Customer' },
    { id: 21, auditSource: 'Inventory' },
    { id: 31, auditSource: 'Profile' },
    { id: 40, auditSource: 'Transaction' },
    { id: 50, auditSource: 'Orders' },
]

AuditSourceService.getAuditSourceInfo = () => {
    return AuditSourceService.AuditSourcesList
}

AuditSourceService.getAuditSourceInfoData = (row) => {
    const auditSource = AuditSourceService.AuditSourcesList.find((item) => item.id === row.auditSource)
    return auditSource ? auditSource.auditSource : ''
}

export default AuditSourceService
