import React from 'react'
import AutoNumberAppProvider from './AutoNumberLayout/AutoNumberAppProvider'
import AutoNumberAppLayout from './AutoNumberLayout/AutoNumberAppLayout'

const AutoNumberApp = () => {
    return (
        <div>
            <AutoNumberAppProvider>
                <AutoNumberAppLayout />
            </AutoNumberAppProvider>
        </div>
    )
}

export default AutoNumberApp
