import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { getBranchUserLookupList } from 'app/redux/store/features/users'
import utilServices from 'app/services/util-services'
import CustomStore from 'devextreme/data/custom_store'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import customerCategoryService from 'app/services/pages/store/customers/customerCategoryServices'

const CustomerReceivableReportFilter = ({ mutation }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [showAll, setShowAll] = useState(false)

    useEffect(() => {
        dispatch(getBranchUserLookupList(''))
    }, [])

    let initialValues = {
        customerName: '',
        customerBarcode: '',
        customerComments: '',
        customerArea: '',
        customerCity: '',
    }

    const [filter, setFilter] = useState({ customerCategory: '' })

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const customerCategoriesLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await customerCategoryService.getCustomerCategoriesLookupList({
                    customerCategoryName: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [customerCategoryService]
    )

    const customerCategoriesLookupById = useCallback(
        async (key) => {
            try {
                return await customerCategoryService.getCustomerCategoriesLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [customerCategoryService]
    )

    const customerCategoryStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'customerCategoryId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await customerCategoriesLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return customerCategoriesLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = async (customerName, customerBarcode, customerComments, customerArea, customerCity) => {
        await mutation.mutate({
            customerName: customerName,
            customerBarcode: customerBarcode,
            customerComments: customerComments,
            customerArea: customerArea,
            customerCity: customerCity,
            ...filter,
            showAllCustomers: showAll,
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(
            data.customerName,
            data.customerBarcode,
            data.customerComments,
            data.customerArea,
            data.customerCity
        )
        setSubmitting(false)
    }

    const handleClear = () => {
        setFilter({ customerCategory: '' })
        setShowAll(null)
    }

    const onCustomerCategorySelectionChanged = (value) => {
        setFilter({ ...filter, customerCategory: value?.customerCategoryId })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ display: '', borderRadius: 0 }}>
                        <Divider />
                        <Div style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Customer Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='customerName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Barcode</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='customerBarcode' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Category</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={customerCategoryStore}
                                        displayExpr={'customerCategoryName'}
                                        value={filter.customerCategory}
                                        onValueChange={onCustomerCategorySelectionChanged}
                                        searchEnabled={true}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Comments</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='customerComments' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Customer Area</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='customerArea' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Customer City</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='customerCity' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 3.5 }}>
                                <Div sx={{ mt: 0.5 }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            type='checkbox'
                                            checked={showAll}
                                            onChange={(e) => setShowAll(!showAll)}
                                            label='Show All'
                                        />
                                    </FormGroup>
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default CustomerReceivableReportFilter
