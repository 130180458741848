import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency.js'
import useJumboTheme from '../../../../../../../@jumbo/hooks/useJumboTheme'

const DiscountGridData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { discount, loading, error } = useSelector((state) => state.discounts)
    const [sorting, setSorting] = useState([{ columnName: 'discountName', direction: 'asc' }])
    const [booleanColumns] = useState(['isActive'])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton
                    onClick={() => navigate(`/store/settings/discounts`, { state: { discountId: row.discountId } })}>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const BooleanFormatter = ({ value }) => <span>{formatBooleanValue(value)}</span>
    const formatBooleanValue = (value) => (value ? 'Yes' : 'No')
    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const columns = [
        { name: 'discountName', title: 'Discount Name' },
        { name: 'couponCode', title: 'Coupon Code' },
        { name: 'isActive', title: 'Enabled' },
        { name: 'discountType', title: 'Discount Type' },
        { name: 'amount', title: 'Amount', getCellValue: (row) => formatCurrency(row.amount) },
        { name: 'percentage', title: 'Percentage' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'discountName', width: min ? '170px' : '17%', wordWrapEnabled: true, minWidth: '170px' },
            { columnName: 'couponCode', width: min ? '160px' : '16%', wordWrapEnabled: true, minWidth: '160px' },
            { columnName: 'isActive', width: min ? '150px' : '15%', wordWrapEnabled: true, minWidth: '150px' },
            { columnName: 'discountType', width: min ? '150px' : '15%', wordWrapEnabled: true, minWidth: '150px' },
            {
                columnName: 'amount',
                width: min ? '150px' : '15%',
                wordWrapEnabled: true,
                minWidth: '150px',
                align: 'right',
            },
            { columnName: 'Action', width: min ? '90px' : '9%', minWidth: '90px', align: 'center' },
            { columnName: 'percentage', width: min ? '150px' : '15%', wordWrapEnabled: true, minWidth: '150px' },
        ],
        [min]
    )
    const [columnWidths, setColumnWidths] = useState(columnWidth)
    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={discount.data} columns={columns}>
                <BooleanTypeProvider for={booleanColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={discount.totalCount} />
                <Table columnExtensions={columnWidths} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default DiscountGridData
