import jwtAuthAxios from 'app/services/auth/jwtAuth'

const branchServices = {}

branchServices.publishBranch = async (branchId) => {
    let url = `/company/branch/publish`
    const { data } = await jwtAuthAxios.put(url, branchId).catch(function (error) {
        if (error.response) {
            if (error.response.data && error.response.data.detail && error.response.data.status) {
                throw Error(`${error.response.data.status} ${error.response.data.detail}`)
            } else if (error.request) {
                throw Error('Request timed out')
            } else {
                console.log('Error', error.message)
                throw Error(error.message)
            }
        }
    })
    return data
}

export default branchServices
