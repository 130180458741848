import React, { useState } from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import { ListItemText, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { useNavigate } from 'react-router-dom'
import useAutoNumberApp from '../../hooks/useAutoNumberApp'

const AutoNumberViewItem = ({ autoNumberViewItem }) => {
    const navigate = useNavigate()
    const { setActiveAutoNumber, activeAutoNumberColorList } = useAutoNumberApp()
    const [activeAutoNumberColor, setActiveAutoNumberColor] = useState('text.secondary')

    const handleAutoNumberItemClick = () => {
        setActiveAutoNumber({
            category: autoNumberViewItem?.category,
            id: autoNumberViewItem?.id,
        })

        navigate(`/store/settings/${autoNumberViewItem?.category}/${autoNumberViewItem?.id}`)
    }

    React.useEffect(() => {
        activeAutoNumberColorList.map((item) => {
            if (item.id === autoNumberViewItem.id) {
                setActiveAutoNumberColor(item.color)
            }
        })
    }, [activeAutoNumberColorList])

    return (
        <List>
            <ListItemButton
                component='li'
                sx={{
                    borderRadius: 2,
                    borderLeft: 2,
                    bgcolor: activeAutoNumberColor == 'text.secondary' ? 'common.white' : 'background.default',
                    borderColor: activeAutoNumberColor == 'text.secondary' ? 'common.white' : activeAutoNumberColor,
                }}>
                <ListItemText
                    onClick={handleAutoNumberItemClick}
                    primary={
                        <Typography variant='h6' mb={0.5} fontSize={15} color={activeAutoNumberColor}>
                            {autoNumberViewItem?.name}
                        </Typography>
                    }
                />
            </ListItemButton>
            <Divider component='li' />
        </List>
    )
}
export default AutoNumberViewItem
