import React from 'react'
import { Grid } from '@mui/material'
import { Card, CardContent } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import RequestTimeLineCard from './RequestTimeLineCard'
import { requestTimeLineData } from './RequestTimeLineCard/components/data'
import ChangeRequestStatusForm from './RequestTimeLineCard/components/ChangeRequestStatusForm'
import Div from '@jumbo/shared/Div'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'

const RequestRightInfoCard = ({ request }) => {
    const { showDialog, hideDialog } = useJumboDialog()
    const firstGridData = requestTimeLineData.filter((item) => item.id >= 0 && item.id <= 5)
    const secondGridData = requestTimeLineData.filter((item) => item.id >= 7 && item.id <= 10)

    const onChangeStatus = ({ request }) => {
        showDialog({
            title: (
                <Div
                    sx={{
                        textAlign: 'center',
                        height: 48,
                        pt: 1.5,
                        bgcolor: 'success.main',
                        borderRadius: 1,
                        color: 'common.white',
                    }}>
                    Change Status
                </Div>
            ),
            content: <ChangeRequestStatusForm request={request} />,
        })
    }

    return (
        <Grid>
            <Card sx={{ height: 300 }}>
                <CardContent>
                    <Stack direction={'row'}>
                        <Grid item sx={{ width: '45%', marginRight: '5%' }}>
                            <RequestTimeLineCard statusList={firstGridData} request={request} />
                        </Grid>
                        <Grid item sx={{ width: '45%', display: 'flex', alignItems: 'right', ml: 1.9 }}>
                            <RequestTimeLineCard statusList={secondGridData} request={request} />
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
            <Grid item sx={{ textAlign: 'right', marginTop: '-17%', marginRight: '24%' }}>
                <Button variant='outlined' onClick={() => onChangeStatus({ request })}>
                    Change Status
                </Button>
            </Grid>
        </Grid>
    )
}

export default RequestRightInfoCard
