import jwtAxios from 'axios'
import { auth } from './firebase/firebase'

const jwtAuthAxios = jwtAxios.create({
    baseURL: process.env.REACT_APP_HQServiceBackendUrl,
    headers: {
        'Content-Type': 'application/json',
    },
})

jwtAuthAxios.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        try {
            const originalRequest = error.config
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true
                let user = auth.currentUser
                if (user) {
                    let token = await user.getIdToken()
                    setAuthToken(token)
                }
                return jwtAuthAxios(originalRequest)
            }
        } catch (_error) {
            return Promise.reject(_error)
        }
        return Promise.reject(error)
    }
)

export const setAuthToken = (token) => {
    if (token) {
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        sessionStorage.setItem('token', token)
    } else {
        delete jwtAuthAxios.defaults.headers.common['Authorization']
        sessionStorage.removeItem('token')
    }
}

export const setBranchId = (branchId) => {
    if (branchId) {
        jwtAuthAxios.defaults.headers.post['x-branch-id'] = branchId
        jwtAuthAxios.defaults.headers.put['x-branch-id'] = branchId
        jwtAuthAxios.defaults.headers.get['x-branch-id'] = branchId
        jwtAuthAxios.defaults.headers.delete['x-branch-id'] = branchId
    } else {
        delete jwtAuthAxios.defaults.headers.post['x-branch-id']
        delete jwtAuthAxios.defaults.headers.put['x-branch-id']
        delete jwtAuthAxios.defaults.headers.get['x-branch-id']
        delete jwtAuthAxios.defaults.headers.delete['x-branch-id']
    }
}

export const getAuthToken = () => {
    return sessionStorage.getItem('token')
}

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios
