import React from 'react'
import { config } from './config/main'
import { APP_ACTIONS } from './utils/constants/appActions'
import { AppContext } from './AppContext'
import { setBranchId } from './services/auth/jwtAuth'

const init = () => {
    if (branchIdStorage) {
        setBranchId(branchIdStorage) // also sets axios header
    }

    if (branchDataStorage)
        return {
            customizerVisibility: false,
            containerStyle: config.containerStyle,
            rebuildRoutes: true,
            branchInfo: branchDataStorage,
            companySelection: { companyId: companyIdStorage },
            branchSelection: {
                branchId: branchIdStorage,
                branchType: branchTypeStorage,
                branchName: branchNameStorage,
            },
        }

    return {
        customizerVisibility: false,
        containerStyle: config.containerStyle,
        branchInfo: {},
    }
}

const branchDataStorage = JSON.parse(localStorage.getItem('branchData'))
export const saveBranchData = (branchData) => {
    localStorage.setItem('branchData', JSON.stringify(branchData))
}

const companyIdStorage = localStorage.getItem('companySelection')
export const saveCompanySelection = (companySelection) => {
    localStorage.setItem('companySelection', companySelection.companyId)
}

const branchIdStorage = localStorage.getItem('branchSelection')
const branchTypeStorage = localStorage.getItem('branchTypeSelection')
const branchNameStorage = localStorage.getItem('branchNameSelection')
export const saveBranchSelection = (branchSelection) => {
    if (config?.authSetting?.axiosObject) setBranchId(branchSelection.branchId)

    localStorage.setItem('branchSelection', branchSelection.branchId)
    localStorage.setItem('branchTypeSelection', branchSelection.branchType)
    localStorage.setItem('branchNameSelection', branchSelection.branchName)
}

export const removeBranchData = () => {
    localStorage.removeItem('branchData')
}
export const removeCompanySelection = () => {
    localStorage.removeItem('companySelection')
}
export const removeBranchSelection = () => {
    if (config?.authSetting?.axiosObject) setBranchId(null)

    localStorage.removeItem('branchSelection')
    localStorage.removeItem('branchTypeSelection')
    localStorage.removeItem('branchNameSelection')
}

function appReducer(state, action) {
    switch (action.type) {
        case APP_ACTIONS.SET_CUSTOMIZER_VISIBILITY:
            return {
                ...state,
                customizerVisibility: action.payload,
            }

        case APP_ACTIONS.SET_CONTAINER_STYLE:
            return {
                ...state,
                containerStyle: action.payload,
            }

        case APP_ACTIONS.SET_STORE_DATA:
            return {
                ...state,
                branchData: action.payload,
            }

        case APP_ACTIONS.SET_STORE_SEL:
            return {
                ...state,
                branchSelection: action.payload,
            }

        case APP_ACTIONS.SET_COMPANY_SEL:
            return {
                ...state,
                companySelection: action.payload,
            }

        case APP_ACTIONS.SET_APP:
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}

const AppProvider = ({ children, ...restProps }) => {
    const [app, setApp] = React.useReducer(appReducer, restProps, init)

    const setCustomizerVisibility = React.useCallback(
        (value) => {
            setApp({ type: APP_ACTIONS.SET_CUSTOMIZER_VISIBILITY, payload: value })
        },
        [setApp]
    )

    const setContainerStyle = React.useCallback(
        (containerStyle) => {
            setApp({ type: APP_ACTIONS.SET_CONTAINER_STYLE, payload: containerStyle })
        },
        [setApp]
    )

    const setBranchData = React.useCallback(
        (branchData) => {
            setApp({ type: APP_ACTIONS.SET_STORE_DATA, payload: branchData })
            saveBranchData(branchData)
        },
        [setApp]
    )

    const setCompanySelection = React.useCallback(
        (companySelection) => {
            setApp({ type: APP_ACTIONS.SET_COMPANY_SEL, payload: companySelection })
            saveCompanySelection(companySelection)
        },
        [setApp]
    )

    const setBranchSelection = React.useCallback(
        (branchSelection) => {
            setApp({ type: APP_ACTIONS.SET_STORE_SEL, payload: branchSelection })
            saveBranchSelection(branchSelection)
        },
        [setApp]
    )

    const setAppState = React.useCallback(
        (stateObject) => {
            setApp({ type: APP_ACTIONS.SET_APP, payload: stateObject })
        },
        [setApp]
    )

    const rebuildRoutes = React.useCallback((value) => {}, [setApp])

    // Upon page refresh, we want to update state from local storage
    React.useEffect(() => {
        setApp({ type: APP_ACTIONS.SET_STORE_DATA, payload: branchDataStorage })
    }, [branchDataStorage])

    React.useEffect(() => {
        setApp({ type: APP_ACTIONS.SET_COMPANY_SEL, payload: { companyId: companyIdStorage } })
    }, [companyIdStorage])

    React.useEffect(() => {
        setApp({
            type: APP_ACTIONS.SET_STORE_SEL,
            payload: { branchId: branchIdStorage, branchType: branchTypeStorage, branchName: branchNameStorage },
        })
    }, [branchIdStorage])

    const contextValue = React.useMemo(
        () => ({
            ...app,
            setCustomizerVisibility,
            setContainerStyle,
            setBranchData,
            setCompanySelection,
            setBranchSelection,
            setAppState,
            removeBranchData,
            removeCompanySelection,
            removeBranchSelection,
        }),
        [app]
    )

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}
export default AppProvider
