import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import pdfMake from 'pdfmake'
import Div from '@jumbo/shared/Div'
import { Pagination, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import DownloadIcon from '@mui/icons-material/Download'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from 'notistack'
import vfs from '../../../../../../../../../fonts/NimbusSanL/nimbus-san'
import DailySaleReportFilter from './DailySaleReportFilter'
import '../../../../../../reportstyles.css'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const DailySalesReportLayout = ({
    imageData,
    viewMutation,
    pdfMutation,
    pageMutation,
    apiCallInProgress,
    setApiCallInProgress,
    pdfData,
}) => {
    const { dailySales, loading, error } = useSelector((state) => state.reports)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [isFilterVisible, setFilterVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pdfDownloading, setPdfDownloading] = useState(false)
    const [pdfTemplate, setpdfTemplate] = useState({})
    const itemsPerPage = 25
    const totalPages = Math.ceil(dailySales.totalCount / itemsPerPage)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )
    pdfMake.vfs = vfs
    pdfMake.fonts = {
        NimbusSans: {
            normal: 'NimbusSanL-Reg.otf',
            bold: 'NimbusSanL-Bol.otf',
            italics: 'NimbusSanL-RegIta.otf',
            bolditalics: 'NimbusSanL-BolIta.otf',
        },
    }

    const handlePageChange = async (page) => {
        try {
            await pageMutation.mutate(page)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error updating page:', error)
        }
    }

    const handleFilterClick = () => {
        setFilterVisible(!isFilterVisible)
    }

    const headers = React.useMemo(
        () => [
            'Sale No',
            'Checkout By',
            'Terminal No',
            'Customer Name',
            'Sale Type',
            'Items Count',
            'Sub Total',
            'Discount',
            'Tax',
            'Added Tax',
            'Total',
        ],
        []
    )

    const parseToPdfData = useMemo(
        () => (data) => {
            return data.map((d) => {
                return [
                    d.invoiceNo,
                    d.soldBy,
                    d.counterNO,
                    d.customerName,
                    d.saleType,
                    d.itemsCount,
                    formatCurrency(d.subTotal),
                    formatCurrency(d.discount),
                    formatCurrency(d.tax),
                    formatCurrency(d.additionalTaxAmount),
                    formatCurrency(d.total),
                ]
            })
        },
        []
    )

    const pageRow = [
        { text: 'Page Total', style: 'rowHeading' },
        { text: pdfData.pageSummary?.totalItemsCount.toFixed(2), style: 'cell' },
        { text: formatCurrency(pdfData.pageSummary?.totalSubTotal), style: 'rightAlign' },
        { text: formatCurrency(pdfData.pageSummary?.totalDiscount), style: 'rightAlign' },
        { text: formatCurrency(pdfData.pageSummary?.totalTax), style: 'rightAlign' },
        { text: formatCurrency(pdfData.pageSummary?.totalAdditionalTaxAmount), style: 'rightAlign' },
        { text: formatCurrency(pdfData.pageSummary?.totalTxnTotal), style: 'rightAlign' },
    ]

    const reportRow = [
        { text: 'Grand Total', style: 'rowHeading' },
        { text: pdfData.reportSummary?.totalItemsCount.toFixed(2), style: 'cell' },
        { text: formatCurrency(pdfData.reportSummary?.totalSubTotal), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.totalDiscount), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.totalTax), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.totalAdditionalTaxAmount), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.totalTxnTotal), style: 'rightAlign' },
    ]

    const tableBody =
        pdfData?.data?.length > 0
            ? parseToPdfData(pdfData.data).map((row) =>
                  row.map((cell, index) => ({
                      text: cell,
                      style: [
                          'cell',
                          index === 0 || index === 1 || index === 2 || index === 3 || index === 4
                              ? 'leftAlign'
                              : index === 5
                              ? 'center'
                              : 'rightAlign',
                      ],
                  }))
              )
            : []

    const pdfTemplateDefault = React.useMemo(() => {
        return {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [10, 45, 15, 45],
            footer: function (currentPage, pageCount) {
                const isLastPage = currentPage === pageCount
                // Content for every page
                if (!isLastPage) {
                    return {
                        columns: [
                            {
                                table: {
                                    headerRows: 0,
                                    widths: [440, 50, 65, 50, 40, 60, 50],
                                    body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                                },
                                margin: [15, 10, 15, 10],
                            },
                        ],
                    }
                }
                // Content for the last page
                return {
                    columns: [
                        {
                            table: {
                                headerRows: 0,
                                widths: [405, 60, 60, 50, 60, 60, 60],
                                body: [
                                    pageRow.map((cell) => ({ text: cell.text, style: cell.style })),
                                    reportRow.map((cell) => ({ text: cell.text, style: cell.style })),
                                ],
                            },
                            margin: [15, 5, 15, 20],
                        },
                    ],
                }
            },
            content: [
                {
                    image: imageData,
                    width: 70,
                },
                {
                    text: 'Daily Sales Report',
                    style: 'header',
                    alignment: 'center',
                },
                {
                    layout: {
                        fillColor: function (rowIndex) {
                            return rowIndex % 2 === 1 ? '#f2f2f2' : null
                        },
                    },
                    style: 'withMargin',
                    alignment: 'center',
                    table: {
                        headerRows: 1,
                        widths: [60, 65, 90, 105, 50, 60, 60, 50, 60, 60, 60],
                        body: [
                            headers.map((header, index) => {
                                return { text: header, style: 'headerRow' }
                            }),
                            ...tableBody,
                        ],
                    },
                },
            ],
            defaultStyle: {
                font: 'NimbusSans',
            },
            styles: {
                withMargin: {
                    margin: [5, 20, 20, 5],
                },
                header: {
                    fontSize: 18,
                    bold: true,
                },
                headerRow: {
                    fillColor: '#ADD8E6',
                    bold: true,
                    fontSize: 10,
                },
                cell: {
                    fontSize: 9,
                    alignment: 'center',
                },
                rightAlign: {
                    alignment: 'right',
                    fontSize: 9,
                },
                leftAlign: {
                    alignment: 'left',
                    fontSize: 9,
                },
                rowHeading: {
                    alignment: 'center',
                    fontSize: 10,
                },
            },
        }
    }, [imageData, headers, pdfData])

    useEffect(() => {
        const template = { ...pdfTemplateDefault }
        setpdfTemplate(template)
    }, [headers, pdfData, pdfTemplateDefault])

    const create = async () => {
        // This will load the data
        await pdfMutation.mutate()
        setPdfDownloading(true)
    }

    useEffect(() => {
        if (!pdfDownloading) return
        setPdfDownloading(false)
        const pdfDocGenerator = pdfMake.createPdf(pdfTemplate)
        let today = new Date()
        pdfDocGenerator.download(`Daily_Sales_Report_${today.toLocaleDateString()}_${today.toLocaleTimeString()}`)
    }, [pdfTemplate])

    return (
        <Div>
            <h1 className='report-heading'>Daily Sales Report</h1>
            <LoadingButton
                sx={{ float: 'right', marginRight: '90px', marginTop: '-55px' }}
                onClick={create}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <DownloadIcon sx={{ fontSize: 'medium', marginRight: '2px' }} /> Download Report
            </LoadingButton>
            <LoadingButton
                sx={{ float: 'right', marginRight: '9px', marginTop: '-55px' }}
                onClick={handleFilterClick}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <FilterAltIcon fontSize='medium' />
            </LoadingButton>
            {isFilterVisible && <DailySaleReportFilter mutation={viewMutation} />}
            <>
                <div className='report-container'>
                    {loading && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    {!loading && dailySales.totalCount === 0 ? (
                        <Typography variant={'primary'}>No data. Select suitable filter to view data</Typography>
                    ) : (
                        <div className='custom-table' sty>
                            <table>
                                <thead>
                                    <tr>
                                        {headers.map((h, index) => (
                                            <th
                                                key={h}
                                                style={{
                                                    whiteSpace: index === 4 || index === 5 ? 'pre' : 'pre-line',
                                                }}>
                                                {h}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dailySales.data?.map((d, index) => (
                                        <tr key={index} style={{ fontSize: '13px' }}>
                                            <td style={{ width: '90px' }}>{d.invoiceNo}</td>
                                            <td style={{ width: '90px' }}>{d.soldBy}</td>
                                            <td style={{ width: '110px' }}>{d.counterNO}</td>
                                            <td style={{ width: '150px' }}>{d.customerName}</td>
                                            <td style={{ width: '90px' }}>{d.saleType}</td>
                                            <td style={{ textAlign: 'center', width: '70px' }}>{d.itemsCount}</td>
                                            <td style={{ textAlign: 'right', width: '80px' }}>
                                                {formatCurrency(d.subTotal)}
                                            </td>
                                            <td style={{ textAlign: 'right', width: '80px' }}>
                                                {formatCurrency(d.discount)}
                                            </td>
                                            <td style={{ textAlign: 'right', width: '80px' }}>
                                                {formatCurrency(d.tax)}
                                            </td>
                                            <td style={{ textAlign: 'right', width: '80px' }}>
                                                {formatCurrency(d.additionalTaxAmount)}
                                            </td>
                                            <td style={{ textAlign: 'right', width: '80px' }}>
                                                {formatCurrency(d.total)}
                                            </td>
                                        </tr>
                                    ))}
                                    {dailySales.pageSummary && (
                                        <tr style={{ fontSize: '13px' }}>
                                            <td colSpan={5} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                Page Total
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {dailySales.pageSummary.totalItemsCount !== undefined
                                                    ? dailySales.pageSummary.totalItemsCount.toFixed(2)
                                                    : ''}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.pageSummary.totalSubTotal ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.pageSummary.totalDiscount ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.pageSummary.totalTax ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.pageSummary.totalAdditionalTaxAmount ?? '')}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.pageSummary.totalTxnTotal ?? '')}
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td colSpan={headers.length} />
                                    </tr>
                                    {currentPage === totalPages && dailySales.reportSummary && (
                                        <tr style={{ fontSize: '13px' }}>
                                            <td colSpan={5} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                Grand Total
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {dailySales.reportSummary.totalItemsCount !== undefined
                                                    ? dailySales.reportSummary.totalItemsCount.toFixed(2)
                                                    : ''}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.reportSummary.totalSubTotal)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.reportSummary.totalDiscount)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.reportSummary.totalTax)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.reportSummary.totalAdditionalTaxAmount)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(dailySales.reportSummary.totalTxnTotal)}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Stack>
                        <Pagination
                            count={Math.ceil(dailySales.totalCount / itemsPerPage)}
                            onChange={(event, page) => {
                                handlePageChange(page)
                            }}
                            page={currentPage}
                            siblingCount={1}
                            boundaryCount={1}
                            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px' }}
                            variant='outlined'
                        />
                    </Stack>
                </div>
            </>
        </Div>
    )
}

export default DailySalesReportLayout
