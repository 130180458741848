import React from 'react'
import ReportAppLayout from './reportLayout/ReportAppLayout'
import ReportAppProvider from './reportLayout/ReportAppProvider'

const ReportApp = () => {
    return (
        <div>
            <ReportAppProvider>
                <ReportAppLayout />
            </ReportAppProvider>
        </div>
    )
}
export default ReportApp
