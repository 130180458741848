import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import 'devextreme-react/tag-box'
import { useSnackbar } from 'notistack'
import AutoNumberForm from '../../AutoNumberForm'
import { getOrderAutoNumber, updateOrderAutoNumber } from 'app/redux/store/features/autonumbers'

const Order = () => {
    const navigate = useNavigate()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { orderAutoNumber, loading, error } = useSelector((state) => state.autoNumbers)
    const order = { ...orderAutoNumber }

    useEffect(() => {
        dispatch(getOrderAutoNumber(''))
    }, [dispatch])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

  function handleError(error) {
      if (error.status === '401') {
          navigate('/profile/signout')
      } else if (error.detail) {
          enqueueSnackbar(error.detail, {
              variant: 'error',
              anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
              },
          })
      } else {
          enqueueSnackbar(error, {
              variant: 'error',
              anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
              },
          })
      }
  }

    const onUpdateOrder = async () => {
        setApiCallInProgress(true)
        dispatch(updateOrderAutoNumber(order))
        enqueueSnackbar('Order Auto Number updated successfully', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    }

    return (
        <React.Fragment>
            <AutoNumberForm
                autoNumberData={order}
                heading={'Order'}
                onUpdate={onUpdateOrder}
                reloadAutoNumber={() => dispatch(getOrderAutoNumber(''))}
            />
        </React.Fragment>
    )
}

export default Order
