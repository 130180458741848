import React, { useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import TextBox from 'devextreme-react/text-box'
import notify from 'devextreme/ui/notify'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import Div from '@jumbo/shared/Div'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import RadioGroup from 'devextreme-react/radio-group'
import { updateTransferRequestStatus } from 'app/redux/store/features/requests'
import { useDispatch } from 'react-redux'

const ChangeRequestStatusForm = ({ request }) => {
    const { hideDialog } = useJumboDialog()
    const dispatch = useDispatch()

    const hideDialogAndRefreshList = () => {
        hideDialog()
        notify(
            { message: 'Transfer Request Status Updated successfully', width: 300, position: 'top right' },
            'success',
            1500
        )
    }

    const requestStatusMutation = useMutation((data) => {
        dispatch(updateTransferRequestStatus(data))
        hideDialogAndRefreshList()
    })

    const onFormSubmit = () => {
        requestStatusMutation.mutate({
            transferStatus: selectionPriority,
            transferRequestId: request.transferRequestId,
        })
    }

    const branchSelection = {
        Draft: [
            { id: 3, text: 'Submit' },
            { id: 5, text: 'Cancel' },
        ],
        Submitted: [
            { id: 10, text: 'Aprrove' },
            { id: 5, text: 'Cancel' },
        ],
        Approved: [{ id: 5, text: 'Cancel' }],
        Cancelled: [{ id: 7, text: 'Reopen' }],
        Reopened: [{ id: 5, text: 'Cancel' }],
    }

    const initialStatus = ''
    const [selectionPriority, setSelectionPriority] = useState(initialStatus)

    const changeSelectionPriority = useCallback(
        (e) => {
            setSelectionPriority(e.value)
        },
        [setSelectionPriority]
    )

    return (
        <Div>
            <Div sx={{ borderBottom: 1, borderColor: 'success.main' }}>
                <Div sx={{ m: 3 }}>
                    <Typography>Current Status</Typography>
                    <TextBox value={request.transferStatusName} height={40} readOnly={true} />
                </Div>
            </Div>
            <Div>
                <Div sx={{ m: 3 }}>
                    <Typography sx={{ mb: 1 }}>New Request Status</Typography>
                    <RadioGroup
                        className='horizontalRadioGroupRequest'
                        items={branchSelection[request.transferStatusName] || []}
                        value={selectionPriority}
                        defaultValue={selectionPriority}
                        layout='vertical'
                        valueExpr='id'
                        displayExpr='text'
                        onValueChanged={changeSelectionPriority}
                    />
                </Div>
            </Div>
            <Stack direction={'row'} spacing={2} sx={{ m: 'auto', ml: 10.5, mb: 2 }}>
                <LoadingButton
                    variant='contained'
                    color='success'
                    onClick={onFormSubmit}
                    disabled={!selectionPriority} // Disable button if no selection is made
                    size='large'
                    sx={{ width: 100 }}>
                    {' '}
                    Save
                </LoadingButton>
                <LoadingButton variant='outlined' color='success' onClick={hideDialog} size='large'>
                    {' '}
                    Cancel
                </LoadingButton>
            </Stack>
        </Div>
    )
}

export default ChangeRequestStatusForm
