import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import Typography from '@mui/material/Typography'
import { TabContext, TabPanel } from '@mui/lab'
import OnlineTransaction from './components/OnlineTransaction'
import AllTransaction from './components/AllTransaction'

const ManageSales = () => {
    const navigate = useNavigate()
    const { tabTitle } = useParams()
    const [title, setTitle] = React.useState(tabTitle ? tabTitle : 'all-transaction')

    const onTabSelectionChanged = (e, newValue) => {
        setTitle(newValue)
        navigate(`/store/sales/${newValue}`)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} sx={{ mt: 1 }} variant={'h4'}>
                        Sales
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={title}>
                    <TabList sx={{ bgcolor: 'background.default' }} onChange={onTabSelectionChanged}>
                        <Tab
                            label={'All Transactions'}
                            value={'all-transaction'}
                            sx={{ width: '162px', textTransform: 'capitalize' }}
                        />
                        <Tab
                            label={'Online Transactions'}
                            value={'online-transaction'}
                            sx={{ width: '188px', textTransform: 'capitalize' }}
                        />
                    </TabList>
                    <TabPanel value='all-transaction' sx={{ padding: '4px 1px 40px 1px' }}>
                        <AllTransaction />
                    </TabPanel>
                    <TabPanel value='online-transaction' sx={{ padding: '12px 10px 45px 10px' }}>
                        <OnlineTransaction />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default ManageSales
