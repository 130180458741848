let transactionStatusService = {}

transactionStatusService.TransactionStatusList = [
    { id: '87f57694-ba65-4370-8a61-00d84801d492', name: 'Over Due' },
    { id: 'c6931f7c-91d7-42db-9c9e-035c0b6ed015', name: 'Return' },
    { id: '80909f54-ee39-4327-af11-2b7509e8686a', name: 'Received' },
    { id: '5cba79ed-3256-4873-9c5e-ca4828dab771', name: 'Placed' },
    { id: '5f291a2e-c998-428d-b7b1-e24cfc8c1593', name: 'Canceled' },
]

transactionStatusService.getTransactionStatus = () => {
    return transactionStatusService.TransactionStatusList
}

transactionStatusService.getTransactionStatusData = (row) => {
    const name = transactionStatusService.TransactionStatusList.find((item) => item.id === row.name)
    return name ? name.name : ''
}

export default transactionStatusService
