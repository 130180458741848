import React from 'react'
import Divider from '@mui/material/Divider'
import { Grid } from '@mui/material'
import { Card, CardContent, ListItemText, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import DateRangeIcon from '@mui/icons-material/DateRange'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import Span from '@jumbo/shared/Span'
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend'
import CommentIcon from '@mui/icons-material/Comment'
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { formatDate } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const TransferLeftInfoCard = ({ transfer }) => {
    return (
        <Grid>
            <Card sx={{ height: 360 }}>
                <CardContent sx={{ justifyContent: 'center' }}>
                    <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={12} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <List sx={{ width: '100%', color: 'text.primary' }}>
                                <ListItemText
                                    disablePadding
                                    primary={
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <RequestQuoteIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                            Transfer will be sent from
                                            <br />
                                            <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                                {transfer.senderBranchName}
                                            </Span>{' '}
                                            branch and received by
                                            <br />
                                            <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                                {transfer.receivingBranchName}
                                            </Span>
                                        </Typography>
                                    }
                                />
                                <ListItemText sx={{ mt: 1.7 }}>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <SignalWifiStatusbar4BarIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Transfer was created by <br />{' '}
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                            {' '}
                                            {transfer.staffName}{' '}
                                        </Span>{' '}
                                        on <br />
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                            {' '}
                                            {transfer.createdDate ? formatDate(transfer.createdDate) : ''}{' '}
                                        </Span>
                                    </Typography>
                                </ListItemText>
                                <ListItemText sx={{ mt: 1.7 }}>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <DisabledByDefaultIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Transfer is cancelled By <br />
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                            {transfer.cancelledByName}
                                        </Span>{' '}
                                        on <br />
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                            {' '}
                                            {transfer.cancelledOn ? formatDate(transfer.cancelledOn) : ''}
                                        </Span>
                                    </Typography>
                                </ListItemText>
                                <ListItemText sx={{ mt: 1.7 }}>
                                    <Typography
                                        variant={'h5'}
                                        color='text.primary'
                                        fontFamily='Lobster, cursive'
                                        sx={{ paddingTop: '4px' }}>
                                        <CancelScheduleSendIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Cancellation reason was mentioned as <br />
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '4%' }}>
                                            {transfer.cancelledReason}
                                        </Span>
                                    </Typography>
                                </ListItemText>
                            </List>
                        </Grid>
                        <Grid item sx={{ width: '100%', display: 'flex', alignItems: 'center', ml: 3.9, mt: '-6.5%' }}>
                            <List sx={{ width: '100%' }}>
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <DateRangeIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Shipment Cost of this Transfer is <br />
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                            {'  '}
                                            {formatCurrency(transfer?.shippingCost ?? '')}
                                        </Span>
                                    </Typography>
                                </ListItemText>
                                <Divider />
                                <ListItemText sx={{ mt: 3.5 }}>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <LocalShippingIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Shipping Comment given are
                                        <br />
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                            {'  '}
                                            {transfer.shippingComments}{' '}
                                        </Span>
                                    </Typography>
                                </ListItemText>
                                <Divider />
                                <ListItemText sx={{ mt: 1.5 }}>
                                    <Typography
                                        variant={'h5'}
                                        color='text.primary'
                                        fontFamily='Lobster, cursive'
                                        sx={{ paddingTop: '4px' }}>
                                        <CommentIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Comments
                                    </Typography>
                                </ListItemText>
                                <ListItemText>
                                    <Typography variant={'h5'} color='secondary.dark'>
                                        {transfer.comments && (
                                            <pre>
                                                <Span
                                                    style={{
                                                        fontSize: '1rem',
                                                        color: 'secondary.dark',
                                                        fontFamily: 'Lobster, cursive',
                                                        fontWeight: '400',
                                                        lineHeight: 1.5,
                                                        marginLeft: '12%',
                                                        whiteSpace: 'pre-wrap',
                                                    }}>
                                                    {transfer.comments + ' '}
                                                </Span>
                                            </pre>
                                        )}
                                    </Typography>
                                </ListItemText>
                            </List>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default TransferLeftInfoCard
