import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from '@mui/material/Select'
import { MenuItem } from '@mui/material'
import useApp from '../../../../../hooks/useApp'
import Div from '@jumbo/shared/Div'

const CompanySelection = () => {
    const { branchData, companySelection, setCompanySelection } = useApp()
    const navigate = useNavigate()
    const onCompanyChange = (companyId) => {
        setCompanySelection({ companyId: companyId.target.value })
        navigate(0)
    }

    return (
        <Div>
            <Select
                defaultValue=''
                value={companySelection?.companyId}
                sx={{ mt: 0.5, mr: 3, width: 250, height: 37 }}
                onChange={onCompanyChange}>
                {branchData?.companyList?.map((comp) => (
                    <MenuItem key={comp.companyId} value={comp.companyId}>
                        {comp.companyName}
                    </MenuItem>
                ))}
            </Select>
        </Div>
    )
}

export default CompanySelection
