import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import LinkedCompanyBranchDropdown from './components/LinkedCompanyBranchDropdown'
import { getLinkedBranchList, linkUserBranch } from 'app/redux/store/features/users'
import LinkedCompanyBranchGrid from './components/LinkedCompanyBranchGrid'
import utilServices from 'app/services/util-services'

const LinkedCompanyBranch = ( {userId} ) => {

    const [filter, setFilter] = useState({
        companyId: null,
        branchId: null,
    });
    const [currentPage, setCurrentPage] = useState(0)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const dispatch = useDispatch()
    const pageSize = 25;

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getLinkedBranchList({ userId: userId, skip: 0, take: pageSize }))
    }, [dispatch])

    const onClickAdd = useMutation({
        mutationFn: (currentfilter) => {
            if (!utilServices.isNullOrUndefined(filter.companyId) && !utilServices.isNullOrUndefined(filter.branchId))
            {
                dispatch(linkUserBranch({ ...currentfilter, userId: userId, companyId: filter.companyId, branchId: filter.branchId  }))
                setApiCallInProgress(true)           
                dispatch(getLinkedBranchList({  userId: userId, skip: 0, take: pageSize }))
                setFilter(filter.branchId=null)
            }
            setFilter(currentfilter)
        }
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = {  userId: userId, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getLinkedBranchList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            userId: userId,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getLinkedBranchList(currentFilter))
        setFilter(currentFilter)
    }

    const setFilterCompany = (companyId ) => {
        // Update the state in the company component
        setFilter({ ...filter, companyId });
    };

    const setFilterBranch = ( branchId) => {
        // Update the state in the branch component
         setFilter({ ...filter, branchId });
    };
    
    return (
    <React.Fragment>  
        <Grid>
            <LinkedCompanyBranchDropdown
                onClickAdd={onClickAdd}
                setFilterCompany={setFilterCompany}   //passsing setFilter to child
                setFilterBranch={setFilterBranch}     //passsing setFilter to child 
            />
        </Grid>
        <Grid>
            <LinkedCompanyBranchGrid
                userId= {userId}
                onPageChange={onPageChange}
                onColumnSort={onColumnSort}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                apiCallInProgress={apiCallInProgress}
                setApiCallInProgress={setApiCallInProgress}
            />
        </Grid>
    </React.Fragment>
    )
}

export default LinkedCompanyBranch