let AuditTypeService = {}

AuditTypeService.AuditTypesList = [
    { id: 0, auditType: 'Default' },
    { id: 1, auditType: 'System Logs' },
    { id: 3, auditType: 'User Logs' },
]

AuditTypeService.getAuditTypeInfo = () => {
    return AuditTypeService.AuditTypesList
}

AuditTypeService.getAuditTypeInfoData = (row) => {
    const auditType = AuditTypeService.AuditTypesList.find((item) => item.id === row.auditType)
    return auditType ? auditType.auditType : ''
}

export default AuditTypeService
