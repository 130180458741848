import React from 'react'
import Div from '@jumbo/shared/Div'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import BranchGrid from './components/branches/BranchGrid'
import CompanyGrid from './components/companyGridSidebar/CompanyGrid'

const Branches = () => {
    return (
        <JumboCardQuick
            noWrapper
            headerSx={{
                height: 40,
                bgcolor: 'secondary.main',
                pt: 3.2,
            }}>
            <Div>
                <JumboContentLayout
                    sidebar={
                        <Grid>
                            <CompanyGrid />
                        </Grid>
                    }
                    layoutOptions={{
                        sidebar: {
                            sx: {
                                mr: 1,
                                width: '25%',
                            },
                        },
                    }}>
                    <Grid sx={{ mb: 1.5 }}>
                        <BranchGrid />
                    </Grid>
                </JumboContentLayout>
            </Div>
        </JumboCardQuick>
    )
}

export default Branches
