import { USE_IMAGE_PLACEHOLDERS } from './constants/paths'

export const getAssetPath = (url, size) => {
    if (USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`
    }

    return url
}

export const buildQuery = (baseUrl, userQuery) => {
    const query = []
    for (const key in userQuery) {
        if (userQuery[key]) query.push(encodeURIComponent(key) + '=' + encodeURIComponent(userQuery[key]))
    }
    return baseUrl + (query.length ? '?' + query.join('&') : '')
}

export const buildFilter = (filters) => {
    let result = {}
    if (Array.isArray(filters)) {
        if (Array.isArray(filters[0])) {
            for (const key in filters) {
                if (Array.isArray(filters[key])) {
                    result[filters[key][0]] = filters[key][2]
                }
            }
            return result
        }
        result[filters[0]] = filters[2]
    }
    return result
}
