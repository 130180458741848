import React from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import LeftInfoPanelList from './components/LeftInfoPanelList'

const LeftInfoPanel = ({ OnPageChangeShipment }) => {

    return (
        <JumboCardQuick wrapperSx={{ p: 0 }}>
            <LeftInfoPanelList OnPageChangeShipment={OnPageChangeShipment} />
        </JumboCardQuick>
    )
}

export default LeftInfoPanel
