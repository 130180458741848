import React, { useState } from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import { ListItemAvatar, ListItemText, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { useNavigate } from 'react-router-dom'
import useReportApp from '../../hooks/useReportApp'

const ReportViewItem = ({ reportViewItem: reportViewItem }) => {
    const { setActiveReport, activeReportColorList } = useReportApp()
    const navigate = useNavigate()
    const [activeReportColor, setActiveReportColor] = useState('text.primary')
    const handleReportItemClick = () => {
        setActiveReport({
            category: reportViewItem?.category,
            id: reportViewItem?.id,
        })
        navigate(`/reports/${reportViewItem?.category}/${reportViewItem?.id}`)
    }

    React.useEffect(() => {
        activeReportColorList.map((item) => {
            if (item.id === reportViewItem.id) {
                setActiveReportColor(item.color)
            }
        })
    }, [activeReportColorList])

    return (
        <List disablePadding>
            <ListItemButton component='li' onClick={handleReportItemClick} >
                <ListItemText
                    primary={
                        <Typography
                            component='div'
                            variant='body1'
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                            <Typography variant='h6' mb={0.5} fontSize={15} color={activeReportColor}>
                                {reportViewItem?.name}
                            </Typography>
                        </Typography>
                    }
                    secondary={
                        <Typography
                            component='div'
                            variant='body1'
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                            <Typography noWrap variant='body1' color={'text.secondary'} fontSize={13}>
                                {reportViewItem?.description}
                            </Typography>
                        </Typography>
                    }
                />
            </ListItemButton>
            <Divider component='li' />
        </List>
    )
}
export default ReportViewItem
