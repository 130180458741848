import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { TabContext, TabPanel } from '@mui/lab'
import RequestHeaderCard from './components/requestHeaderCard/RequestHeaderCard'
import RequestInfoBar from './components/requestInfoBar/RequestInfoBar'
import { getRequestById, selectItemById } from 'app/redux/store/features/requests'
import RequestItemListGrid from './components/requestDetailBar/components/requestItemList/RequestItemListGrid'
import RequestActivityLogGrid from './components/requestDetailBar/components/requestActivityLog/RequestActivityLogGrid'
import utilServices from 'app/services/util-services'

const RequestView = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { transferRequestId } = state
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { requests, loading, error } = useSelector((state) => state.requests)
    const request = selectItemById(requests, transferRequestId)
    const requestItemsData = request.requestItems || { data: [], totalCount: 0 }
    const activityLogsData = request.activityLogs || { data: [], totalCount: 0 }

    useEffect(() => {
        if (utilServices.isNullOrUndefined(request.transferRequestId)) dispatch(getRequestById({ transferRequestId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const [tabTitle, setTabTitle] = React.useState('item-list')

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} sm={12} lg={12}>
                <RequestHeaderCard request={request} />
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
                <RequestInfoBar request={request} />
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
                <Grid>
                    <TabContext value={tabTitle}>
                        <TabList sx={{ bgcolor: 'divider' }} onChange={(e, newValue) => setTabTitle(newValue)}>
                            <Tab label={'Item List'} value={'item-list'} sx={{ width: '160px' }} />
                            <Tab label={'Activity Logs'} value={'activity-logs'} sx={{ width: '160px' }} />
                        </TabList>
                        <TabPanel
                            value='item-list'
                            sx={{
                                pt: '0%',
                                pl: '0%',
                                pr: '0%',
                            }}>
                            <RequestItemListGrid
                                transferRequestId={transferRequestId}
                                requestItemsData={requestItemsData}
                            />
                        </TabPanel>
                        <TabPanel
                            value='activity-logs'
                            sx={{
                                pt: '0%',
                                pl: '0%',
                                pr: '0%',
                            }}>
                            <RequestActivityLogGrid
                                transferRequestId={transferRequestId}
                                activityLogsData={activityLogsData}
                            />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RequestView
