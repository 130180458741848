import React from 'react'
import Divider from '@mui/material/Divider'
import { Grid } from '@mui/material'
import { Card, CardContent, ListItemText, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import DateRangeIcon from '@mui/icons-material/DateRange'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'
import Span from '@jumbo/shared/Span'
import CommentIcon from '@mui/icons-material/Comment'
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import { formatDate } from 'app/utils/components/FormatDateTime/FormatDateTime'

const TransferLeftInfoCard = ({ request }) => {
    return (
        <Grid>
            <Card sx={{ height: 300 }}>
                <CardContent sx={{ justifyContent: 'center' }}>
                    <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={12} sx={{ width: '100%', display: 'flex', alignItems: 'center', mt: '-7%' }}>
                            <List sx={{ width: '100%', color: 'text.primary' }}>
                                <ListItemText
                                    sx={{ mt: 1.7 }}
                                    disablePadding
                                    primary={
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <RequestQuoteIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Request was raised by <br />
                                            <Span sx={{ color: 'secondary.dark', marginLeft: '9%' }}>
                                                {' '}
                                                {request.branchName}
                                            </Span>{' '}
                                        </Typography>
                                    }
                                />
                                <ListItemText sx={{ mt: 1.7 }}>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <DateRangeIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} /> Request was created
                                        on
                                        <br />
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                            {'  '}
                                            {request.createdDate ? formatDate(request.createdDate) : ''}
                                        </Span>
                                    </Typography>
                                </ListItemText>
                                <ListItemText sx={{ mt: 1.7 }}>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <EditCalendarIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Request was updated on
                                        <br />
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                            {'  '}
                                            {request.updatedDate ? formatDate(request.updatedDate) : ''}
                                        </Span>
                                    </Typography>
                                </ListItemText>
                            </List>
                        </Grid>
                        <Grid item sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <List sx={{ width: '100%' }}>
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <CancelPresentationIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} /> Cancelled On
                                        <Span sx={{ color: 'secondary.dark' }}>
                                            {' '}
                                            {request.cancelledOn ? formatDate(request.cancelledOn) : ''}
                                        </Span>
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <DisabledByDefaultIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Cancelled By{' '}
                                        <Span sx={{ color: 'secondary.dark' }}>{request.cancelledByName}</Span>
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <CancelScheduleSendIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Cancelled reason was
                                        <br />
                                        <Span
                                            sx={{
                                                color: 'secondary.dark',
                                                marginLeft: '11%',
                                                display: 'block',
                                            }}>
                                            {request.cancelledReason}
                                        </Span>
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <CommentIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Comments
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2 }} />
                            </List>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default TransferLeftInfoCard
