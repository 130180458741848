import React from 'react'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import { useMediaQuery } from '@mui/material'
import useDefinitionApp from '../hooks/useDefinitionApp'
import DefinitionAppSidebar from '../components/DefinitionAppSidebar/DefinitionAppSidebar'
import DefinitionAppContent from '../components/DefinitionAppContent/DefinitionAppContent'

const DefinitionAppLayout = () => {
    const { theme } = useJumboTheme()
    const { activeDefinition } = useDefinitionApp()
    const md = useMediaQuery(theme.breakpoints.down('md'))

    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx:
                    md && activeDefinition
                        ? { display: 'none' }
                        : {
                              width: 280,
                              marginRight: 1,
                          },
            },
        }),
        [theme, md, activeDefinition]
    )

    return (
        <JumboContentLayout sidebar={<DefinitionAppSidebar />} layoutOptions={layoutOptions}>
            <DefinitionAppContent />
        </JumboContentLayout>
    )
}

export default DefinitionAppLayout
