import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    permissions: { data: [], totalCount: 0 },
    permissionLookups: { data: [], totalCount: 0 },
    updateInProgress: false,
    loading: false,
    error: null,
}

const permissionSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        getPermissions: (state, action) => {
            state.permissions = action.payload
            state.loading = false
        },
        updatePermissionStart: (state, action) => {
            state.updateInProgress = true
            state.loading = true
            state.error = null
        },
        updateExistingPermission: (state, action) => {
            if (action.payload.data?.length > 0) {
                action.payload.data.foreach((item) => {
                    const index = state.permissions.data.findIndex((placement) => placement.id === item.id)
                    if (index >= 0) state.permissions[index] = item
                })
            }
            state.loading = false
            state.updateInProgress = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.permissionId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const { apiRequested, apiRequestedFailed, getPermissions, updatePermissionStart, updateExistingPermission } =
    permissionSlice.actions
export default permissionSlice.reducer

export const getPermissionList = (filter) => {
    let url = `/User/permission/${filter.userType}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getPermissions.type,
        onError: apiRequestedFailed.type,
    })
}

export const updatePermission = (permission) => {
    let url = `/User/permission/save`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: permission,
        onStart: updatePermissionStart.type,
        onSuccess: updateExistingPermission.type,
        onError: apiRequestedFailed.type,
    })
}
