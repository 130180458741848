import React, { useState, useCallback, useMemo } from 'react'
import DataSource from 'devextreme/data/data_source'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import utilServices from 'app/services/util-services'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import CustomStore from 'devextreme/data/custom_store'

const InventorySubCategoryGridFilter = ({ mutation, display, filterIconColor }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    let initialValues = {
        subCategoryName: '',
    }

    const [filter, setFilter] = useState({ fkCategoryId: null })

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const inventoryCategoriesLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await inventoryService.getInventoryCategoriesLookupList({ categoryName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryCategoriesLookupById = useCallback(
        async (key) => {
            try {
                return await inventoryService.getInventoryCategoriesLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryCategoryStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'inventoryCategoryId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await inventoryCategoriesLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return inventoryCategoriesLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = async (subCategoryName) => {
        await mutation.mutate({
            subCategoryName: subCategoryName,
            ...filter,
        })
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit(data.subCategoryName)
        setSubmitting(false)
    }
    const handleClear = () => {
        filterIconColor(false)
        setFilter({ fkCategoryId: null })
    }

    const onCategorySelectionChanged = (value) => {
        setFilter({ ...filter, fkCategoryId: value?.inventoryCategoryId })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick
                        noWrapper
                        id='filterBody'
                        variant={'primary'}
                        sx={{ display: display, borderRadius: 0 }}>
                        <Div style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Category Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={inventoryCategoryStore}
                                        displayExpr={'categoryName'}
                                        value={filter.fkCategoryId}
                                        searchEnabled={true}
                                        onValueChange={onCategorySelectionChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Sub-Categories</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='subCategoryName' />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1} sx={{}}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type='reset'
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default InventorySubCategoryGridFilter
