let DiscountTypeService = {}

DiscountTypeService.DiscountTypeList = [
    { id: 0, discountType: 'FixedAmount' },
    { id: 1, discountType: 'Percentage' },
    { id: 2, discountType: 'BuyXGetY' },
    { id: 3, discountType: 'SimpleDiscountPercentage' },
    { id: 4, discountType: 'SimpleDiscountFixed' },
    { id: 5, discountType: 'DiscountOverride' },
]

DiscountTypeService.getDiscountTypeInfo = () => {
    return DiscountTypeService.DiscountTypeList
}

DiscountTypeService.getDiscountTypeInfoData = (row) => {
    const discountType = DiscountTypeService.DiscountTypeList.find((item) => item.id === row.discountType)
    return discountType ? discountType.discountType : ''
}

export default DiscountTypeService
