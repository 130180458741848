import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'
import { getBranchUserLookup, getExistingBranchUserLookupById } from './users'

const initialState = {
    dailySalesSummary: { data: [], totalCount: 0, pageSummary: {}, ReportSummary: {} },
    dailySales: { data: [], totalCount: 0, pageSummary: {}, ReportSummary: {} },
    saleDetail: { data: [], totalCount: 0, ReportSummary: {} },
    salesTypes: { data: [], totalCount: 0 },
    terminalTypes: { data: [], totalCount: 0 },
    cashReasonTypes: { data: [], totalCount: 0 },
    inventoryLoadForm: { data: [], totalCount: 0 },
    inventoryListReport: { data: [], totalCount: 0 },
    customersReceivablesReport: { data: [], totalCount: 0 },
    customersReceivablesDetailReport: { data: [], totalCount: 0 },
    saleProfitLossReport: { data: [], totalCount: 0 },
    dailycashdetailreport: { data: [], totalCount: 0, pageSummary: {}, ReportSummary: {} },
    cashRegisterReport: { data: [], totalCount: 0, ReportSummary: {} },
    orderListReport: { data: [], totalCount: 0 },
    supplierAccountReport: { data: [], totalCount: 0 },
    loading: false,
    error: null,
}

const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },

        getSaleTypeLookup: (state, action) => {
            state.salesTypes = action.payload
            state.loading = false
        },
        getExistingSaleTypeLookupById: (state, action) => {
            const index = state.salesTypes.data.findIndex(
                (user) => user.saleTransTypeId === action.payload.saleTransTypeId
            )
            if (index >= 0) state.salesTypes.data[index] = action.payload
            else {
                state.salesTypes.data.push(action.payload)
            }
            state.loading = false
        },

        getTerminalLookup: (state, action) => {
            state.terminalTypes = action.payload
            state.loading = false
        },
        getExistingTerminalLookupById: (state, action) => {
            const index = state.terminalTypes.data.findIndex((user) => user.terminalId === action.payload.terminalId)
            if (index >= 0) state.terminalTypes.data[index] = action.payload
            else {
                state.terminalTypes.data.push(action.payload)
            }
            state.loading = false
        },
        getCashReasonLookup: (state, action) => {
            state.cashReasonTypes = action.payload
            state.loading = false
        },
        getDailySaleSummary: (state, action) => {
            state.dailySalesSummary = action.payload
            state.loading = false
        },
        getDailySales: (state, action) => {
            state.dailySales = action.payload
            state.loading = false
        },
        getSaleDetail: (state, action) => {
            state.saleDetail = action.payload
            state.loading = false
        },
        getInventoryLoadForm: (state, action) => {
            state.inventoryLoadForm = action.payload
            state.loading = false
        },
        getInventoryList: (state, action) => {
            state.inventoryListReport = action.payload
            state.loading = false
        },
        getCustomersReceivables: (state, action) => {
            state.customersReceivablesReport = action.payload
            state.loading = false
        },
        getCustomersReceivableDetail: (state, action) => {
            state.customersReceivablesDetailReport = action.payload
            state.loading = false
        },
        getSaleProfitLoss: (state, action) => {
            state.saleProfitLossReport = action.payload
            state.loading = false
        },
        getCashRegister: (state, action) => {
            state.cashRegisterReport = action.payload
            state.loading = false
        },
        getDailyCashDetail: (state, action) => {
            state.dailycashdetailreport = action.payload
            state.loading = false
        },
        getOrderList: (state, action) => {
            state.orderListReport = action.payload
            state.loading = false
        },
        getSupplierAccountList: (state, action) => {
            state.supplierAccountReport = action.payload
            state.loading = false
        },
    },
})

export const {
    apiRequested,
    apiRequestedFailed,
    getSaleTypeLookup,
    getExistingSaleTypeLookupById,
    getTerminalLookup,
    getExistingTerminalLookupById,
    getCashReasonLookup,
    getDailySaleSummary,
    getDailySales,
    getSaleDetail,
    getInventoryLoadForm,
    getInventoryList,
    getCustomersReceivables,
    getCustomersReceivableDetail,
    getSaleProfitLoss,
    getDailyCashDetail,
    getCashRegister,
    getOrderList,
    getSupplierAccountList,
} = reportSlice.actions
export default reportSlice.reducer

export const getSaleTypeLookupList = (filter) => {
    let url = `/epos/Report/definitions/salestypes/lookup/list?search=${filter}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSaleTypeLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSaleTypeLookupById = (filter) => {
    let url = `/epos/Report/definitions/salestypes/lookup/${filter.saleTransTypeId}`

    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingSaleTypeLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTerminalLookupList = (filter) => {
    let url = `/epos/Report/definitions/terminals/lookup/list?search=${filter}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTerminalLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTerminalLookupById = (filter) => {
    let url = `/epos/Report/definitions/terminals/lookup/${filter.terminalId}`

    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingTerminalLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCashReasonLookupList = (filter) => {
    let url = `/epos/Report/definitions/cashreasons/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCashReasonLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getDailySaleSummaryReport = (filter) => {
    let url = `/epos/Report/dailysalessummaryreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getDailySaleSummary.type,
        onError: apiRequestedFailed.type,
    })
}

export const getDailySaleReport = (filter) => {
    let url = `/epos/Report/dailysalesreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getDailySales.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSaleDetailReport = (filter) => {
    let url = `/epos/Report/saledetailreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSaleDetail.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInventoryLoadFormReport = (filter) => {
    let url = `/epos/Report/inventoryloadformlistreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getInventoryLoadForm.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInventoryListReport = (filter) => {
    let url = `/epos/Report/inventorylistreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getInventoryList.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCustomersReceivablesReport = (filter) => {
    let url = `/epos/Report/customersreceivablereport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCustomersReceivables.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCustomersReceivableDetailReport = (filter) => {
    let url = `/epos/Report/customersreceivabledetailreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCustomersReceivableDetail.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSaleProfitLossReport = (filter) => {
    let url = `/epos/Report/saleprofitandlossreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSaleProfitLoss.type,
        onError: apiRequestedFailed.type,
    })
}

export const getDailyCashDetailReport = (filter) => {
    let url = `/epos/Report/cashdetailreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getDailyCashDetail.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCashRegisterReport = (filter) => {
    let url = `/epos/Report/cashregisterlistreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCashRegister.type,
        onError: apiRequestedFailed.type,
    })
}

export const getOrderListReport = (filter) => {
    let url = `/epos/Report/orderlistreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getOrderList.type,
        onError: apiRequestedFailed.type,
    })
}

export const getSupplierAccountListReport = (filter) => {
    let url = `/epos/Report/supplieraccountsreport`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSupplierAccountList.type,
        onError: apiRequestedFailed.type,
    })
}
