import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    auditLog: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const auditLogsSlice = createSlice({
    name: 'auditLog',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },

        getAuditLogs: (state, action) => {
            state.auditLog = action.payload
            state.loading = false
            state.refreshing = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.auditLogId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const { apiRequested, refreshRequested, apiRequestedFailed, getAuditLogs } = auditLogsSlice.actions
export default auditLogsSlice.reducer

export const getAuditLogList = (filter, refresh = false) => {
    let url = '/AuditLog/list'
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getAuditLogs.type,
        onError: apiRequestedFailed.type,
    })
}
