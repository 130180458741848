let TransferInventoryStatusService = {}

TransferInventoryStatusService.transferInventoyyStatusList = [
    { id: 15, transferInventoryStatus: 'Transfer Draft Created' },
    { id: 20, transferInventoryStatus: 'Ready for Dispatch' },
    { id: 25, transferInventoryStatus: 'Dispatch in Progress' },
    { id: 30, transferInventoryStatus: 'Shipment Dispatched' },
    { id: 35, transferInventoryStatus: 'Shipment Delivered' },
    { id: 40, transferInventoryStatus: 'Shipment Received' },
    { id: 45, transferInventoryStatus: 'Received with Adjustment' },
    { id: 50, transferInventoryStatus: 'Transfer Completed' },
    { id: 60, transferInventoryStatus: 'Transfer Cancelled' },
]

TransferInventoryStatusService.getTransferInventoryStatus = () => {
    return TransferInventoryStatusService.transferInventoyyStatusList
}

TransferInventoryStatusService.getTransferStatusData = (row) => {
    const transferInventoryStatus = TransferInventoryStatusService.transferInventoyyStatusList.find(
        (item) => item.id === row.transferInventoryStatus
    )
    return transferInventoryStatus ? transferInventoryStatus.transferInventoryStatus : ''
}

export default TransferInventoryStatusService
