import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import { CustomPaging, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import { useDispatch, useSelector } from 'react-redux'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useSnackbar } from 'notistack'
import { unLinkUserBranch } from 'app/redux/store/features/users'

const LinkedCompanyBranchGrid = ({
    userId,
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const { linkedBranches, loading, error } = useSelector((state) => state.users)
    const [sorting, setSorting] = useState([{ columnName: 'companyName', direction: 'asc' }])
    const pageSize = 25

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onRemoveCompanyBranch(row.companyId, row.branchId)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const Cell = (props) => {
        let style = {
            paddingTop: 3,
            paddingBottom: 3,
        }
        if (props.column.name == 'Action')
            style = {
                ...style,
                textAlign: 'center',
            }
        else {
            style = {
                ...style,
                textAlign: 'left',
            }
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const hideDialogAndRefreshCompanyBranchList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Linked Company Branch removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveCompanyBranch = async (companyId, branchId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(unLinkUserBranch({ userId: userId, companyId: companyId, branchId: branchId }))
                hideDialogAndRefreshCompanyBranchList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'companyName', title: 'Company Name' },
        { name: 'contactName', title: 'Contact Name' },
        { name: 'branchName', title: 'Branch Name' },
        { name: 'branchType', title: 'Branch Type' },
        { name: 'address', title: 'Address' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidths = [
        { columnName: 'companyName', width: '16%' },
        { columnName: 'contactName', width: '16%' },
        { columnName: 'branchName', width: '14%' },
        { columnName: 'branchType', width: '14%' },
        { columnName: 'Action', width: '8%' },
    ]

    const sortingColumnExtensions = [
        { columnName: 'Action', sortingEnabled: false },
        { columnName: 'branchType', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={linkedBranches.data} columns={columns}>
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={linkedBranches.totalCount} />
                    <Table columnExtensions={columnWidths} cellComponent={Cell} />
                    <TableHeaderRow showSortingControls cellComponent={Cell} />
                    <SelectionState />
                    <TableSelection showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default LinkedCompanyBranchGrid
