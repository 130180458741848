import React from 'react'
import Div from '@jumbo/shared/Div'
import Link from '@mui/material/Link'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import util from '../../utils/permissions/util-permissions'
import { CanLogin } from '../../constants/Permissions'

const Logo = ({ mini, mode, sx }) => {
    const logoUrl = '/images/posLogoImg.png'
    const { authUser } = useJumboAuth()
    const canLogin = util.hasPermission(authUser, CanLogin)

    return (
        <Div sx={{ display: 'inline-flex', ...sx }}>
            {authUser && canLogin ? (
                <Link href={'/dashboard'}>
                    <img src={logoUrl} alt='Head Office System' width='150' />
                </Link>
            ) : (
                <Link href={'/profile/signout'}>
                    <img src={logoUrl} alt='Head Office System' width='150' />
                </Link>
            )}
        </Div>
    )
}

Logo.defaultProps = {
    mode: 'light',
}

export default Logo
