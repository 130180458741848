import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import { TabContext, TabPanel } from '@mui/lab'
import { getTransferById, selectItemById } from 'app/redux/store/features/transfers'
import TransferHeaderCard from './components/TransferHeaderCard/TransferHeaderCard'
import TransfersInfoBar from './components/TransferInfobar/components/TransfersInfoBar'
import TransferItemListGrid from './components/transferDetailBar/components/transferItemList/TransferItemListGrid'
import TransferActivityLogGrid from './components/transferDetailBar/components/transferActivityLogs/TransferActivityLogGrid'
import utilServices from 'app/services/util-services'

const TransferView = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { transferInventoryId } = state
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { transfers, loading, error } = useSelector((state) => state.transfers)
    const transfer = selectItemById(transfers, transferInventoryId)
    const transferItemsData = transfer.inventoryItems || { data: [], totalCount: 0 }
    const activityLogsData = transfer.activityLogs || { data: [], totalCount: 0 }

    useEffect(() => {
        if (utilServices.isNullOrUndefined(transfer.transferInventoryId))
            dispatch(getTransferById({ transferInventoryId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const [tabTitle, setTabTitle] = React.useState('item-list')

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} sm={12} lg={12}>
                <TransferHeaderCard transfer={transfer} />
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
                <TransfersInfoBar transfer={transfer} />
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
                <Grid>
                    <TabContext value={tabTitle}>
                        <TabList sx={{ bgcolor: 'divider' }} onChange={(e, newValue) => setTabTitle(newValue)}>
                            <Tab label={'Item List'} value={'item-list'} sx={{ width: '160px' }} />
                            <Tab label={'Activity Logs'} value={'activity-logs'} sx={{ width: '160px' }} />
                        </TabList>
                        <TabPanel
                            value='item-list'
                            sx={{
                                pt: '0%',
                                pl: '0%',
                                pr: '0%',
                            }}>
                            <TransferItemListGrid
                                transferItemsData={transferItemsData}
                                transferInventoryId={transferInventoryId}
                            />
                        </TabPanel>
                        <TabPanel
                            value='activity-logs'
                            sx={{
                                pt: '0%',
                                pl: '0%',
                                pr: '0%',
                            }}>
                            <TransferActivityLogGrid
                                activityLogsData={activityLogsData}
                                transferInventoryId={transferInventoryId}
                            />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TransferView
