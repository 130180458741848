import React from 'react'
import { Card, CardContent } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import Avatar from '@mui/material/Avatar'
import shadows from '@mui/material/styles/shadows'
import { alpha } from '@mui/material/styles'
import { auth } from '../../services/auth/firebase/firebase'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import { useAuthSignInWithEmailAndPassword } from '@react-query-firebase/auth'
import LoadingButton from '@mui/lab/LoadingButton'
import Div from '@jumbo/shared/Div'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { useSnackbar } from 'notistack'
import useApp from '../../hooks/useApp'

const validationSchema = yup.object({
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    password: yup.string('Enter your password').required('Password is required'),
})

const SignIn = () => {
    const { setAuthToken } = useJumboAuth()
    const { setBranchData, setBranchSelection, setCompanySelection } = useApp()
    const logInImgUrl = 'images/posLogoImg.png'
    const { enqueueSnackbar } = useSnackbar()
    const { authUser } = useJumboAuth()
    const version = process.env.REACT_APP_VERSION

    const mutation = useAuthSignInWithEmailAndPassword(auth, {
        onError() {
            enqueueSnackbar('Login failed, please enter correct email and password.  ', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        },
        onSuccess(data) {
            setAuthToken(data.user.accessToken)
        },
    })

    const onSignIn = (email, password) => {
        if (!mutation.isLoading) mutation.mutate({ email, password })
    }

    React.useEffect(() => {
        if (authUser && authUser.companyBranches) {
            let selectedCompany = authUser.companyBranches.find((el) => el.companyId === authUser.defaultCompanyId)
            let selectedBranch = ''
            if (selectedCompany) {
                selectedBranch = selectedCompany.branchList.find((el) => el.branchId === authUser.defaultBranchId)
            }

            setBranchData({ companyList: authUser.companyBranches })
            setBranchSelection({
                branchId: authUser.defaultBranchId ?? '',
                branchType: selectedBranch?.branchType,
                branchName: selectedBranch?.branchName,
            })
            setCompanySelection({ companyId: authUser.defaultCompanyId ?? '' })
        }
    }, [authUser])

    return (
        <Div
            sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '14%',
                marginBottom: '3%',
                justifyContent: 'center',
                p: (theme) => theme.spacing(4),
            }}>
            <Div sx={{ mb: 3, display: 'inline-flex' }}>
                <Link href='#' underline='none' sx={{ display: 'inline-flex' }}></Link>
            </Div>
            <Card sx={{ width: 360 }}>
                <Div sx={{ position: 'relative' }}>
                    <CardMedia
                        component='img'
                        alt='Pos Login Image'
                        image={logInImgUrl}
                        sx={{ height: 180, objectFit: 'contain' }}
                    />
                    <Div
                        sx={{
                            flex: 1,
                            inset: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: (theme) => alpha(theme.palette.common.black, 0.1),
                            p: (theme) => theme.spacing(3),
                        }}>
                        <Div
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                padding: 1,
                            }}>
                            Version: {version}
                        </Div>
                    </Div>
                </Div>
                <CardContent sx={{ pt: 0 }}>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                            password: '',
                            // email: 'admin@hqservice.com',
                            // password: 'G15YZbdp&d1pdG*p',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true)
                            onSignIn(data.email, data.password)
                            setSubmitting(false)
                        }}>
                        {({ isSubmitting }) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                <Avatar
                                    alt='User Iamge'
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        marginLeft: 'auto',
                                        boxShadow: shadows[3],
                                        transform: 'translateY(-40%)',
                                        left: 10,
                                    }}
                                />
                                <Div sx={{ mb: 3, mt: 1 }}>
                                    <JumboTextField fullWidth name='email' label='Email' />
                                </Div>
                                <Div sx={{ mb: 2, mt: 1 }}>
                                    <JumboTextField fullWidth name='password' label='Password' type='password' />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting || mutation.isLoading}>
                                    Login
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    )
}

export default SignIn
