import React, { useState, useEffect } from 'react'
import UpdateCompaniesForm from './UpdateCompaniesForm.js'
import { getCompanyById, updateCompany, selectItemById } from 'app/redux/store/features/company.js'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services.js'
import LinkedUserGrid from './LinkedUserGrid.js'
import { TabContext, TabPanel } from '@mui/lab'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'

const UpdateCompanies = () => {
    const [tabTitle, setTabTitle] = React.useState('details') // Initialize with a default value
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { companyId } = state
    const { companies, loading: companyLoading, error: companyError } = useSelector((state) => state.companies)
    const company = selectItemById(companies, companyId)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(company.companyId)) dispatch(getCompanyById({ companyId: companyId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || companyLoading) return
        setApiCallInProgress(false)
        const error = companyError
        if (error) handleError(error)
        else {
            enqueueSnackbar('Company updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/companies')
        }
    }, [companyLoading])

    const onTabSelectionChanged = (e, newValue) => {
        setTabTitle(newValue)
    }

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateCompany = async () => {
        setApiCallInProgress(true)
        dispatch(updateCompany({ ...company }))
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Company
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={tabTitle}>
                    <TabList sx={{ bgcolor: 'background.default', m: 2, mb: 0 }} onChange={onTabSelectionChanged}>
                        <Tab label={'Details'} value={'details'} sx={{ width: '160px' }} />
                        <Tab label={'Linked Users'} value={'linked-users'} sx={{ width: '180px' }} />
                    </TabList>
                    <TabPanel value='details'>
                        <UpdateCompaniesForm companyData={company} updateCompany={onUpdateCompany} />
                    </TabPanel>
                    <TabPanel value='linked-users'>
                        <LinkedUserGrid companyId={company.companyId} />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateCompanies
