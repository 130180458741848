import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import 'devextreme-react/tag-box'
import { useSnackbar } from 'notistack'
import AutoNumberForm from '../../AutoNumberForm'
import { getShipmentAutoNumber, updateShipmentAutoNumber } from 'app/redux/store/features/autonumbers'

const Shipment = () => {
    const navigate = useNavigate()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { shipmentAutoNumber, loading, error } = useSelector((state) => state.autoNumbers)
    const shipment = { ...shipmentAutoNumber }

    useEffect(() => {
        dispatch(getShipmentAutoNumber(''))
    }, [dispatch])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

function handleError(error) {
    if (error.status === '401') {
        navigate('/profile/signout')
    } else if (error.detail) {
        enqueueSnackbar(error.detail, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    } else {
        enqueueSnackbar(error, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    }
}

    const onUpdateShipment = async () => {
        setApiCallInProgress(true)
        dispatch(updateShipmentAutoNumber(shipment))
        enqueueSnackbar('Shipment Auto Number updated successfully', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    }

    return (
        <React.Fragment>
            <AutoNumberForm
                autoNumberData={shipment}
                heading={'Shipment'}
                onUpdate={onUpdateShipment}
                reloadAutoNumber={() => dispatch(getShipmentAutoNumber(''))}
            />
        </React.Fragment>
    )
}

export default Shipment
