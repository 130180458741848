const util = {}

util.hasPermission = (authSlice, canView) => {
    const permission = authSlice?.permissions.find((perm) => perm === canView)
    if (permission) {
        return true
    }
    return false
}

export default util
