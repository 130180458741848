export const defaultTransferItem = {
    transferInventoryItemId: null,
    cartName: '',
    comments: '',
    displayOrder: 0,
    requestedQty: 0,
    fkTransferInventoryId: null,
    fkSenderInvClassId: null,
    senderPurchasePriceIsAveragePrice: false,
    senderPurchasePrice: 0,
    senderSalePriceIsAveragePrice: false,
    senderSalePrice: 0,
    senderInventoryProfitMargin: 0,
    subTotal: 0,
}
