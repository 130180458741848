import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import pdfMake from 'pdfmake'
import Div from '@jumbo/shared/Div'
import { Pagination, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import DownloadIcon from '@mui/icons-material/Download'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from 'notistack'
import vfs from '../../../../../../../../../fonts/NimbusSanL/nimbus-san'
import CashRegisterReportFilter from './CashRegisterReportFilter'
import Paper from '@mui/material/Paper'
import '../../../../../../reportstyles.css'
import { Grid, Table, TableHeaderRow, TableRowDetail, VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid'

const CashRegisterReportLayout = ({
    imageData,
    viewMutation,
    pdfMutation,
    pageMutation,
    apiCallInProgress,
    setApiCallInProgress,
    pdfData,
}) => {
    const { cashRegisterReport, loading, error } = useSelector((state) => state.reports)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [isFilterVisible, setFilterVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pdfDownloading, setPdfDownloading] = useState(false)
    const [pdfTemplate, setpdfTemplate] = useState({})
    const itemsPerPage = 25
    const totalPages = Math.ceil(cashRegisterReport.totalCount / itemsPerPage)
    const dateTimeColumns = ['startTime', 'endTime']

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    pdfMake.vfs = vfs
    pdfMake.fonts = {
        NimbusSans: {
            normal: 'NimbusSanL-Reg.otf',
            bold: 'NimbusSanL-Bol.otf',
            italics: 'NimbusSanL-RegIta.otf',
            bolditalics: 'NimbusSanL-BolIta.otf',
        },
    }

    const handlePageChange = async (page) => {
        try {
            await pageMutation.mutate(page)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error updating page:', error)
        }
    }

    const handleFilterClick = () => {
        setFilterVisible(!isFilterVisible)
    }

    const headers = React.useMemo(
        () => [
            'Terminal No',
            'Cashier',
            'Start Time',
            'End Time',
            'Supplier Payment',
            'Bal. After Counting',
            'Difference',
            'Drawer Start',
            'Added',
            'Removed',
            'Sales',
            'Inst.',
            'Refund',
            'Total',
        ],
        []
    )

    const parseToPdfData = useMemo(
        () => (data) => {
            return data.map((d) => {
                return [
                    d.terminal,
                    d.Cashier,
                    formatDateTime(d.startTime),
                    formatDateTime(d.endTime),
                    formatCurrency(d.supplierPayment),
                    formatCurrency(d.cashCouted),
                    formatCurrency(d.difference),
                    formatCurrency(d.startDayCash),
                    formatCurrency(d.cashAdded),
                    formatCurrency(d.cashRemoved),
                    formatCurrency(d.cashSales),
                    formatCurrency(d.cashInstalment),
                    formatCurrency(d.cashRefund),
                    formatCurrency(d.totalCash),
                ]
            })
        },
        []
    )

    const pageRow = [
        { text: 'Total', style: 'rowHeading' },
        { text: formatCurrency(pdfData.reportSummary?.startDayCash), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.cashAdded), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.cashRemoved), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.cashSales), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.cashInstalment), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.cashRefund), style: 'rightAlign' },
        { text: formatCurrency(pdfData.reportSummary?.totalCash), style: 'rightAlign' },
    ]

    const tableBody =
        pdfData?.data?.length > 0
            ? parseToPdfData(pdfData.data).map((row) =>
                  row.map((cell, index) => ({
                      text: cell,
                      style: ['cell', index <= 3 ? 'leftAlign' : 'rightAlign'],
                  }))
              )
            : []

    const pdfTemplateDefault = React.useMemo(() => {
        return {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [10, 30, 15, 45],
            footer: function (currentPage, pageCount) {
                const isLastPage = currentPage === pageCount
                // Content for every page
                if (!isLastPage) {
                    return {
                        columns: [
                            {
                                table: {
                                    headerRows: 0,
                                    widths: [404, 44, 50, 50, 50, 50, 45, 50],
                                    body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                                },
                                margin: [15, 25, 15, 10],
                            },
                        ],
                    }
                }
                // Content for the last page
                return {
                    columns: [
                        {
                            table: {
                                headerRows: 0,
                                widths: [404, 44, 50, 50, 50, 50, 45, 50],
                                body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                            },
                            margin: [15, 25, 15, 10],
                        },
                    ],
                }
            },
            content: [
                {
                    image: imageData,
                    width: 70,
                },
                {
                    text: 'Cash Register Report',
                    style: 'header',
                    alignment: 'center',
                },
                {
                    layout: {
                        fillColor: function (rowIndex) {
                            return rowIndex % 2 === 1 ? '#f2f2f2' : null
                        },
                    },
                    style: 'withMargin',
                    alignment: 'center',
                    table: {
                        headerRows: 1,
                        widths: [50, 50, 52, 52, 45, 50, 50, 44, 50, 50, 50, 50, 45, 50],
                        body: [
                            headers.map((header, index) => {
                                return { text: header, style: 'headerRow' }
                            }),
                            ...tableBody,
                        ],
                    },
                },
            ],
            defaultStyle: {
                font: 'NimbusSans',
            },
            styles: {
                withMargin: {
                    margin: [5, 20, 20, 5],
                },
                header: {
                    fontSize: 18,
                    bold: true,
                },
                headerRow: {
                    fillColor: '#ADD8E6',
                    bold: true,
                    fontSize: 10,
                },
                cell: {
                    fontSize: 9,
                    alignment: 'center',
                },
                rightAlign: {
                    alignment: 'right',
                    fontSize: 9,
                },
                leftAlign: {
                    alignment: 'left',
                    fontSize: 9,
                },
                rowHeading: {
                    alignment: 'center',
                    fontSize: 10,
                },
            },
        }
    }, [imageData, headers, pdfData])

    useEffect(() => {
        const template = { ...pdfTemplateDefault }
        setpdfTemplate(template)
    }, [headers, pdfData, pdfTemplateDefault])

    const create = async () => {
        // This will load the data
        await pdfMutation.mutate()
        setPdfDownloading(true)
    }

    useEffect(() => {
        if (!pdfDownloading) return
        setPdfDownloading(false)
        const pdfDocGenerator = pdfMake.createPdf(pdfTemplate)
        let today = new Date()
        pdfDocGenerator.download(`Cash_Register_Report_${today.toLocaleDateString()}_${today.toLocaleTimeString()}`)
    }, [pdfTemplate])

    const columns = [
        { name: 'terminal', title: 'Terminal No', wordWrapEnabled: true },
        { name: 'Cashier', title: 'Cashier', wordWrapEnabled: true },
        { name: 'startTime', title: 'Start Time', wordWrapEnabled: true },
        { name: 'endTime', title: 'End Time', wordWrapEnabled: true },
        {
            name: 'supplierPayment',
            title: 'Supplier Payment',
            getCellValue: (row) => formatCurrency(row.supplierPayment),
        },
        { name: 'cashCouted', title: 'Bal. After Counting', getCellValue: (row) => formatCurrency(row.cashCouted) },
        { name: 'difference', title: 'Difference', getCellValue: (row) => formatCurrency(row.difference) },
        { name: 'startDayCash', title: 'Drawer Start', getCellValue: (row) => formatCurrency(row.startDayCash) },
        { name: 'cashAdded', title: 'Added', getCellValue: (row) => formatCurrency(row.cashAdded) },
        { name: 'cashRemoved', title: 'Removed', getCellValue: (row) => formatCurrency(row.cashRemoved) },
        { name: 'cashSales', title: 'Sales', getCellValue: (row) => formatCurrency(row.cashSales) },
        { name: 'cashInstalment', title: 'Inst.', getCellValue: (row) => formatCurrency(row.cashInstalment) },
        { name: 'cashRefund', title: 'Refund', getCellValue: (row) => formatCurrency(row.cashRefund) },
        { name: 'totalCash', title: 'Total ' },
    ]

    const defaultColumnWidths = [
        { columnName: 'terminal', width: '100px', align: 'center', wordWrapEnabled: true },
        { columnName: 'Cashier', width: '90px', align: 'center', wordWrapEnabled: true },
        { columnName: 'startTime', width: '110px', align: 'center', wordWrapEnabled: true },
        { columnName: 'endTime', width: '110px', align: 'center', wordWrapEnabled: true },
        { columnName: 'supplierPayment', width: '87px', align: 'center', wordWrapEnabled: true },
        { columnName: 'cashCouted', width: '87px', align: 'center', wordWrapEnabled: true },
        { columnName: 'difference', width: '87px', align: 'center' },
        { columnName: 'startDayCash', width: '87px', align: 'center', wordWrapEnabled: true },
        { columnName: 'cashAdded', width: '87px', align: 'center' },
        { columnName: 'cashRemoved', width: '87px', align: 'center' },
        { columnName: 'cashSales', width: '87px', align: 'center' },
        { columnName: 'cashInstalment', width: '87px', align: 'center' },
        { columnName: 'cashRefund', width: '87px', align: 'center' },
        { columnName: 'totalCash', width: '92px', align: 'center' }, // Adjusted this width
    ]
    const activitiesColumns = [
        { name: 'Cashier', title: 'Cashier Name' },
        { name: 'cashAdded', title: 'Cash Added' },
        { name: 'description', title: 'Description' },
        {
            name: 'activityDate',
            title: 'Activity Date',
            getCellValue: (row) => formatDateTime(row.activityDate),
        },
        { name: 'activityType', title: 'Activity Type' },
        { name: 'reasonType', title: 'Reason Type' },
    ]

    const defaultActivitiesColumnWidths = [
        { columnName: 'Cashier', width: 'auto', align: 'left' },
        {
            columnName: 'cashAdded',
            width: 'auto',
            align: 'right',
            getCellValue: (row) => formatCurrency(row.totalAmount),
        },
        { columnName: 'description', width: 'auto', align: 'left' },
        { columnName: 'activityDate', width: '16%', align: 'left' },
        { columnName: 'activityType', width: 'auto', align: 'left' },
        { columnName: 'reasonType', width: 'auto', align: 'left' },
    ]

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const Cell = (props) => {
        let style = {
            paddingTop: 6,
            paddingBottom: 6,
            background: '#DAA06D',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const RowDetail = ({ row }) => (
        <div>
            <Paper>
                <Grid rows={row.activities} columns={activitiesColumns}>
                    <Table columnExtensions={defaultActivitiesColumnWidths} />
                    <TableHeaderRow cellComponent={Cell} />
                </Grid>
            </Paper>
        </div>
    )

    return (
        <Div>
            <h1 className='report-heading'>Cash Register Report</h1>
            <LoadingButton
                sx={{ float: 'right', marginRight: '90px', marginTop: '-65px' }}
                onClick={create}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <DownloadIcon sx={{ fontSize: 'medium', marginRight: '2px' }} /> Download Report
            </LoadingButton>
            <LoadingButton
                sx={{ float: 'right', marginRight: '9px', marginTop: '-65px' }}
                onClick={handleFilterClick}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <FilterAltIcon fontSize='medium' />
            </LoadingButton>
            {isFilterVisible && <CashRegisterReportFilter mutation={viewMutation} />}
            <>
                <div className='report-container'>
                    {loading && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    {!loading && cashRegisterReport.totalCount === 0 ? (
                        <Typography variant={'primary'}>No data. Select suitable filter to view data</Typography>
                    ) : (
                        <>
                            <Paper>
                                <Grid rows={cashRegisterReport.data} columns={columns}>
                                    <DateTypeProvider for={dateTimeColumns} />
                                    <RowDetailState />
                                    <Table columnExtensions={defaultColumnWidths} />
                                    <TableHeaderRow />
                                    <TableRowDetail contentComponent={RowDetail} />
                                </Grid>
                            </Paper>
                            <div className='custom-table'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan={12} />
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {currentPage === totalPages && cashRegisterReport.reportSummary && (
                                            <tr>
                                                <td colSpan={5} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                                    Total
                                                </td>
                                                <td style={{ textAlign: 'right', width: '6.5%' }}>
                                                    {formatCurrency(cashRegisterReport.reportSummary.startDayCash)}
                                                </td>
                                                <td style={{ textAlign: 'right', width: '6.5%' }}>
                                                    {formatCurrency(cashRegisterReport.reportSummary.cashAdded)}
                                                </td>
                                                <td style={{ textAlign: 'right', width: '6.6%' }}>
                                                    {formatCurrency(cashRegisterReport.reportSummary.cashRemoved)}
                                                </td>
                                                <td style={{ textAlign: 'right', width: '6.5%' }}>
                                                    {formatCurrency(cashRegisterReport.reportSummary.cashSales)}
                                                </td>
                                                <td style={{ textAlign: 'right', width: '6.5%' }}>
                                                    {formatCurrency(cashRegisterReport.reportSummary.cashInstalment)}
                                                </td>
                                                <td style={{ textAlign: 'right', width: '6.5%' }}>
                                                    {formatCurrency(cashRegisterReport.reportSummary.cashRefund)}
                                                </td>
                                                <td style={{ textAlign: 'right', width: '7.0%' }}>
                                                    {formatCurrency(cashRegisterReport.reportSummary.totalCash)}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                    <Stack>
                        <Pagination
                            count={Math.ceil(cashRegisterReport.totalCount / itemsPerPage)}
                            onChange={(event, page) => {
                                handlePageChange(page)
                            }}
                            page={currentPage}
                            siblingCount={1}
                            boundaryCount={1}
                            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px' }}
                            variant='outlined'
                        />
                    </Stack>
                </div>
            </>
        </Div>
    )
}

export default CashRegisterReportLayout
