import React from 'react'
import Div from '@jumbo/shared/Div'
import AdminDashboard from './components/AdminDashboard'
import UserDashboard from './components/UserDashboard'
import { CanLogin, CanViewAdminDashboard } from '../../constants/Permissions'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import util from '../../utils/permissions/util-permissions'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
    const { authUser } = useJumboAuth()
    const canViewAdminPermission = util.hasPermission(authUser, CanViewAdminDashboard)
    const canLogin = util.hasPermission(authUser, CanLogin)
    const navigate = useNavigate()

    if (!authUser || !canLogin) navigate('/profile/signout')

    return (
        <Div>
            {canViewAdminPermission && <AdminDashboard />}
            {!canViewAdminPermission && <UserDashboard />}
        </Div>
    )
    //CanViewUserDashboard
}

export default Dashboard
