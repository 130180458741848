import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import UpdateSupplierForm from './UpdateSupplierForm'
import { getSupplierById, selectItemById, updateSupplier } from 'app/redux/store/features/suppliers.js'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import supplierService from 'app/services/shipments/supplier/supplierServices'
import utilServices from 'app/services/util-services'
import { useSnackbar } from 'notistack'

const UpdateSupplier = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { supplierId } = state
    const { suppliers, loading, error } = useSelector((state) => state.suppliers)
    const supplier = selectItemById(suppliers, supplierId)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(supplier.supplierId)) dispatch(getSupplierById({ supplierId: supplierId }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return

        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Supplier updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/store/shipments/suppliers/')
        }
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const supplierComapanyLookupList = useCallback(async (searchTerm) => {
        try {
            return await supplierService.getSupplierCompanyLookupList({ supplierCompanyName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierCompanyLookupById = useCallback(async (key) => {
        try {
            return await supplierService.getSupplierCompanyLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const supplierCompanyLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'supplierCompanyId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await supplierComapanyLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return await utilServices.emptyList()

                        return supplierCompanyLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const companyEditorOptions = {
        dataSource: supplierCompanyLookupStore,
        displayExpr: 'supplierCompanyName',
        valueExpr: 'supplierCompanyId',
        searchEnabled: true,
        showClearButton: true,
    }

    const onUpdateSupplier = async () => {
        setApiCallInProgress(true)
        dispatch(updateSupplier({ ...supplier }))
    }

    return (
        <React.Fragment>
            <UpdateSupplierForm
                supplierData={supplier}
                updateSupplierGrid={onUpdateSupplier}
                companyEditorOptions={companyEditorOptions}
            />
        </React.Fragment>
    )
}

export default UpdateSupplier
