import React from 'react'
import { Grid } from '@mui/material'
import LeftInfoCard from './components/LeftInfoCard'
import RightInfoCard from './components/RightInfoCard'

const InfoBar = ({ transaction }) => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} sm={12} lg={6}>
                <LeftInfoCard transaction={transaction} />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <RightInfoCard transaction={transaction} />
            </Grid>
        </Grid>
    )
}

export default InfoBar
