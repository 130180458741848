import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Div from '@jumbo/shared/Div'
import DailySalesReportLayout from './components/DailySalesReportLayout'
import reportDataServices from 'app/services/pages/reports/report-data-services'
import { getDailySaleReport } from 'app/redux/store/features/reports'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'

const DailySaleReport = () => {
    const dispatch = useDispatch()
    const itemsPerPage = 25
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [imageData, setImageData] = useState('')
    const [pdfData, setPdfData] = useState({})
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const [reportFilter, setReportFilter] = useState({
        startDate: DateUtils.getUtcStartDate(10),
        endDate: DateUtils.getUtcEndDate(),
        CheckoutById: null,
        ServedById: null,
        SaleTypeId: null,
        customerName: null,
        customerArea: null,
        TerminalId: null,
    })

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const applyViewFilter = useMutation({
        mutationFn: async (viewFilter) => {
            const filter = { ...viewFilter, skip: 0, take: itemsPerPage }
            setReportFilter(filter)
            dispatch(getDailySaleReport(filter))
        },
    })

    const applyPageChange = useMutation({
        mutationFn: async (page) => {
            const filter = { ...reportFilter, skip: (page - 1) * itemsPerPage, take: itemsPerPage }
            setReportFilter(filter)
            dispatch(getDailySaleReport(filter))
        },
    })

    const applyPdfFilter = useMutation({
        mutationFn: async () => {
            const filter = { ...reportFilter, skip: 0, take: 2000 }
            const data = await fetchData(filter)
            setPdfData(data)
        },
    })

    const fetchData = useCallback(async (filter) => {
        return await reportDataServices.getDailySalesReportData(filter, handleError)
    }, [])

    useEffect(async () => {
        // We load the logo only first time or on full refresh
        setApiCallInProgress(true)
        const image = await reportDataServices.getImage()
        const imageData = image.data ? image.data : ''
        setImageData(imageData)
        dispatch(getDailySaleReport({ ...reportFilter, skip: 0, take: itemsPerPage }))
    }, [dispatch])

    return (
        <Div>
            <DailySalesReportLayout
                imageData={imageData}
                viewMutation={applyViewFilter}
                pdfMutation={applyPdfFilter}
                pageMutation={applyPageChange}
                apiCallInProgress={apiCallInProgress}
                setApiCallInProgress={setApiCallInProgress}
                pdfData={pdfData}
            />
        </Div>
    )
}

export default DailySaleReport
