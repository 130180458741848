import React from 'react'
import Divider from '@mui/material/Divider'
import { Grid } from '@mui/material'
import { Card, CardContent, ListItem, ListItemText, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import Span from '@jumbo/shared/Span'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import StoreIcon from '@mui/icons-material/Store'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import StorefrontIcon from '@mui/icons-material/Storefront'

const LeftInfoCard = ({ order }) => {
    return (
        <Grid>
            <Card sx={{ height: 250 }}>
                <CardContent sx={{ justifyContent: 'center' }}>
                    <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={12} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <List sx={{ width: '100%', color: 'text.primary' }}>
                                <ListItem>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <StoreIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Purchase Order No:
                                            <Span sx={{ color: 'secondary.dark' }}> {order.purchaseOrderNo} </Span>{' '}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem sx={{ mt: -1 }}>
                                    <ListItemText disablePadding>
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <CalendarViewMonthIcon sx={{ fontSize: 20, mb: -0.5, mr: 1.5 }} />
                                            Delivery Date:
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                <Span>{`${formatDateTime(order?.expectedDate ?? '')}`}</Span>
                                            </Span>
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item sx={{ width: '100%', display: 'flex', alignItems: 'center', ml: 3.9 }}>
                            <List sx={{ width: '100%', mt: -3.6 }}>
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <StorefrontIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} /> {order.supplierName}
                                    </Typography>
                                </ListItemText>

                                <Divider sx={{ mb: 2 }} />
                            </List>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default LeftInfoCard
