import React from 'react'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'

const AutoNumberForm = (props) => {
    const { handleSubmit } = useForm()
    const { showDialog, hideDialog } = useJumboDialog()

    const dialogBoxButtons = () => {
        return (
            <Stack direction={'row'} spacing={2} sx={{ m: 'auto', ml: 10.5, mb: 2 }}>
                <LoadingButton
                    variant='contained'
                    color='secondary'
                    onClick={() => props.reloadAutoNumber() && hideDialog()}
                    size='large'
                    sx={{ width: 100 }}>
                    {' '}
                    YES
                </LoadingButton>
                <LoadingButton
                    variant='outlined'
                    color='secondary'
                    onClick={hideDialog}
                    size='large'
                    sx={{ width: 100 }}>
                    {' '}
                    NO
                </LoadingButton>
            </Stack>
        )
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'danger',
        width: 120,
        icon: 'check',
        useSubmitBehavior: true,
    }
    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () =>
            showDialog({
                title: 'You have unsaved changes, do you want to cancel and reload?',
                content: dialogBoxButtons(),
            }),
    }

    const validationRules = {
        format: [{ type: 'required', message: 'Format is required.' }],
        increment: [{ type: 'required', message: 'Increment is required.' }],
        start: [{ type: 'required', message: 'Start is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        {props.heading}
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'secondary.main',
                }}>
                <form onSubmit={handleSubmit(props.onUpdate)}>
                    <Form
                        mode='form'
                        formData={props.autoNumberData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}
                        deferRendering={false}>
                        <GroupItem caption='Details' cssClass='form-group-item'>
                            <GroupItem cssClass='form-group-item-margin'>
                                <ColCountByScreen lg={5} md={1} sm={1} xs={1} />
                                <GroupItem />
                                <GroupItem colSpan={3}>
                                    <SimpleItem dataField='format' validationRules={validationRules.format}>
                                        <Label text='Number Format' />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='increment'
                                        editorType='dxNumberBox'
                                        editorOptions={{ showSpinButtons: 'true' }}
                                        validationRules={validationRules.increment}>
                                        <Label text='Number Increment' />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='start'
                                        editorType='dxNumberBox'
                                        editorOptions={{ showSpinButtons: 'true' }}
                                        validationRules={validationRules.start}>
                                        <Label text='Number Start' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem buttonOptions={submitButtonOptions} horizontalAlignment='right' />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AutoNumberForm
