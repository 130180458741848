import React from 'react'
import { Grid } from '@mui/material'
import { Card, CardContent } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Div from '@jumbo/shared/Div'
import TransferTimeLineCard from './transferTimeLineCard/components/TransferTimeLineCard'
import { TransferTimeLineData } from './transferTimeLineCard/components/data'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import ChangeTransferStatusForm from './transferTimeLineCard/components/ChangeTransferStatusForm'

const TransferRightInfoCard = ({ transfer }) => {
    const { showDialog, hideDialog } = useJumboDialog()
    const firstGridData = TransferTimeLineData.filter((item) => item.id >= 15 && item.id <= 30)
    const secondGridData = TransferTimeLineData.filter((item) => item.id >= 35 && item.id <= 60)

    const onChangeStatus = ({ transfer }) => {
        showDialog({
            title: (
                <Div
                    sx={{
                        textAlign: 'center',
                        height: 48,
                        pt: 1.5,
                        bgcolor: 'success.main',
                        borderRadius: 1,
                        color: 'common.white',
                    }}>
                    Change Status
                </Div>
            ),
            content: <ChangeTransferStatusForm transfer={transfer} />,
        })
    }

    return (
        <Grid>
            <Card sx={{ height: 360 }}>
                <CardContent>
                    <Stack direction={'row'}>
                        <Grid item sx={{ width: '45%', marginRight: '5%' }}>
                            <TransferTimeLineCard statusList={firstGridData} transfer={transfer} />
                        </Grid>

                        <Grid item sx={{ width: '45%', display: 'flex', alignItems: 'right', ml: 1.9 }}>
                            {' '}
                            <TransferTimeLineCard statusList={secondGridData} transfer={transfer} />
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
            <Grid item sx={{ marginTop: '-7.5%', marginLeft: '12%' }}>
                <Button variant='outlined' onClick={() => onChangeStatus({ transfer })}>
                    Change Status
                </Button>
            </Grid>
        </Grid>
    )
}

export default TransferRightInfoCard
