import React, { useMemo } from 'react'
import { Button, Typography } from '@mui/material'
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { formatDateTime } from '../../../../../../../../../../utils/components/FormatDateTime/FormatDateTime'

const TransferTimeLineItems = ({ status, lastItemEdge, transfer }) => {
    let statusHistory = useMemo(() => {
        const entry = transfer?.transferStatusHistory?.find((history) => history.transferStatus === status.id)
        if (entry) return { color: 'green', statusChangedDate: entry.statusChangedDate }
        return { color: null, statusChangedDate: null }
    }, [status, transfer])

    return (
        <TimelineItem
            sx={{
                '&::before': {
                    display: 'none',
                },
            }}>
            <TimelineSeparator>
                <TimelineDot
                    sx={{
                        p: '6px',
                        m: 0,
                        bgcolor: statusHistory.color ?? '',
                        color: 'white',
                    }}>
                    {status.icon}
                </TimelineDot>
                {!lastItemEdge && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent
                sx={{
                    p: (theme) => theme.spacing(0.5, 0),
                    ml: 2.5,
                }}>
                <Typography variant='h5' mb={0.5}>
                    {status.title}
                </Typography>
                <Typography variant='h6' color={'text.secondary'}>
                    {formatDateTime(statusHistory.statusChangedDate)}
                </Typography>
            </TimelineContent>
        </TimelineItem>
    )
}

export default TransferTimeLineItems
