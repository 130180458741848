import React from 'react'
import Div from '@jumbo/shared/Div'
import useDefinitionApp from '../../hooks/useDefinitionApp'
const ActiveDefinitionHeader = () => {
    const { activeDefinition } = useDefinitionApp()

    return <Div></Div>
}

export default ActiveDefinitionHeader
