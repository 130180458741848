import React from 'react'
import { Timeline } from '@mui/lab'
import TimeLineItems from '../../TimeLineItems'

const RequestTimeLineList = ({ statusList, request }) => {
    return (
        <Timeline sx={{ m: 0, p: (theme) => theme.spacing(0, 3) }}>
            {statusList?.map((item, index) => (
                <TimeLineItems
                    status={item}
                    key={index}
                    lastItemEdge={index === statusList.length - 1}
                    request={request}
                />
            ))}
        </Timeline>
    )
}

export default RequestTimeLineList
