import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Form, { SimpleItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { getReportConfig } from 'app/redux/store/features/configs'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'

const ReportConfig = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { report, loading, error } = useSelector((state) => state.config)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [reportConfig, setReportConfig] = useState({ ...report })

    useEffect(() => {
        if (!apiCallInProgress || error) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getReportConfig())
    }, [])

    useEffect(() => {
        setReportConfig({ ...report })
    }, [report])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const dateTimeEditorOption = {
        displayFormat: 'shortDateShortTime',
        type: 'datetime',
        pickerType: 'calendar',
        useMaskBehavior: true,
        readOnly: true,
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Report Config
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'secondary.main',
                }}>
                <Form mode='form' formData={reportConfig} showColonAfterLabel={false} showValidationSummary={false}>
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                        <GroupItem>
                            <GroupItem
                                caption='Definitions'
                                cssClass='form-group-item'
                                editorOptions={dateTimeEditorOption}>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem colSpan={3}>
                                        <SimpleItem
                                            dataField='chartOfAccountLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}
                                        />
                                        <SimpleItem
                                            dataField='perActvityTypeLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Person Actvity Type Last Updated' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='personCategoryLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}
                                        />
                                        <SimpleItem
                                            dataField='terminalLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}
                                        />
                                        <SimpleItem
                                            dataField='saleTransTypeLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Sale Transaction Type Last Updated' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='userLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}
                                        />
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Orders' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem>
                                        <SimpleItem
                                            dataField='orderTxnLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}
                                        />
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Transactions' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem colSpan={3}>
                                        <SimpleItem
                                            dataField='txnLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Transaction Last Updated' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <GroupItem>
                            <GroupItem caption='Sale Item' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem>
                                        <SimpleItem
                                            dataField='saleItemLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}
                                        />
                                        <SimpleItem
                                            dataField='saleItemCatLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Sale Item Category Last Updated' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='saleItemSubCatLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Sale Item Sub-Category Last Updated' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Inventory' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem colSpan={3}>
                                        <SimpleItem
                                            dataField='invCatLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Inventory Category Last Updated' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='invSubCatLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Inventory Sub-Category Last Updated' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='invTypeLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Inventory Type Last Updated' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='inventoryLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}>
                                            <Label text='Inventory Last Updated' />
                                        </SimpleItem>
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Supplier' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem colSpan={3}>
                                        <SimpleItem
                                            dataField='suppliersLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}
                                        />
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem caption='Customer' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <GroupItem colSpan={3}>
                                        <SimpleItem
                                            dataField='custLastUpdated'
                                            editorType='dxDateBox'
                                            editorOptions={dateTimeEditorOption}
                                        />
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </GroupItem>
                </Form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default ReportConfig
