import React from 'react'
import { useNavigate } from 'react-router-dom'
import useApp from '../../hooks/useApp'
import { useAuthSignOut } from '@react-query-firebase/auth'
import { auth } from '../../services/auth/firebase/firebase'
import { signOut } from '@firebase/auth'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { clearProfile, getUserProfile } from '../../redux/store/features/profile'
import { useDispatch } from 'react-redux'

const SignOut = () => {
    const navigate = useNavigate()
    const { setAuthToken } = useJumboAuth()
    const dispatch = useDispatch()

    React.useEffect(async () => {
        await signOut(auth)
        localStorage.clear()
        setAuthToken(null)
        dispatch(clearProfile())
        navigate('/signin')
    }, [])

    return null
}
export default SignOut
