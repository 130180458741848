import React from 'react'
import useReportApp from '../../hooks/useReportApp'
import ActiveReport from '../ActiveReport'

const ReportAppContent = () => {
    const { activeReportRef: activeReportRef } = useReportApp()
    return <ActiveReport ref={activeReportRef} />
}

export default ReportAppContent
