import React from 'react'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import { useMediaQuery } from '@mui/material'
import useAutoNumberApp from '../hooks/useAutoNumberApp'
import AutoNumberAppSidebar from '../components/autoNumberAppSidebar/AutoNumberAppSidebar'
import AutoNumberAppContent from '../components/autoNumberAppContent/AutoNumberAppContent'

const AutoNumberAppLayout = () => {
    const { theme } = useJumboTheme()
    const { activeAutoNumber } = useAutoNumberApp()
    const md = useMediaQuery(theme.breakpoints.down('md'))

    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx:
                    md && activeAutoNumber
                        ? { display: 'none' }
                        : {
                              width: 280,
                              marginRight: 1,
                          },
            },
        }),
        [theme, md, activeAutoNumber]
    )

    return (
        <JumboContentLayout sidebar={<AutoNumberAppSidebar />} layoutOptions={layoutOptions}>
            <AutoNumberAppContent />
        </JumboContentLayout>
    )
}

export default AutoNumberAppLayout
