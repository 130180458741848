import React from 'react'
import Div from '@jumbo/shared/Div'
import useDefinitionApp from '../../hooks/useDefinitionApp'

const ActiveDefinitionFooter = () => {
    const { activeDefinition } = useDefinitionApp()

    return <Div></Div>
}

export default ActiveDefinitionFooter
