import jwtAuthAxios from './auth/jwtAuth'

const authServices = {}

authServices.getCurrentUser = async () => {
    const { data } = await jwtAuthAxios.get('/Account/user').catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.log(error.response.data)
            console.log(error.response.status)
            throw error.response.data
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request)
            throw Error('request timed out')
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

authServices.signIn = async (loginCreds) => {
    const { data } = await jwtAuthAxios.post('/auth', loginCreds)
    return data
}

export default authServices
