import React from 'react'
import { Grid } from '@mui/material'
import RequestLeftInfoCard from './components/RequestLeftInfoCard'
import RequestRightInfoCard from './components/RequestRightInfoCard'

const RequestInfoBar = ({ request }) => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} sm={12} lg={6}>
                <RequestLeftInfoCard request={request} />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <RequestRightInfoCard request={request} />
            </Grid>
        </Grid>
    )
}

export default RequestInfoBar
