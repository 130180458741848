import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import UpdateBranchForm from './UpdateBranchForm'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { getBranchById, updateBranch, selectItemById } from 'app/redux/store/features/branches'
import LinkedUserGrid from './LinkedUserGrid'
import { TabContext, TabPanel } from '@mui/lab'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'

const UpdateBranch = () => {
    const [tabTitle, setTabTitle] = React.useState('details') // Initialize with a default value
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { companyId, branchId } = state
    const { branches, loading, error } = useSelector((state) => state.branches)
    const branch = selectItemById(branches, branchId)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (utilServices.isNullOrUndefined(branch.branchId)) {
            dispatch(getBranchById({ companyId: companyId, branchId: branchId, reportXClientSecret: null }))
        }
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
        else {
            enqueueSnackbar('Branch updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/branches')
        }
    }, [loading])

    const onTabSelectionChanged = (e, newValue) => {
        setTabTitle(newValue)
    }

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateBranch = async () => {
        setApiCallInProgress(true)
        dispatch(updateBranch({ ...branch }))
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Branch
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={tabTitle}>
                    <TabList sx={{ bgcolor: 'background.default', m: 2, mb: 0 }} onChange={onTabSelectionChanged}>
                        <Tab label={'Details'} value={'details'} sx={{ width: '160px' }} />
                        <Tab label={'Linked Users'} value={'linked-users'} sx={{ width: '180px' }} />
                    </TabList>
                    <TabPanel value='details'>
                        <UpdateBranchForm branchData={branch} updateBranch={onUpdateBranch} />
                    </TabPanel>
                    <TabPanel value='linked-users'>
                        <LinkedUserGrid branchId={branch.branchId} />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateBranch
