import { getLocales, getCurrencies, getTimeZone } from 'react-native-localize'

const locale = getLocales()[0]
const currency = getCurrencies()[0]
const timezone = getTimeZone()

const formatCurrency = (amount) => {
    const formattedAmount = new Intl.NumberFormat(locale.languageTag, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
    }).format(amount)
    if (timezone === 'Asia/Karachi') return formattedAmount.replace(/^\D+/, '')
    return formattedAmount
}
export default formatCurrency

export const CurrencyFormatterSummary = ({ value }) => {
    const formattedAmount = new Intl.NumberFormat(locale.languageTag, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
    }).format(value)
    if (timezone === 'Asia/Karachi') return formattedAmount.replace(/^\D+/, '')
    return formattedAmount
}
