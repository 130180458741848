export const defaultInventory = {
    inventoryNo: '',
    inventoryName: '',
    barcode: '',
    categoryId: null,
    totalStock: 0,
    salePrice: 0,
    purchasePrice: 0,
    sku: null,
    needCustomer: false,
    cartName: '',
    printName: '',
    description: null,
    displayOrder: 0,
    isActive: false,
    loyaltyPoints: null,
    fkInvCatId: null,
    fkInvTypeId: null,
    fkInvSubCatId: null,
    fkSaleChartAccountId: null,
    fkChartTaxSaleId: null,
    fkChartTaxPurchaseId: null,
    fkPurchaseChartAccountId: null,
    fkStockChartAccountId: null,
    fkDamagedChartAccountId: null,
    fkStockUomId: null,
    fkSaleUomId: null,
    fkOrderUomId: null,
    isVirtualInventory: false,
    isRawMaterialItem: false,
    boxQuantity: 1,
    minQty: null,
    minOrderQty: null,
    qtyOnOrder: null,
    supplierBarcode: null,
    supplierSku: null,
    modelNumber: null,
    retailPrice: 0,
    wholesalerice: null,
    supplierIds: [],
    salePriceDiscountRate: null,
    salePriceDiscountMode: 0,
    wholesalericeDiscountRate: null,
    wholesalericeDiscountMode: 0,
    purchasePriceDiscountRate: null,
    purchasePriceDiscountMode: 0,
}
