import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { getBranchUserLookupList } from 'app/redux/store/features/users'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import utilServices from 'app/services/util-services'
import CustomStore from 'devextreme/data/custom_store'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import useDebouncedInput from '../../../../../hooks/useDebouncedInput'

const InventoryLoadFormReportFilter = ({ mutation }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        dispatch(getBranchUserLookupList(''))
    }, [])

    const { branchUsers } = useSelector((state) => state.users)
    let initialValues = {
        startDate: DateUtils.getUtcStartDate(10).toISOString().slice(0, 16),
        endDate: DateUtils.getUtcEndDate().toISOString().slice(0, 16),
        productName: '',
        area: '',
        city: '',
        supplierName: '',
    }
    const [filter, setFilter] = useState({ categoryId: null, inventoryTypeId: null, ServedById: null })

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const branchUserLookupStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'userId',
                    data: branchUsers.data,
                    totalCount: branchUsers.length,
                }),
                reshapeOnPush: true,
            }),
        [branchUsers]
    )

    const inventoryCategoriesLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await inventoryService.getInventoryCategoriesLookupList({ categoryName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryCategoriesLookupById = useCallback(
        async (key) => {
            try {
                return await inventoryService.getInventoryCategoriesLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryCategoryStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'inventoryCategoryId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await inventoryCategoriesLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return inventoryCategoriesLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const inventoryTypeLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await inventoryService.getInventoryTypeLookupList({ typeName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryTypesLookupById = useCallback(
        async (key) => {
            try {
                return await inventoryService.getInventoryTypesLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryTypesStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'invTypeId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await inventoryTypeLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return inventoryTypesLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = async (startDate, endDate, productName, area, city, supplierName) => {
        await mutation.mutate({
            startDate: startDate.toFilterStartDateTime(),
            endDate: endDate.toFilterEndDateTime(),
            productName: productName,
            area: area,
            city: city,
            supplierName: supplierName,
            ...filter,
            sort: '',
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(
            data.startDate,
            data.endDate,
            data.productName,
            data.area,
            data.city,
            data.supplierName,
            setSubmitting(false)
        )
    }

    const handleClear = () => {
        setFilter({ categoryId: null, inventoryTypeId: null, ServedById: null })
    }

    const onCategorySelectionChanged = (value) => {
        setFilter({ ...filter, categoryId: value?.inventoryCategoryId })
    }

    const onInventoryTypeChange = (value) => {
        setFilter({ ...filter, inventoryTypeId: value?.invTypeId })
    }

    const {
        value: serveByValue,
        debouncedValue: debouncedServeByValue,
        onChange: onServeBySearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getBranchUserLookupList(serveByValue))
    }, [debouncedServeByValue])

    const onServeByChanged = (value) => {
        setFilter({ ...filter, ServedById: value?.userId })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ display: '', borderRadius: 0 }}>
                        <Divider />
                        <Div style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='startDate'
                                        type='datetime-local'
                                        defaultValue={initialValues.startDate}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='endDate'
                                        type='datetime-local'
                                        defaultValue={initialValues.endDate}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Product Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='productName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Area</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='area' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>City</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='city' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Category</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={inventoryCategoryStore}
                                        displayExpr={'categoryName'}
                                        value={filter.categoryId}
                                        searchEnabled={true}
                                        onValueChange={onCategorySelectionChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Served by</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={branchUserLookupStore}
                                        displayExpr={(data) => (data ? data.firstName.toFullName(data.lastName) : '')}
                                        value={filter.ServedById}
                                        searchEnabled={true}
                                        onValueChange={onServeByChanged}
                                        onInput={onServeBySearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Type</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={inventoryTypesStore}
                                        displayExpr={'typeName'}
                                        value={filter.inventoryTypeId}
                                        searchEnabled={true}
                                        onValueChange={onInventoryTypeChange}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Supplier Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='supplierName' />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default InventoryLoadFormReportFilter
