import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AddBranchForm from './AddBranchForm'
import { defaultBranch } from './defaultBranch'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { addBranch } from 'app/redux/store/features/branches'

const AddBranch = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation()
    const { id } = state
    const [branchData] = useState({ ...defaultBranch, fkCompanyId: id })
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { loading, error } = useSelector((state) => state.branches)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
        else {
            enqueueSnackbar('Branch added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/settings/branches')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onAddBranch = async () => {
        setApiCallInProgress(true)
        dispatch(addBranch({ ...branchData }))
    }

    return (
        <React.Fragment>
            <AddBranchForm branchData={branchData} addBranches={onAddBranch} />
        </React.Fragment>
    )
}

export default AddBranch