import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { addTransfers } from '../../../../../../redux/store/features/transfers'
import useApp from 'app/hooks/useApp'
import TextBox from 'devextreme-react/text-box'

const AddTransferForm = (props) => {
    const navigate = useNavigate()
    const { loading, error } = useSelector((state) => state.transfers)
    const { branchSelection } = useApp()

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/company/transfers/inventories'),
    }

    const validationRules = {
        transferRequiredDate: [{ type: 'required', message: 'Transfer Date is required.' }],
        fkSecondBranchIdValidationRules: [{ type: 'required', message: 'Source / Destination Branch is required.' }],
        branchSelectionValidationRules: [{ type: 'required', message: 'Branch Selection is required.' }],
    }

    const shippingCostEditorOptions = {
        format: (data) => formatCurrency(data), // Pass the formatCurrency function directly
    }

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Add Transfer
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.onAddTransfer)}>
                    <Form mode='form' formData={props.transferData} showColonAfterLabel={false}>
                        <GroupItem>
                            <GroupItem>
                                <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                <GroupItem>
                                    <GroupItem caption='Transfer Details' cssClass='form-group-item'>
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <GroupItem>
                                                <SimpleItem>
                                                    <Label text='Sender Branch' />
                                                    <TextBox
                                                        readOnly={true}
                                                        value={branchSelection.branchName}></TextBox>
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='fkSecondBranchId'
                                                    editorType='dxSelectBox'
                                                    validationRules={validationRules.fkSecondBranchIdValidationRules}
                                                    editorOptions={props.branchEditorOptions}>
                                                    <Label text='Receiving  Branch' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='transferNo'
                                                    editorOptions={{
                                                        placeholder: 'Auto generated on save',
                                                    }}>
                                                    <Label text='Transfer Number' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='fkTransferRequestId'
                                                    editorType='dxSelectBox'
                                                    editorOptions={props.transferRequestEditorOptions}>
                                                    <Label text='Transfer Request' />
                                                </SimpleItem>

                                                <SimpleItem dataField='barcode' />
                                                <SimpleItem
                                                    dataField='transferRequiredDate'
                                                    editorType='dxDateBox'
                                                    editorOptions={{
                                                        displayFormat: 'shortDateShortTime',
                                                        type: 'datetime',
                                                        pickerType: 'calendar',
                                                        useMaskBehavior: true,
                                                    }}
                                                    validationRules={validationRules.transferRequiredDate}>
                                                    <Label text='Transfer Date' />
                                                </SimpleItem>
                                                <SimpleItem dataField='refNumber'>
                                                    <Label text='Reference Number' />
                                                </SimpleItem>
                                                <SimpleItem dataField='comments' editorType='dxTextArea' />
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                </GroupItem>
                                <GroupItem>
                                    <GroupItem caption='Shipping Details' cssClass='form-group-item '>
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <SimpleItem dataField='hasShippingCost' editorType='dxCheckBox'>
                                                <Label text='Shipping is required' />
                                            </SimpleItem>
                                            <SimpleItem
                                                editorType='dxNumberBox'
                                                dataField='shippingCost'
                                                editorOptions={shippingCostEditorOptions}>
                                                <Label text='Shipping Cost' />
                                            </SimpleItem>
                                            <SimpleItem dataField='shippingComments' editorType='dxTextArea'>
                                                <Label text='Shipping Comments' />
                                            </SimpleItem>
                                        </GroupItem>
                                    </GroupItem>
                                    <GroupItem caption='Configuration' cssClass='form-group-item '>
                                        <GroupItem cssClass='form-group-item-margin'>
                                            <GroupItem>
                                                <ColCountByScreen lg={2} md={1} sm={1} xs={1} />
                                                <SimpleItem dataField='transferAutoDispatch' editorType='dxCheckBox'>
                                                    <Label text='Transfer Auto Dispatch' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='overwritePurchasePriceOnReceivingSide'
                                                    editorType='dxCheckBox'>
                                                    <Label text='Update Purchase Price When Receiving' />
                                                </SimpleItem>
                                                <SimpleItem dataField='transferAutoReceive' editorType='dxCheckBox'>
                                                    <Label text='Transfer Auto Receive' />
                                                </SimpleItem>
                                                <SimpleItem
                                                    dataField='overwriteSalePriceOnReceivingSide'
                                                    editorType='dxCheckBox'>
                                                    <Label text='Update Sale Price When Receiving' />
                                                </SimpleItem>
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddTransferForm
