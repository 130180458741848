let DiscountModeServices = {}

DiscountModeServices.DiscountModeList = [
    { id: 0, discountMode: 'Fixed' },
    { id: 1, discountMode: 'Percentage ' },
]

DiscountModeServices.getDiscountMode = () => {
    return DiscountModeServices.DiscountModeList
}

DiscountModeServices.getDiscountModeData = (row) => {
    const discountMode = DiscountModeServices.DiscountModeList.find((item) => item.id === row.discountMode)
    return discountMode ? discountMode.discountMode : ''
}

export default DiscountModeServices
