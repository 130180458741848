import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import AddIcon from '@mui/icons-material/Add'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import BranchGridData from './components/BranchGridData'
import BranchGridFilter from './components/BranchGridFilter'
import { getBranchList } from 'app/redux/store/features/branches'
import { LoadPanel } from 'devextreme-react/load-panel'
import Div from '@jumbo/shared/Div'

const BranchGrid = () => {
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { companyConfig } = useSelector((state) => state.companies)
    const navigate = useNavigate()
    const [filter, setFilter] = useState()
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 25
    const position = { of: '#dataGrid' }
    const dispatch = useDispatch()
    const { refreshing } = useSelector((state) => state.branches)

    useEffect(() => {
        setApiCallInProgress(true)
        if (companyConfig.companyId !== null)
            dispatch(getBranchList({ ...filter, fkCompanyId: companyConfig.companyId, skip: 0, take: pageSize }))
    }, [companyConfig.companyId, filter])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function userReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }
            default:
                return state
        }
    }

    const [branchGrid, setBranchGrid] = React.useReducer(
        userReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            currentfilter = { ...filter, ...currentfilter }
            dispatch(
                getBranchList({ ...currentfilter, fkCompanyId: companyConfig.companyId, skip: 0, take: pageSize }, true)
            )
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = {
            ...filter,
            fkCompanyId: companyConfig.companyId,
            skip: pageSize * pageNumber,
            take: pageSize,
        }
        dispatch(getBranchList(currentFilter))
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            fkCompanyId: companyConfig.companyId,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getBranchList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getBranchList({ ...filter, fkCompanyId: companyConfig.companyId, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setBranchGrid({ type: 'set-grid-filter', payload: value })
        },
        [setBranchGrid]
    )

    const onAddBranch = () => {
        navigate(`/settings/companies/branches/add/${companyConfig.companyId}`, {
            state: { id: companyConfig.companyId },
        })
    }

    const setFilterIconColor = React.useCallback(
        (value) => {
            setBranchGrid({ type: 'set-filter-icon-color', payload: value })
        },
        [setBranchGrid]
    )

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        onClick={onRefresh}
                        fontSize={'large'}
                        sx={{
                            mb: -1.2,
                            mr: 0.5,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    Branch List
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'primary.main',
                pl: 1,
            }}
            action={
                <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                    <AddIcon
                        size={'large'}
                        fontSize={'large'}
                        variant={'contained'}
                        color={'#e0e0e0'}
                        sx={{
                            pt: 0,
                            pb: 0,
                            color: '#e0e0e0',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={onAddBranch}
                    />
                    <FilterAltIcon
                        fontSize='large'
                        sx={{
                            mt: -1.8,
                            color:
                                branchGrid.filterIconColor && !branchGrid.gridFilterVisible
                                    ? 'success.main'
                                    : 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => setGridFilter(!branchGrid.gridFilterVisible)}
                    />
                </Stack>
            }>
            <Grid>
                <BranchGridFilter
                    mutation={applyFilter}
                    display={branchGrid.gridFilterVisible ? '' : 'none'}
                    filterIconColor={(data) => setFilterIconColor(data)}
                />
            </Grid>
            <Div id='dataGrid'>
                <Grid>
                    <BranchGridData
                        onRefresh={onRefresh}
                        companyId={companyConfig.companyId}
                        onPageChange={onPageChange}
                        onColumnSort={onColumnSort}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        apiCallInProgress={apiCallInProgress}
                        setApiCallInProgress={setApiCallInProgress}
                    />
                </Grid>
                <LoadPanel shadingColor='rgba(0,0,0,0.4)' position={position} visible={refreshing} />
            </Div>
        </JumboCardQuick>
    )
}
export default BranchGrid
