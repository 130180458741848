import React from 'react'
import { CircularProgress, Box } from '@mui/material'

const LoaderOverlay = ({ loading }) => {
    if (!loading) return null

    return (
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}>
            <CircularProgress />
        </Box>
    )
}

export default LoaderOverlay
