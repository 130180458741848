import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import 'devextreme/ui/text_area'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { getHostingLookupList } from 'app/redux/store/features/company'
import { useSnackbar } from 'notistack'
import { PatternRule, RequiredRule } from 'devextreme-react/data-grid'

const AddCompaniesForm = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })

    const { loading } = useSelector((state) => state.companies)
    const { hostingLookups } = useSelector((state) => state.companies)

    useEffect(() => {
        dispatch(getHostingLookupList(''))
    }, [])

    const hostingTypeLookupStore = new DataSource({
        store: new ArrayStore({
            key: 'hostingOptionId',
            data: hostingLookups,
            totalCount: hostingLookups.length,
        }),
        reshapeOnPush: true,
    })

    const hostingTypeLookupEditorOptions = {
        dataSource: hostingTypeLookupStore,
        displayExpr: 'hostingOptionName',
        valueExpr: 'hostingOptionId',
        searchEnabled: true,
        showClearButton: true,
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/settings/companies'),
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }
    const phonePattern = /^\+\d{10,15}$/

    const validationRules = {
        companyName: [{ type: 'required', message: 'Company name is required.' }],
        contactName: [{ type: 'required', message: 'Contact name is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Add New Company
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.addCompany)}>
                    <Form
                        mode='form'
                        formData={props.companyData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem cssClass='form-group-item' caption='New Company'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <GroupItem colCount={3}>
                                    <SimpleItem dataField='hasEposBranches' editorType='dxCheckBox'>
                                        <Label text='Epos Branches' />
                                    </SimpleItem>
                                    <SimpleItem dataField='hasSpiralBranches' editorType='dxCheckBox'>
                                        <Label text='Spiral Branches' />
                                    </SimpleItem>
                                </GroupItem>
                                <EmptyItem />
                                <SimpleItem dataField='companyName' validationRules={validationRules.companyName} />
                                <SimpleItem dataField='contactName' validationRules={validationRules.contactName} />
                                <SimpleItem dataField='contactNo' editorOptions={phoneEditorOptions}>
                                    <RequiredRule message='Contact No is required.' />
                                    <PatternRule
                                        message='phone number must start with + contain 10 to 15 digits'
                                        pattern={phonePattern}
                                    />
                                </SimpleItem>

                                <SimpleItem
                                    dataField='fkHostingOptionId'
                                    editorType='dxSelectBox'
                                    editorOptions={hostingTypeLookupEditorOptions}>
                                    <Label text='Hosting Option' />
                                </SimpleItem>
                                <SimpleItem dataField='companyTag'>
                                    <Label text='Tag (lower case and up to 10 digits)' />
                                </SimpleItem>
                                <SimpleItem dataField='comments' editorType='dxTextArea' />
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddCompaniesForm
