import DraftsIcon from '@mui/icons-material/Drafts'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard'
import PartyModeIcon from '@mui/icons-material/PartyMode'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import NoCrashIcon from '@mui/icons-material/NoCrash'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import RuleFolderIcon from '@mui/icons-material/RuleFolder'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'

export const TransferTimeLineData = [
    {
        id: 15,
        icon: <DraftsIcon />,
        title: 'Transfer Draft Created',
        subTitle: '10 hours ago',
    },
    {
        id: 20,
        icon: <DepartureBoardIcon />,
        title: 'Ready For Dispatch',
        subTitle: '4 days ago',
    },
    {
        id: 25,
        icon: <PartyModeIcon />,
        title: 'Dispatch in Progress',
        subTitle: '1 week ago',
    },
    {
        id: 30,
        icon: <LocalShippingIcon />,
        title: 'Shipment Dispatched',
        subTitle: '2 months ago',
    },
    {
        id: 35,
        icon: <NoCrashIcon />,
        title: 'Shipment Delivered',
        subTitle: '2 months ago',
    },
    {
        id: 40,
        icon: <MarkEmailReadIcon />,
        title: 'Shipment Received',
        subTitle: '2 months ago',
    },
    {
        id: 45,
        icon: <RuleFolderIcon />,
        title: 'Received With Adjustments',
        subTitle: '2 months ago',
    },
    {
        id: 50,
        icon: <AssignmentTurnedInIcon />,
        title: 'Transfer Completed',
        subTitle: '2 months ago',
    },
    {
        id: 60,
        icon: <DisabledByDefaultIcon />,
        title: 'Transfer Cancelled',
        subTitle: '2 months ago',
    },
]
