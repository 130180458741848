import React from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import RightInfoPanelList from './components/RightInfoPanelList'

const RightInfoPanel = () => {

    return (
        <JumboCardQuick wrapperSx={{ p: 0 }}>
            <RightInfoPanelList />
        </JumboCardQuick>
    )
}

export default RightInfoPanel
