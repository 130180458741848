import React, { useState } from 'react'
import Div from '@jumbo/shared/Div'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import { TabContext, TabPanel } from '@mui/lab'
import InventoryDefinitionList from '../InventoryDefinitionList'
import AccountingDefinitionList from '../AccountingDefinitionList'

const DefinitionAppSidebar = () => {
    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'info.contrastText'} variant={'h5'}>
                    Definitions
                </Typography>
            }
            headerSx={{
                pt: 3,
                height: 40,
                bgcolor: 'primary.dark',
            }}>
            <TabContext value={'definitions'}>
                <JumboScrollbar
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                    className='data-grid-height'>
                    <TabPanel value={'definitions'} sx={{ p: 0 }}>
                        {<AccountingDefinitionList />}
                        {<InventoryDefinitionList />}
                    </TabPanel>
                </JumboScrollbar>
            </TabContext>
        </JumboCardQuick>
    )
}
export default DefinitionAppSidebar
