import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend, CartesianGrid } from 'recharts'
import { Chip, Typography } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import { formatDate } from 'app/utils/components/FormatDateTime/FormatDateTime'

const BannerRight = () => {
    const [yAxisDomain, setYAxisDomain] = useState([0, 100])
    const { admin } = useSelector((state) => state.dashboard)

    const formatYAxis = (tickItem) => {
        return `${tickItem}`
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip'>
                    <p className='label'>{`${formatDate(label)}`}</p>
                    <p className='data'>{`License Count: ${payload[0].value}`}</p>
                </div>
            )
        }

        return null
    }

    useEffect(() => {
        let minTotalCount = Infinity
        let maxTotalCount = -Infinity

        admin.rightPanel.data.forEach((item) => {
            if (item.count < minTotalCount) {
                minTotalCount = item.count
            }
            if (item.count > maxTotalCount) {
                maxTotalCount = item.count
            }
        })

        setYAxisDomain([Math.floor(minTotalCount), Math.ceil(minTotalCount)])
    }, [admin.rightPanel.data])

    return (
        <Grid>
            <JumboCardQuick
                title={
                    <Typography fontFamily='Lobster, cursive' variant={'h3'} mb={0} color={'info.dark'}>
                        Branch License Expiry
                    </Typography>
                }
                action={<Chip label={'Last ' + 30 + ' days'} color={'info'} size={'small'} />}
                wrapperSx={{ pt: 0 }}>
                <ResponsiveContainer height={200} debounce={200}>
                    {admin.rightPanel ? (
                        <BarChart data={admin.rightPanel.data}>
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='branchDate' tickLine={false} axisLine={false} tickFormatter={formatDate} />
                            <YAxis domain={yAxisDomain} margin={{ bottom: 20 }} tickFormatter={formatYAxis} />
                            <Tooltip content={<CustomTooltip />} cursor={false} />
                            <Legend />
                            <Bar dataKey='count' fill='#3EC3D0' name='License Count' />
                        </BarChart>
                    ) : (
                        <div>Loading...</div>
                    )}
                </ResponsiveContainer>
            </JumboCardQuick>
        </Grid>
    )
}

export default BannerRight
