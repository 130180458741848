import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    orders: { data: [], totalCount: 0 },
    orderTypeLookups: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getOrders: (state, action) => {
            state.orders = action.payload
            state.loading = false
            state.refreshing = false
        },
        getOrderTypeLookup: (state, action) => {
            state.orderTypeLookups.data = action.payload
            if (state.orderTypeLookups.data) state.orderTypeLookups.totalCount = state.orderTypeLookups.data.length
            state.loading = false
        },
        getExistingOrderTypeLookupById: (state, action) => {
            const index = state.orderTypeLookups.data.findIndex((order) => order.id === action.payload.id)
            if (index >= 0) state.orderTypeLookups.data[index] = action.payload
            else {
                state.orderTypeLookups.data.push(action.payload)
                state.orderTypeLookups.totalCount++
            }
            state.loading = false
        },
        getExistingOrderById: (state, action) => {
            const index = state.orders.data.findIndex(
                (order) => order.stockShipmentId === action.payload.stockShipmentId
            )
            if (index >= 0) state.orders.data[index] = action.payload
            else {
                state.orders.data.push(action.payload)
                state.orders.totalCount++
            }
            state.loading = false
        },

        getShipmentItems: (state, action) => {
            if (action.payload.data.length > 0) {
                const index = state.orders.data.findIndex(
                    (order) => order.stockShipmentId === action.payload.data[0].fkStockShipmentId
                )
                if (index >= 0) {
                    state.orders.data[index].txnItemListResponse = action.payload
                }
                //We cannot add item if the order does not exist
                else {
                    //     state.orders.data.push(action.payload)
                    //     state.orders.totalCount++
                }
            }
            state.loading = false
        },

        // addNewOrder: (state, action) => {
        //     state.orders.data.push(action.payload)
        //     state.orders.totalCount++
        //     state.loading = false
        // },
        // updateExistingOrder: (state, action) => {
        //     const index = state.orders.data.findIndex(
        //         (order) => order.stockShipmentId === action.payload.stockShipmentId
        //     )
        //     if (index >= 0) state.orders.data[index] = action.payload
        //     state.loading = false
        // },
        // removeOrder: (state, action) => {
        //     const index = state.orders.data.findIndex(
        //         (order) => order.stockShipmentId === action.payload.stockShipmentId
        //     )
        //     if (index >= 0) {
        //         state.orders.data.splice(index, 1)
        //         state.orders.totalCount--
        //     }
        //     state.loading = false
        // },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.stockShipmentId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getOrders,
    getOrderTypeLookup,
    getExistingOrderTypeLookupById,
    getExistingOrderById,
    getShipmentItems,
    // addNewOrder,
    // updateExistingOrder,
    // removeOrde
} = orderSlice.actions
export default orderSlice.reducer

export const getOrderList = (filter, refresh = false) => {
    let url = '/shipment/list'
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getOrders.type,
        onError: apiRequestedFailed.type,
    })
}

export const getOrderTypeLookupList = (filter) => {
    let url = filter ? `/shipment/type/lookup/list?search=${filter}` : `/shipment/type/lookup/list`

    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getOrderTypeLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getOrderTypeLookupById = (filter) => {
    let url = `/shipment/type/lookup/${filter.stockShipmentId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingOrderTypeLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getOrderById = (filter) => {
    let url = `/shipment/${filter.stockShipmentId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingOrderById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getShipmentItemsList = (filter) => {
    let url = `/shipment/${filter.stockShipmentId}/items`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getShipmentItems.type,
        onError: apiRequestedFailed.type,
    })
}

// export const addOrder = (order) => {
//     let url = `/sales/Order/add`
//     return apiCallBegan({
//         url: url,
//         method: 'post',
//         data: order,
//         onStart: apiRequested.type,
//         onSuccess: addNewOrder.type,
//         onError: apiRequestedFailed.type,
//     })
// }
//
// export const updateOrder = (order) => {
//     let url = `/sales/Order/update`
//     return apiCallBegan({
//         url: url,
//         method: 'PUT',
//         data: order,
//         onStart: apiRequested.type,
//         onSuccess: updateExistingOrder.type,
//         onError: apiRequestedFailed.type,
//     })
// }
// export const deleteOrder = (order) => {
//     let url = '/sales/Order/remove'
//     return apiCallBegan({
//         url: url,
//         method: 'DELETE',
//         data: order,
//         onStart: apiRequested.type,
//         onSuccess: removeOrder.type,
//         onError: apiRequestedFailed.type,
//     })
// }
