import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import Typography from '@mui/material/Typography'
import { TabContext, TabPanel } from '@mui/lab'
import GlobalConfig from './globalConfig/GlobalConfig'

function Configuration() {
    const navigate = useNavigate()
    const { tabTitle } = useParams()
    const [value, setValue] = React.useState(tabTitle ? tabTitle : 'globalconfig')

    const onTabSelectionChanged = (e, newValue) => {
        setValue(newValue)
        navigate(`/settings/configuration/${newValue}`)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} sx={{ mt: 1 }} variant={'h4'}>
                        Configuration
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabContext value={value}>
                    <TabList sx={{ bgcolor: 'background.default' }} onChange={onTabSelectionChanged}>
                        <Tab
                            label={'Global Config'}
                            value={'globalconfig'}
                            sx={{ width: '160px', textTransform: 'capitalize' }}
                        />
                    </TabList>
                    <TabPanel value='globalconfig'>
                        <GlobalConfig />
                    </TabPanel>
                </TabContext>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default Configuration
