import React from 'react'
import Div from '@jumbo/shared/Div'
import useDefinitionApp from '../../../hooks/useDefinitionApp'
import ChartOfAccount from '../../AccountingDefinitionList/components/chartofaccount/ChartOfAccount'
import TaxCodes from '../../AccountingDefinitionList/components/taxcodes/TaxCodes'
import InventoryTypes from '../../InventoryDefinitionList/components/inventorytypes'
import InventoryCategory from '../../InventoryDefinitionList/components/inventorycategory'
import InventorySubCategory from '../../InventoryDefinitionList/components/inventorysubcategory'
import StockLocations from '../../InventoryDefinitionList/components/stocklocations'
import UnitOfMeasure from '../../InventoryDefinitionList/components/unitofmeasure'

const ActiveDefinitionView = () => {
    const { activeDefinition } = useDefinitionApp()

    const definitionList = {
        chartofaccount: ChartOfAccount,
        taxcodes: TaxCodes,
        inventorytypes: InventoryTypes,
        inventorycategory: InventoryCategory,
        inventorysubcategory: InventorySubCategory,
        stocklocations: StockLocations,
        unitofmeasure: UnitOfMeasure,
    }

    if (activeDefinition.id) {
        return <Div>{React.createElement(definitionList[activeDefinition.id.replace(/-/g, '')])}</Div>
    } else {
        return <Div />
    }
}

export default ActiveDefinitionView
