let transferStatusService = {}

transferStatusService.transferStatusList = [
    { id: 0, transferStatus: 'Draft' },
    { id: 3, transferStatus: 'Submitted' },
    { id: 5, transferStatus: 'Cancelled' },
    { id: 7, transferStatus: 'Reopened' },
    { id: 10, transferStatus: 'Approved' },
]

transferStatusService.getTransferStatus = () => {
    return transferStatusService.transferStatusList
}

transferStatusService.getTransferStatusData = (row) => {
    const transferStatus = transferStatusService.transferStatusList.find((item) => item.id === row.transferStatus)
    return transferStatus ? transferStatus.transferStatus : ''
}

export default transferStatusService
