import { configureStore } from '@reduxjs/toolkit'

import auditLogReducer from './features/auditlog'
import autoNumbersReducer from './features/autonumbers'
import configReducer from './features/configs'
import branchesReducer from './features/branches'
import companyReducer from './features/company'
import customerReducer from './features/customers'
import definitionsReducer from './features/definitions'
import permissionsReducer from './features/permissions'
import inventoryReducer from './features/inventory'
import reportsReducer from './features/reports'
import suppliersReducer from './features/suppliers'
import ordersReducer from './features/orders'
import transactionsReducer from './features/transactions'
import usersReducer from './features/users'
import profileReducer from './features/profile'
import dashboardReducer from './features/dashboard'
import discountsReducer from './features/dicounts'
import requestsReducer from './features/requests.js'
import transfersReducer from './features/transfers.js'

import api from './middleware/api'

const store = configureStore({
    reducer: {
        auditLog: auditLogReducer,
        autoNumbers: autoNumbersReducer,
        config: configReducer,
        dashboard: dashboardReducer,
        branches: branchesReducer,
        companies: companyReducer,
        customers: customerReducer,
        definitions: definitionsReducer,
        permissions: permissionsReducer,
        inventories: inventoryReducer,
        reports: reportsReducer,
        suppliers: suppliersReducer,
        orders: ordersReducer,
        transactions: transactionsReducer,
        users: usersReducer,
        profile: profileReducer,
        discounts: discountsReducer,
        requests: requestsReducer,
        transfers: transfersReducer,
    },
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), api],
})
export default store
