import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import pdfMake from 'pdfmake'
import Div from '@jumbo/shared/Div'
import { Pagination, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import DownloadIcon from '@mui/icons-material/Download'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from 'notistack'
import vfs from '../../../../../../../../../fonts/NimbusSanL/nimbus-san'
import '../../../../../../reportstyles.css'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import CustomerReceivableDetailReportFilter from './CustomerReceivableDetailReportFilter'

const CustomerReceivableDetailReportLayout = ({
    imageData,
    viewMutation,
    pdfMutation,
    pageMutation,
    apiCallInProgress,
    setApiCallInProgress,
    pdfData,
}) => {
    const { customersReceivablesDetailReport, loading, error } = useSelector((state) => state.reports)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [isFilterVisible, setFilterVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pdfDownloading, setPdfDownloading] = useState(false)
    const [pdfTemplate, setpdfTemplate] = useState({})
    const itemsPerPage = 25

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    pdfMake.vfs = vfs
    pdfMake.fonts = {
        NimbusSans: {
            normal: 'NimbusSanL-Reg.otf',
            bold: 'NimbusSanL-Bol.otf',
            italics: 'NimbusSanL-RegIta.otf',
            bolditalics: 'NimbusSanL-BolIta.otf',
        },
    }

    const handlePageChange = async (page) => {
        try {
            await pageMutation.mutate(page)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error updating page:', error)
        }
    }

    const handleFilterClick = () => {
        setFilterVisible(!isFilterVisible)
    }

    const headers = React.useMemo(
        () => [
            'Sale No',
            'Sale Date',
            'Customer Name',
            'Sale Type',
            'Checkedout By',
            'Comments',
            'Total Amount',
            'Paid',
            'Remaining',
        ],
        []
    )

    const parseToPdfData = useMemo(
        () => (data) => {
            return data.map((d) => {
                return [
                    d.saleNo,
                    formatDateTime(d.saleDate),
                    d.customerName,
                    d.salesType,
                    d.checkedOutBy,
                    d.comments,
                    formatCurrency(d.totalAmount),
                    formatCurrency(d.paid),
                    formatCurrency(d.remaining),
                ]
            })
        },
        []
    )

    const pageRow = []

    const tableBody =
        pdfData?.data?.length > 0
            ? parseToPdfData(pdfData.data).map((row) =>
                  row.map((cell, index) => ({
                      text: cell,
                      style: ['cell', index <= 5 ? 'leftAlign' : 'rightAlign'],
                  }))
              )
            : []

    const pdfTemplateDefault = React.useMemo(() => {
        return {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [15, 60, 15, 60],
            footer: function (currentPage, pageCount) {
                const isLastPage = currentPage === pageCount
                if (!isLastPage) {
                    return {
                        columns: [
                            {
                                table: {
                                    headerRows: 0,
                                    widths: [70, 105, 110, 60, 80, 125, 60, 60, 60, 60],
                                    body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                                },
                                margin: [20, 10, 30, 10],
                            },
                        ],
                    }
                }

                return {
                    columns: [
                        {
                            table: {
                                headerRows: 0,
                                widths: [80, 80, 80, 80, 80, 80, 80, 80, 80, 80],
                                body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                            },
                            margin: [20, 10, 30, 10],
                        },
                    ],
                }
            },
            content: [
                {
                    image: imageData,
                    width: 70,
                },
                {
                    text: 'Customers Receivable Detail Report',
                    style: 'header',
                    alignment: 'center',
                },
                {
                    layout: {
                        fillColor: function (rowIndex) {
                            return rowIndex % 2 === 1 ? '#f2f2f2' : null
                        },
                    },
                    alignment: 'center', // header aligment
                    style: 'withMargin',
                    table: {
                        headerRows: 1,
                        widths: [70, 105, 110, 60, 80, 125, 60, 60, 60, 60],
                        body: [
                            headers.map((header, index) => {
                                return { text: header, style: 'headerRow' }
                            }),
                            ...tableBody,
                        ],
                    },
                },
            ],
            defaultStyle: {
                font: 'NimbusSans', //applied on EpoS Icon
            },
            styles: {
                withMargin: {
                    margin: [5, 20, 20, 5],
                },
                header: {
                    fontSize: 18,
                    bold: true,
                },
                headerRow: {
                    fillColor: '#ADD8E6',
                    bold: true,
                    fontSize: 10,
                },
                cell: {
                    fontSize: 9,
                    alignment: 'center',
                },
                rightAlign: {
                    alignment: 'right',
                    fontSize: 9,
                },
                leftAlign: {
                    alignment: 'left',
                    fontSize: 9,
                },
                rowHeading: {
                    alignment: 'center',
                    fontSize: 10,
                },
            },
        }
    }, [imageData, headers, pdfData])

    useEffect(() => {
        const template = { ...pdfTemplateDefault }
        setpdfTemplate(template)
    }, [headers, pdfData, pdfTemplateDefault])

    const create = async () => {
        await pdfMutation.mutate()
        setPdfDownloading(true)
    }

    useEffect(() => {
        if (!pdfDownloading) return
        setPdfDownloading(false)
        const pdfDocGenerator = pdfMake.createPdf(pdfTemplate)
        let today = new Date()
        pdfDocGenerator.download(
            `Customers_Receivables_Detail_Report_${today.toLocaleDateString()}_${today.toLocaleTimeString()}`
        )
    }, [pdfTemplate])

    return (
        <Div>
            <h1 className='report-heading'>Customer's Receivable Detail Report</h1>
            <LoadingButton
                sx={{ float: 'right', marginRight: '90px', marginTop: '-65px' }}
                onClick={create}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <DownloadIcon sx={{ fontSize: 'medium', marginRight: '2px' }} /> Download Report
            </LoadingButton>
            <LoadingButton
                sx={{ float: 'right', marginRight: '9px', marginTop: '-65px' }}
                onClick={handleFilterClick}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <FilterAltIcon fontSize='medium' />
            </LoadingButton>
            {isFilterVisible && <CustomerReceivableDetailReportFilter mutation={viewMutation} />}
            <>
                <div className='report-container'>
                    {loading && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    {!loading && customersReceivablesDetailReport.totalCount === 0 ? (
                        <Typography variant={'primary'}>No data. Select suitable filter to view data</Typography>
                    ) : (
                        <div className='custom-table'>
                            <table>
                                <tbody>
                                    <tr>
                                        {headers.map((h, index) => {
                                            return (
                                                <th
                                                    key={h}
                                                    style={{
                                                        whiteSpace: index === 3 ? 'pre' : 'pre-line',
                                                    }}>
                                                    {h}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {customersReceivablesDetailReport.data?.map((d, index) => {
                                        return (
                                            <tr key={index} style={{ fontSize: '13px' }}>
                                                <td style={{ width: '90px', textAlign: 'left' }}>{d.saleNo}</td>
                                                <td style={{ width: '140px', textAlign: 'left' }}>
                                                    {formatDateTime(d.saleDate)}
                                                </td>
                                                <td style={{ width: '145px', textAlign: 'left' }}>{d.customerName}</td>
                                                <td
                                                    style={{
                                                        width: '90px',
                                                        textAlign: 'left',
                                                    }}>
                                                    {d.salesType}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'left' }}>{d.checkedOutBy}</td>
                                                <td style={{ width: '185px', textAlign: 'left' }}>{d.comments}</td>
                                                <td style={{ width: '90px', textAlign: 'right' }}>
                                                    {formatCurrency(d.totalAmount)}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'right' }}>
                                                    {formatCurrency(d.paid)}
                                                </td>
                                                <td style={{ width: '90px', textAlign: 'right' }}>
                                                    {formatCurrency(d.remaining)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={headers.length} />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Stack>
                        <Pagination
                            count={Math.ceil(customersReceivablesDetailReport.totalCount / itemsPerPage)}
                            onChange={(event, page) => {
                                handlePageChange(page)
                            }}
                            page={currentPage}
                            siblingCount={1}
                            boundaryCount={1}
                            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px' }}
                            variant='outlined'
                        />
                    </Stack>
                </div>
            </>
        </Div>
    )
}

export default CustomerReceivableDetailReportLayout
