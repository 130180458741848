import React from 'react'
import ActiveAutoNumberView from './ActiveAutoNumberView/ActiveAutoNumberView'

const ActiveAutoNumber = React.forwardRef(({ onRefresh }, ref) => {
    return (
        <React.Fragment>
            <ActiveAutoNumberView />
        </React.Fragment>
    )
})

ActiveAutoNumber.defaultProps = {
    onRefresh: () => {},
}

export default ActiveAutoNumber
