import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Span from '@jumbo/shared/Span'
import { Grid } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import PaidIcon from '@mui/icons-material/Paid'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import PaymentIcon from '@mui/icons-material/Payment'
import formatCurrency from '../../../../../../../utils/components/formatCurrency/formatCurrency'

const HeaderCard = ({ transaction }) => {
    return (
        <Grid>
            <Grid container spacing={3.75}>
                <Grid item xs={6} sm={3} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack direction={'row'} alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                                <Typography
                                    sx={{ textAlign: 'center' }}
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    variant={'h3'}>
                                    {transaction?.txnNo ?? ''}
                                    <AccountBalanceWalletIcon color='common.white' sx={{ ml: 1 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'secondary.main',
                        }}>
                        <Typography fontFamily='Lobster, cursive' color='secondary.dark' variant={'h4'}>
                            <DateRangeIcon fontSize='large' sx={{ mt: -3, mb: -1, mr: 1 }} />
                            <Span>{`${formatDateTime(transaction?.txStartDate ?? '')}`}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>

                <Grid item xs={6} sm={3} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack direction={'row'} alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                                <Typography fontFamily='Lobster, cursive' color='common.white' variant={'h3'}>
                                    Sub Total <LocalOfferIcon color='common.white' sx={{ ml: 1 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'success.main',
                        }}>
                        <Typography variant={'h4'} fontFamily='Lobster, cursive' color='success.dark'>
                            <PaidIcon fontSize='large' sx={{ mt: -3, mb: -1, mr: 1 }} />
                            <Span sx={{ ml: 1 }}>{formatCurrency(transaction?.subTotal ?? '')}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>

                <Grid item xs={6} sm={3} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack direction={'row'} alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                                <Typography fontFamily='Lobster, cursive' color='common.white' variant={'h3'}>
                                    Invoice Profit <AddShoppingCartIcon color='common.white' sx={{ ml: 1 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'warning.main',
                        }}>
                        <Typography variant={'h4'} fontFamily='Lobster, cursive' color='warning.dark'>
                            <AddShoppingCartIcon fontSize='large' sx={{ mt: -3, mb: -1, ml: 1 }} />
                            <Span sx={{ ml: 1 }}>{formatCurrency(transaction?.invoiceProfit ?? '')}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>

                <Grid item xs={6} sm={3} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack direction={'row'} alignItems='center' justifyContent='center' sx={{ mt: 1 }}>
                                <Typography fontFamily='Lobster, cursive' color='common.white' variant={'h3'}>
                                    Payment Total <PaymentIcon color='common.white' sx={{ ml: 1 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'info.main',
                        }}>
                        <Typography color='info.dark' variant={'h4'} fontFamily='Lobster, cursive'>
                            <LocalAtmIcon fontSize='large' sx={{ mt: -3, mb: -1, ml: 1 }} />
                            <Span sx={{ ml: 1 }}>{formatCurrency(transaction?.totalPayment ?? '')}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HeaderCard
