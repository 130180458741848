import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import 'devextreme-react/tag-box'
import { useSnackbar } from 'notistack'
import AutoNumberForm from '../../AutoNumberForm'
import { getTransactionAutoNumber, updateTransactionAutoNumber } from 'app/redux/store/features/autonumbers'

const Transaction = () => {
    const navigate = useNavigate()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { transactionAutoNumber, loading, error } = useSelector((state) => state.autoNumbers)
    const transaction = { ...transactionAutoNumber }

    useEffect(() => {
        dispatch(getTransactionAutoNumber(''))
    }, [dispatch])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateTransaction = async () => {
        setApiCallInProgress(true)
        dispatch(updateTransactionAutoNumber(transaction))
        enqueueSnackbar('Transaction Auto Number updated successfully', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    }

    return (
        <React.Fragment>
            <AutoNumberForm
                autoNumberData={transaction}
                heading={'Transaction'}
                onUpdate={onUpdateTransaction}
                reloadAutoNumber={() => dispatch(getTransactionAutoNumber(''))}
            />
        </React.Fragment>
    )
}

export default Transaction
