const definitionServices = {}

definitionServices.getAccountingDefinitionList = async () => {
    return {
        definitions: [
            {
                id: 'chart-of-account',
                name: 'Chart of Account',
                category: 'accounting',
                description: 'This Defintions contains Chart of Account Defintions info',
            },
            {
                id: 'tax-codes',
                name: 'Tax Codes',
                category: 'accounting',
                description: 'This Defintions contains Tax Codes Defintions info',
            },
        ],
    }
}

definitionServices.getInventoryDefinitionList = async () => {
    return {
        definitions: [
            {
                id: 'inventory-types',
                name: 'Inventory Types',
                category: 'inventory',
                description: 'This Defintions contains Inventory Types Defintions info',
            },
            {
                id: 'inventory-category',
                name: 'Inventory Category',
                category: 'inventory',
                description: 'This Defintions contains Inventory Category Defintions info',
            },
            {
                id: 'inventory-sub-category',
                name: 'Inventory Sub-Category',
                category: 'inventory',
                description: 'This Defintions contains Inventory Sub-Category Defintions info',
            },
            {
                id: 'stock-locations',
                name: 'Stock Locations',
                category: 'inventory',
                description: 'Stock Storage locations for inventories',
            },
            {
                id: 'unit-of-measure',
                name: 'Unit of Measure',
                category: 'inventory',
                description: 'This Defintions contains Unit of Measure Defintions info',
            },
        ],
    }
}

export default definitionServices
