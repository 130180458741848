import React from 'react'
import { Timeline } from '@mui/lab'
import TransferTimeLineItems from './TransferTimeLineItems'

const TransferTimeLineList = ({ statusList, transfer }) => {
    return (
        <Timeline sx={{ m: 0, p: (theme) => theme.spacing(0, 3) }}>
            {statusList.map((item, index) => (
                <TransferTimeLineItems
                    status={item}
                    key={index}
                    lastItemEdge={index === statusList.length - 1}
                    transfer={transfer}
                />
            ))}
        </Timeline>
    )
}

export default TransferTimeLineList
