export const defaultSupplier = {
    supplierCode: '',
    companyName: '',
    firstName: ' ',
    lastName: '',
    barcode: '',
    abn: '',
    email: '',
    fkSupplierCompanyId: null,
    phoneNo: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    suburb: '',
    postCode: '',
    isTaxExempted: false,
}
