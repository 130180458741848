import DraftsIcon from '@mui/icons-material/Drafts'
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen'
import DevicesFoldIcon from '@mui/icons-material/DevicesFold'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'

export const requestTimeLineData = [
    {
        id: 0,
        icon: <DraftsIcon />,
        title: 'Request Draft',
        subTitle: '10 hours ago',
    },
    {
        id: 3,
        icon: <AddToHomeScreenIcon />,
        title: 'Request Submitted',
        subTitle: '4 days ago',
    },
    {
        id: 5,
        icon: <DisabledByDefaultIcon />,
        title: 'Request Cancelled',
        subTitle: '1 week ago',
        color: 'green',
    },
    {
        id: 7,
        icon: <DevicesFoldIcon />,
        title: 'Request Reopened',
        subTitle: '3 weeks ago',
    },
    {
        id: 10,
        icon: <AssignmentTurnedInIcon />,
        title: 'Request Approved',
        subTitle: '2 months ago',
    },
]
