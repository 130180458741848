import React from 'react'
import { Navigate } from 'react-router-dom'
import SignIn from '../pages/login'
import homeRoutes from './homeRoutes'
import securityRoutes from './securityRoutes'
import storeRoutes from './storeRoutes'
import profileRoutes from './profileRoutes'
import Page from '@jumbo/shared/Page'
import reportRoutes from './reportRoutes'
import appsRoutes from './appsRoutes'
import settingRoutes from './settingRoutes'
import companyRoutes from './companyRoutes'

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = []

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: '/',
        element: <Navigate to={'/dashboard'} />,
    },
    ...homeRoutes,
    ...securityRoutes,
    ...appsRoutes,
    ...storeRoutes,
    ...reportRoutes,
    ...settingRoutes,
    ...companyRoutes,
    ...profileRoutes,
]

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: '/signin',
        element: <Page component={SignIn} layout={'solo-page'} disableSmLogin={true} />,
    },
]

const routes = [...routesForPublic, ...routesForAuthenticatedOnly, ...routesForNotAuthenticatedOnly]

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly }
