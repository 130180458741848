import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'
import { buildQuery } from '../../../utils/appHelpers'

const initialState = {
    branches: { data: [], totalCount: 0 },
    branchUsers: { data: [], totalCount: 0 },
    branchLookup: { data: [], totalCount: 0 },
    branchTypes: [],
    loading: false,
    error: null,
    refreshing: false,
}

const branchSlice = createSlice({
    name: 'branches',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getBranches: (state, action) => {
            state.branches = action.payload
            state.loading = false
            state.refreshing = false
        },
        getBranchLookup: (state, action) => {
            state.branchLookup = action.payload
            state.loading = false
        },

        getExistingBranchById: (state, action) => {
            const index = state.branches.data.findIndex((branch) => branch.branchId === action.payload.branchId)
            if (index >= 0) state.branches.data[index] = action.payload
            else {
                state.branches.data.push(action.payload)
                state.branches.totalCount++
            }
            state.loading = false
        },
        addNewBranch: (state, action) => {
            state.branches.data.push(action.payload)
            state.branches.totalCount++
            state.loading = false
        },
        updateExistingBranch: (state, action) => {
            const index = state.branches.data.findIndex((branch) => branch.branchId === action.payload.branchId)
            if (index >= 0) state.branches.data[index] = action.payload
            state.loading = false
        },
        removeBranch: (state, action) => {
            const index = state.branches.data.findIndex((branch) => branch.branchId === action.payload.branchId)
            if (index >= 0) {
                state.branches.data.splice(index, 1)
                state.branches.totalCount--
            }
            state.loading = false
        },

        getBranchUsers: (state, action) => {
            state.branchUsers = action.payload
            state.loading = false
        },

        getBranchTypeLookup: (state, action) => {
            state.branchTypes = action.payload
            state.loading = false
        },
        getExistingBranchTypeLookupById: (state, action) => {
            const index = state.branchTypes.findIndex((branchType) => branchType.id === action.payload.id)
            if (index >= 0) state.branchTypes[index] = action.payload
            else {
                state.branchTypes.push(action.payload)
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.branchId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getBranches,
    getBranchLookup,
    getExistingBranchById,
    getBranchTypeLookup,
    getExistingBranchTypeLookupById,
    addNewBranch,
    updateExistingBranch,
    removeBranch,
    getBranchUsers,
} = branchSlice.actions
export default branchSlice.reducer

export const getBranchList = (filter, refresh = false) => {
    let url = `/company/${filter.fkCompanyId}/branch/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getBranches.type,
        onError: apiRequestedFailed.type,
    })
}

export const getBranchLookupList = (companyId, queryParams) => {
    let url = buildQuery(`/company/${companyId}/branch/lookup/list`, queryParams)
    return apiCallBegan({
        url: url,
        method: 'get',
        data: {},
        onStart: apiRequested.type,
        onSuccess: getBranchLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getBranchById = (filter) => {
    let url = `/company/${filter.companyId}/branch/${filter.branchId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingBranchById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getBranchTypeLookupList = (filter) => {
    let url = `/company/branch/type/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getBranchTypeLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getBranchTypeLookupById = (filter) => {
    let url = `/company/branch/type/lookup/${filter.branchTypeId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingBranchTypeLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addBranch = (branch) => {
    let url = `/company/branch/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: branch,
        onStart: apiRequested.type,
        onSuccess: addNewBranch.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateBranch = (branch) => {
    let url = `/company/branch/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: branch,
        onStart: apiRequested.type,
        onSuccess: updateExistingBranch.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteBranch = (branch) => {
    let url = '/company/branch/remove'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: branch,
        onStart: apiRequested.type,
        onSuccess: removeBranch.type,
        onError: apiRequestedFailed.type,
    })
}

export const getBranchUserList = (filter) => {
    let url = `/company/branch/${filter.branchId}/users/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getBranchUsers.type,
        onError: apiRequestedFailed.type,
    })
}
