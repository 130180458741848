import React from 'react'
import Page from '@jumbo/shared/Page'
import Companies from 'app/pages/settings/companies'
import Branches from 'app/pages/settings/branches'
import AddBranch from 'app/pages/settings/branches/components/branches/components/AddBranch'
import UpdateBranch from 'app/pages/settings/branches/components/branches/components/UpdateBranch'
import AddCompanies from 'app/pages/settings/companies/components/AddCompanies'
import UpdateCompanies from 'app/pages/settings/companies/components/UpdateCompanies'
import Configuration from 'app/pages/settings/configuration/components/Configuration'

const settingRoutes = [
    {
        path: '/settings/companies',
        element: <Page component={Companies} />,
    },
    {
        path: '/settings/companies/addCompany',
        element: <Page component={AddCompanies} />,
    },
    {
        path: '/settings/companies/edit/:companyId',
        element: <Page component={UpdateCompanies} />,
    },
    {
        path: '/settings/branches',
        element: <Page component={Branches} />,
    },
    {
        path: '/settings/companies/branches/add/:id',
        element: <Page component={AddBranch} />,
    },
    {
        path: '/settings/companies/:id/branches/edit/:branchId',
        element: <Page component={UpdateBranch} />,
    },

    {
        path: [
            '/settings/configuration',
            '/settings/configuration/:tabTitle',
            '/settings/configuration/:tabTitle/:selectedId',
        ],
        element: <Page component={Configuration} />,
    },
]

export default settingRoutes
