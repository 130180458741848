import React, { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, ColCountByScreen, EmptyItem, GroupItem, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import { getBranchTypeLookupList } from 'app/redux/store/features/branches'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import utilServices from 'app/services/util-services'
import { useSnackbar } from 'notistack'
import CustomStore from 'devextreme/data/custom_store'
import companyAndBranchServices from 'app/services/settings/company/companyAndBranchServices'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'

const UpdateBranchForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { branchTypes } = useSelector((state) => state.branches)
    const { loading } = useSelector((state) => state.branches)

    useEffect(() => {
        dispatch(getBranchTypeLookupList(''))
    }, [])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const comapanyLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await companyAndBranchServices.getCompanyLookupList({ typeName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [companyAndBranchServices]
    )

    const companyLookupById = useCallback(
        async (key) => {
            try {
                if (!key) return
                return await companyAndBranchServices.getCompanyLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [companyAndBranchServices]
    )

    const companyLookupStore = new DataSource({
        store: new CustomStore({
            key: 'companyId',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await comapanyLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return companyLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const storeTypeLookupStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: branchTypes,
            totalCount: branchTypes.length,
        }),
        reshapeOnPush: true,
    })

    const companyEditorOptions = {
        dataSource: companyLookupStore,
        displayExpr: 'companyName',
        valueExpr: 'companyId',
        searchEnabled: true,
        showClearButton: true,
        readOnly: true,
    }

    const storeEditorOptions = {
        dataSource: storeTypeLookupStore,
        displayExpr: 'typeName',
        valueExpr: 'id',
        showClearButton: true,
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate(`/settings/branches`),
    }

    const validationRules = {
        branchName: [{ type: 'required', message: 'Branch Name is required.' }],
        branchType: [{ type: 'required', message: 'Branch type is required.' }],
        licenseStartDate: [{ type: 'required', message: 'License Start Date is required.' }],
        licenseEndDate: [{ type: 'required', message: 'End Date is required.' }],
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(props.updateBranch)}>
                <Form mode='form' formData={props.branchData} showColonAfterLabel={false} showValidationSummary={false}>
                    <GroupItem cssClass='form-group-item' caption='Update Branch Details'>
                        <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem cssClass='form-group-item' colSpan={6}>
                            <EmptyItem />
                            <SimpleItem
                                dataField='fkCompanyId'
                                editorType='dxSelectBox'
                                editorOptions={companyEditorOptions}>
                                <Label text='Company' />
                            </SimpleItem>
                            <SimpleItem dataField='branchName' validationRules={validationRules.storeName}>
                                <Label text='Branch Name' />
                            </SimpleItem>
                            <SimpleItem dataField='address' />
                            <SimpleItem dataField='phoneNo' />
                            <SimpleItem
                                dataField='branchType'
                                editorType='dxSelectBox'
                                editorOptions={storeEditorOptions}
                                validationRules={validationRules.branchType}
                            />
                            <GroupItem colCount={2}>
                                <SimpleItem dataField='transferAutoDispatch' editorType='dxCheckBox'>
                                    <Label text='Transfer Auto Dispatch' />
                                </SimpleItem>
                                <SimpleItem dataField='transferAutoReceive' editorType='dxCheckBox'>
                                    <Label text='Transfer Auto Receive' />
                                </SimpleItem>
                            </GroupItem>
                            <SimpleItem dataField='reportXClientId'>
                                <Label text='Client Id' />
                            </SimpleItem>
                            <SimpleItem dataField='reportXClientSecret'>
                                <Label text='Client Secret' />
                            </SimpleItem>
                            <SimpleItem dataField='clientVersion' editorOptions={{ readOnly: true }}>
                                <Label text='Client Version' />
                            </SimpleItem>
                            <SimpleItem
                                dataField='licenseStartDate'
                                editorType='dxDateBox'
                                validationRules={validationRules.licenseStartDate}
                                editorOptions={{
                                    displayFormat: 'shortDateShortTime',
                                    type: 'datetime',
                                    pickerType: 'calendar',
                                    useMaskBehavior: true,
                                }}>
                                <Label text='License Start Date' />
                            </SimpleItem>
                            <SimpleItem
                                dataField='licenseEndDate'
                                editorType='dxDateBox'
                                validationRules={validationRules.licenseEndDate}
                                editorOptions={{
                                    displayFormat: 'shortDateShortTime',
                                    type: 'datetime',
                                    pickerType: 'calendar',
                                    useMaskBehavior: true,
                                }}>
                                <Label text='License End Date' />
                            </SimpleItem>
                            <SimpleItem dataField='timezoneId' editorOptions={{ readOnly: true }}>
                                <Label text='Time Zone' />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem
                            buttonOptions={{
                                disabled: loading,
                                icon: loading ? 'refresh' : 'check',
                                ...submitButtonOptions,
                            }}
                            horizontalAlignment='right'
                        />
                        <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                    </GroupItem>
                </Form>
            </form>
        </React.Fragment>
    )
}

export default UpdateBranchForm
