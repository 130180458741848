import React from 'react'
import DefinitionAppContext from './DefinitionAppContext'
import { useParams } from 'react-router-dom'

const DefinitionAppProvider = ({ children }) => {
    const { id } = useParams()

    const [activeDefinition, setActiveDefinition] = React.useState({
        id: id ? id : 'chart-of-account',
    })
    const [activeDefinitionColorList, setActiveDefinitionColorList] = React.useState([])
    const activeDefinitionRef = React.useRef()
    const inventoryDefinitionListRef = React.useRef()
    const shipmentDefinitionListRef = React.useRef()
    const salesDefinitionListRef = React.useRef()
    const orderDefinitionListRef = React.useRef()

    const contextValue = React.useMemo(
        () => ({
            activeDefinition: activeDefinition,
            setActiveDefinition: setActiveDefinition,
            activeDefinitionColorList: activeDefinitionColorList,
            setColorActiveDefinition: setActiveDefinitionColorList,
            shipmentDefinitionListRef: shipmentDefinitionListRef,
            activeDefinitionRef: activeDefinitionRef,
            salesDefinitionListRef: salesDefinitionListRef,
            inventoryDefinitionListRef: inventoryDefinitionListRef,
            orderDefinitionListRef: orderDefinitionListRef,
        }),
        [
            activeDefinition,
            orderDefinitionListRef,
            salesDefinitionListRef,
            activeDefinitionColorList,
            shipmentDefinitionListRef,
            activeDefinitionRef,
            inventoryDefinitionListRef,
        ]
    )

    React.useEffect(() => {
        const found = activeDefinitionColorList.find(function (element) {
            return element.id === activeDefinition?.id
        })
        // Reset the list as we will be setting a new id
        const resetList = activeDefinitionColorList.map((c, i) => {
            return { id: c.id, color: 'text.secondary' }
        })

        // Add or update
        if (found) {
            const newList = resetList.map((c, i) => {
                if (c.id === activeDefinition?.id) {
                    return { id: c.id, color: 'primary.main' }
                }
                return { id: c.id, color: 'text.secondary' }
            })
            setActiveDefinitionColorList(newList)
        } else {
            let newItem = { id: activeDefinition?.id, color: 'primary.main' }
            let updatedItems = [...resetList, newItem]
            setActiveDefinitionColorList(updatedItems)
        }
    }, [setActiveDefinitionColorList, activeDefinition])

    return <DefinitionAppContext.Provider value={contextValue}>{children}</DefinitionAppContext.Provider>
}

export default DefinitionAppProvider
