import React, { useEffect, useState, useCallback } from 'react'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
    CustomPaging,
    PagingState,
    SortingState,
    SelectionState,
    SummaryState,
    IntegratedSummary,
    DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSummaryRow,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import { CurrencyFormatterSummary } from 'app/utils/components/formatCurrency/formatCurrency'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { useSelector } from 'react-redux'

const TxnItemsDataGrid = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
    txnItemsData,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { loading, error } = useSelector((state) => state.transactions)
    const [sorting, setSorting] = useState([{ columnName: 'cartName', direction: 'asc' }])
    const pageSize = 10

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const CurrencyTypeProvider = (props) => (
        <DataTypeProvider formatterComponent={CurrencyFormatterSummary} {...props} />
    )

    const QtyTypeProvider = (props) => <DataTypeProvider {...props} />

    const Cell = (props) => {
        let style = {
            height: '42px',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const columns = [
        { name: 'cartName', title: 'Product Name' },
        { name: 'qty', title: 'QTY' },
        { name: 'unitPrice', title: 'Unit Price', getCellValue: (row) => formatCurrency(row.unitPrice) },
        { name: 'discount', title: 'Discount' },
        { name: 'subTotal', title: 'Sub Total' },
        { name: 'taxTotal', title: 'Tax Total' },
        { name: 'lineItemTotal', title: 'Line Item Total' },
        { name: 'comments', title: 'Comments' },
    ]

    const tableColumnExtensions = [
        { columnName: 'subTotal', align: 'right' },
        { columnName: 'taxTotal', align: 'right' },
        { columnName: 'cartName', width: '18%' },
        { columnName: 'qty', align: 'center', width: '9%' },
        { columnName: 'unitPrice', align: 'right' },
        { columnName: 'discount', align: 'right' },
        { columnName: 'lineItemTotal', align: 'right' },
        { columnName: 'comments', width: '18%' },
    ]

    const totalSummaryItems = [
        { columnName: 'subTotal', type: 'sum', title: 'Total' },
        { columnName: 'taxTotal', type: 'sum' },
        { columnName: 'discount', type: 'sum' },
        { columnName: 'lineItemTotal', type: 'sum' },
        { columnName: 'qty', type: 'sum' },
    ]

    const currencyColumns = ['subTotal', 'taxTotal', 'discount', 'lineItemTotal']
    const qtyColumns = ['qty']

    const messages = {
        sum: 'Total',
    }

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={txnItemsData.data} columns={columns}>
                    <CurrencyTypeProvider for={currencyColumns} />
                    <QtyTypeProvider for={qtyColumns} />
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={txnItemsData.totalCount} />
                    <SummaryState totalItems={totalSummaryItems} />
                    <IntegratedSummary />
                    <Table columnExtensions={tableColumnExtensions} cellComponent={Cell} />
                    <TableHeaderRow showSortingControls />
                    <TableSummaryRow messages={messages} />
                    <SelectionState />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default TxnItemsDataGrid
