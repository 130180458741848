import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, {
    ButtonItem,
    SimpleItem,
    EmptyItem,
    ColCountByScreen,
    GroupItem,
    TabbedItem,
    TabPanelOptions,
    Tab,
    Label,
} from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerCategoryLookupList } from 'app/redux/store/features/customers'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { PatternRule, RequiredRule } from 'devextreme-react/data-grid'

const AddCustomerForm = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })

    const { customerCategoryLookups, loading } = useSelector((state) => state.customers)

    useEffect(() => {
        dispatch(getCustomerCategoryLookupList(''))
    }, [])

    const CustomerCategoryLookupStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'customerCategoryId',
                    data: customerCategoryLookups.data,
                    totalCount: customerCategoryLookups.length,
                }),
                reshapeOnPush: true,
            }),
        [customerCategoryLookups]
    )

    const CustomerCategoryLookupEditorOptions = {
        dataSource: CustomerCategoryLookupStore,
        displayExpr: 'name',
        valueExpr: 'customerCategoryId',
        searchEnabled: true,
        showClearButton: true,
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }
    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/customers'),
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }
    const phonePattern = /^\+\d{10,15}$/
    const validationRules = {
        firstName: [{ type: 'required', message: 'First name is required.' }],
        lastName: [{ type: 'required', message: 'Last name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Email is invalid.' },
        ],
    }

    const messageEditorOptions = { height: 90, maxLength: 200 }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Add Customer
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.addCustomers)}>
                    <Form mode='form' formData={props.customerData} showColonAfterLabel={false}>
                        <GroupItem>
                            <TabbedItem>
                                <TabPanelOptions deferRendering={false} />
                                <Tab title=' Customer Details'>
                                    <GroupItem>
                                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                        <GroupItem caption='Personal Info' cssClass='form-group-item'>
                                            <GroupItem cssClass='form-group-item-margin'>
                                                <GroupItem colCount={3}>
                                                    <SimpleItem dataField='isWholesaleCustomer' editorType='dxCheckBox'>
                                                        <Label text='Whole-Sale Customer' />
                                                    </SimpleItem>
                                                    <SimpleItem dataField='isTaxExempted' editorType='dxCheckBox'>
                                                        <Label text='Tax Exempted' />
                                                    </SimpleItem>
                                                    <SimpleItem dataField='isLoyaltyEnabled' editorType='dxCheckBox'>
                                                        <Label text='Loyalty' />
                                                    </SimpleItem>
                                                </GroupItem>
                                                <EmptyItem />
                                                <SimpleItem dataField='customerCode' />
                                                <SimpleItem
                                                    dataField='firstName'
                                                    validationRules={validationRules.firstName}
                                                />
                                                <SimpleItem
                                                    dataField='lastName'
                                                    validationRules={validationRules.lastName}
                                                />
                                                <SimpleItem dataField='cnIcNumber'>
                                                    <Label text='CNIC Number' />
                                                </SimpleItem>
                                                <SimpleItem dataField='taxFileNumber' />
                                                <SimpleItem
                                                    dataField='fkCustomerCategoryId'
                                                    editorType='dxSelectBox'
                                                    editorOptions={CustomerCategoryLookupEditorOptions}>
                                                    <Label text='Customer Category' />
                                                </SimpleItem>
                                            </GroupItem>
                                        </GroupItem>
                                        <GroupItem caption='Contact Info' cssClass='form-group-item '>
                                            <GroupItem cssClass='form-group-item-margin'>
                                                <SimpleItem dataField='mobileNo' editorOptions={phoneEditorOptions}>
                                                    <RequiredRule message='Mobile number is required.' />
                                                    <PatternRule
                                                        message='phone number must start with + contain 10 to 15 digits'
                                                        pattern={phonePattern}
                                                    />
                                                </SimpleItem>
                                                <SimpleItem dataField='addressLine1'>
                                                    <Label text='Address Line One' />
                                                </SimpleItem>
                                                <SimpleItem dataField='addressLine2'>
                                                    <Label text='Address Line two' />
                                                </SimpleItem>
                                                <SimpleItem dataField='email' validationRules={validationRules.email} />
                                                <SimpleItem dataField='area' />
                                                <SimpleItem dataField='city' />
                                                <SimpleItem
                                                    dataField='comments'
                                                    editorType='dxTextArea'
                                                    editorOptions={messageEditorOptions}>
                                                    <Label text='Notes' />
                                                </SimpleItem>
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                    <GroupItem>
                                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                        <GroupItem caption='State Info' cssClass='form-group-item '>
                                            <GroupItem cssClass='form-group-item-margin'>
                                                <SimpleItem dataField='country' />
                                                <SimpleItem dataField='suburb' />
                                                <SimpleItem dataField='state' />
                                                <SimpleItem dataField='postCode' />
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                </Tab>
                            </TabbedItem>
                        </GroupItem>
                        <EmptyItem />
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddCustomerForm
