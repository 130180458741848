import React, { useEffect, useState, useCallback } from 'react'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { CustomPaging, PagingState, SortingState, SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow, PagingPanel, VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import { useSelector } from 'react-redux'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'

const RequestItemListData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
    requestItemsData,
}) => {
    const pageSize = 10
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { loading, error } = useSelector((state) => state.requests)
    const dateColumns = ['updatedDate']
    const [sorting, setSorting] = useState([{ columnName: 'cartName', direction: 'asc' }])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const Cell = (props) => {
        let style = {
            height: '42px',
        }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const columns = [
        { name: 'cartName', title: 'Cart Name' },
        { name: 'qtyRequested', title: 'Qty Requested' },
        { name: 'updatedDate', title: 'Date Updated' },
        { name: 'comments', title: 'Comments' },
    ]

    const tableColumnExtensions = [
        { columnName: 'cartName', width: '20%' },
        { columnName: 'qtyRequested', align: 'center', width: '20%' },
        { columnName: 'updatedDate', width: '20%' },
        { columnName: 'comments', title: 'Comments', width: '40%' },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={requestItemsData.data} columns={columns}>
                    <DateTypeProvider for={dateColumns} />
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={requestItemsData.totalCount} />
                    <Table columnExtensions={tableColumnExtensions} cellComponent={Cell} />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default RequestItemListData
