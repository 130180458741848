import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { getSaleTypeLookupList, getTerminalLookupList } from 'app/redux/store/features/reports'
import { getBranchUserLookupList } from 'app/redux/store/features/users'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import useDebouncedInput from 'app/pages/reports/hooks/useDebouncedInput'

const DailySaleReportFilter = ({ mutation }) => {
    const dispatch = useDispatch()
    const { branchUsers } = useSelector((state) => state.users)
    const { salesTypes, terminalTypes } = useSelector((state) => state.reports)

    useEffect(() => {
        dispatch(getSaleTypeLookupList(''))
        dispatch(getBranchUserLookupList(''))
        dispatch(getTerminalLookupList(''))
    }, [dispatch])

    const branchUserLookupStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'userId',
                    data: branchUsers.data,
                    totalCount: branchUsers.length,
                }),
                reshapeOnPush: true,
            }),
        [branchUsers]
    )

    const saleTypeLookupStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'saleTransTypeId',
                    data: salesTypes.data,
                    totalCount: salesTypes.length,
                }),
                reshapeOnPush: true,
            }),
        [salesTypes]
    )

    const terminalLookupStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'terminalId',
                    data: terminalTypes.data,
                    totalCount: terminalTypes.length,
                }),
                reshapeOnPush: true,
            }),
        [terminalTypes]
    )

    let initialValues = {
        startDate: DateUtils.getUtcStartDate(10).toISOString().slice(0, 16),
        endDate: DateUtils.getUtcEndDate().toISOString().slice(0, 16),
        customerName: '',
        customerArea: '',
    }

    const [filter, setFilter] = useState({
        SaleTypeId: null,
        CheckoutById: null,
        TerminalId: null,
        ServedById: null,
    })

    const onFormSubmit = (startDate, endDate, customerName, customerArea) => {
        mutation.mutate({
            startDate: startDate.toFilterStartDateTime(),
            endDate: endDate.toFilterEndDateTime(),
            customerName: customerName,
            customerArea: customerArea,
            ...filter,
            sort: '',
        })
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit(data.startDate, data.endDate, data.customerName, data.customerArea)
        setSubmitting(false)
    }

    const handleClear = () => {
        setFilter({
            SaleTypeId: null,
            TerminalId: null,
            ServedById: null,
            CheckoutById: null,
        })
    }

    const {
        value: SaleTypeValue,
        debouncedValue: debouncedSaleTypeValue,
        onChange: onSaleTypeSearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getSaleTypeLookupList(SaleTypeValue))
    }, [debouncedSaleTypeValue])

    const onSaleTypeChanged = (value) => {
        setFilter({ ...filter, SaleTypeId: value?.saleTransTypeId })
    }

    const {
        value: TerminalNumberValue,
        debouncedValue: debouncedTerminalNumberValue,
        onChange: onTerminalNumberSearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getTerminalLookupList(TerminalNumberValue))
    }, [debouncedTerminalNumberValue])

    const onTerminalValueChanged = (value) => {
        setFilter({ ...filter, TerminalId: value?.terminalId })
    }

    const {
        value: CheckOutByValue,
        debouncedValue: debouncedCheckOutByValue,
        onChange: onCheckOutBySearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getBranchUserLookupList(CheckOutByValue))
    }, [debouncedCheckOutByValue])

    const onCheckedOutByChanged = (value) => {
        setFilter({ ...filter, CheckoutById: value?.userId })
    }

    const {
        value: ServedByValue,
        debouncedValue: debouncedServedByValue,
        onChange: onServedBySearch,
    } = useDebouncedInput('', 300)

    useEffect(() => {
        dispatch(getBranchUserLookupList(ServedByValue))
    }, [debouncedServedByValue])

    const onServedByChanged = (value) => {
        setFilter({ ...filter, ServedById: value?.userId })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ borderRadius: 0 }}>
                        <Divider />
                        <Div style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        size='small'
                                        style={{ width: 220 }}
                                        name='startDate'
                                        type='datetime-local'
                                        defaultValue={initialValues.startDate}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        size='small'
                                        style={{ width: 220 }}
                                        name='endDate'
                                        type='datetime-local'
                                        defaultValue={initialValues.endDate}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Customer Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField size='small' name='customerName' style={{ width: 220 }} />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Customer Area</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField size='small' name='customerArea' style={{ width: 220 }} />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Checkout by</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={branchUserLookupStore}
                                        displayExpr={'firstName'}
                                        value={filter.CheckoutById}
                                        searchEnabled={true}
                                        onValueChange={onCheckedOutByChanged}
                                        onInput={onCheckOutBySearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Served by</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={branchUserLookupStore}
                                        displayExpr={'firstName'}
                                        value={filter.ServedById}
                                        searchEnabled={true}
                                        onValueChange={onServedByChanged}
                                        onInput={onServedBySearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Sale Type</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={saleTypeLookupStore}
                                        displayExpr={'name'}
                                        value={filter.SaleTypeId}
                                        searchEnabled={true}
                                        onValueChange={onSaleTypeChanged}
                                        onInput={onSaleTypeSearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Terminal</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={terminalLookupStore}
                                        displayExpr={'name'}
                                        value={filter.TerminalId}
                                        searchEnabled={true}
                                        onValueChange={onTerminalValueChanged}
                                        onInput={onTerminalNumberSearch}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default DailySaleReportFilter
