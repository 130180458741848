import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useDispatch, useSelector } from 'react-redux'
import { deleteInventory, getInventoryById } from '../../../../redux/store/features/inventory'
import { useSnackbar } from 'notistack'
import { formatDateTime } from 'app/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/utils/components/FormatDateTime/NoDataCellWithLoading'
import useJumboTheme from '../../../../../@jumbo/hooks/useJumboTheme'

const InventoryDataGrid = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const { inventories, loading, error } = useSelector((state) => state.inventories)
    const dateColumns = ['modifiedDate']
    const [sorting, setSorting] = useState([{ columnName: 'inventoryNo', direction: 'asc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getInventoryById({ inventoryId: id }))
        navigate(`/store/inventory/edit/${id}`, { state: { inventoryId: id } })
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.inventoryId)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />
    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail(row.inventoryId)}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveInventory(row.inventoryId)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.inventoryId)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {row.inventoryNo}
            </Link>
        )
    }

    const hideDialogAndRefreshInventoryList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Inventory data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onRemoveInventory = async (inventoryId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteInventory({ inventoryId: inventoryId }))
                hideDialogAndRefreshInventoryList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'inventoryNo', title: 'Inv Code', getCellValue: renderLinkCell },
        { name: 'inventoryName', title: 'Name' },
        { name: 'barcode', title: 'Bar Code' },
        { name: 'totalStock', title: 'Stock' },
        { name: 'salePrice', title: 'S.Price', getCellValue: (row) => formatCurrency(row.salePrice) },
        { name: 'purchasePrice', title: 'P.Price', getCellValue: (row) => formatCurrency(row.purchasePrice) },
        { name: 'modifiedDate', title: 'Date Modified', hidden: true }, ///
        { name: 'categoryName', title: 'Category' },
        { name: 'invTypeName', title: 'Inventory Type', hidden: true }, ///
        { name: 'description', title: 'Description' },
        { name: 'supplierName', title: 'Supplier' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const visibleColumns = columns.filter((column) => !column.hidden)

    const columnWidth = useMemo(
        () => [
            { columnName: 'inventoryNo', width: min ? '100px' : '9%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'inventoryName', width: min ? '200px' : '18%', minWidth: '200px', wordWrapEnabled: true },
            { columnName: 'barcode', width: min ? '130px' : '13%', minWidth: '130px' },
            { columnName: 'totalStock', width: min ? '90px' : '8%', minWidth: '90px' },
            { columnName: 'salePrice', align: 'right', width: min ? '90px' : '9%', minWidth: '90px' },
            { columnName: 'purchasePrice', align: 'right', width: min ? '90px' : '9%', minWidth: '90px' },
            { columnName: 'categoryName', wordWrapEnabled: true, width: min ? '130px' : '13%', minWidth: '130px' },
            { columnName: 'description', wordWrapEnabled: true, width: min ? '120px' : '12%', minWidth: '120px' },
            { columnName: 'supplierName', wordWrapEnabled: true, width: min ? '110px' : '10%', minWidth: '110px' },
            { columnName: 'Action', align: 'center', width: min ? '90px' : '9%', minWidth: '90px' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={inventories.data} columns={visibleColumns}>
                <DateTypeProvider for={dateColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={inventories.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default InventoryDataGrid
