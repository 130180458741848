import React from 'react'
import ReportAppContext from './ReportAppContext'
import { useParams } from 'react-router-dom'

const ReportAppProvider = ({ children }) => {
    const { id } = useParams()

    const [activeReport, setActiveReport] = React.useState({
        id: id ? id : 'daily-sales-report-epos',
    })
    const [activeReportColorList, setActiveReportColorList] = React.useState([])
    const activeReportRef = React.useRef()
    const salesReportListRef = React.useRef()
    const inventoryReportListRef = React.useRef()
    const orderReportListRef = React.useRef()
    const otherReportListRef = React.useRef()

    const contextValue = React.useMemo(
        () => ({
            activeReport: activeReport,
            setActiveReport: setActiveReport,
            activeReportColorList: activeReportColorList,
            setColorActiveReport: setActiveReportColorList,
            activeReportRef: activeReportRef,
            salesReportListRef: salesReportListRef,
            inventoryReportListRef: inventoryReportListRef,
            orderReportListRef: orderReportListRef,
            otherReportListRef: otherReportListRef,
        }),
        [
            activeReport,
            activeReportColorList,
            activeReportRef,
            salesReportListRef,
            inventoryReportListRef,
            orderReportListRef,
            otherReportListRef,
        ]
    )

    React.useEffect(() => {
        const found = activeReportColorList.find(function (element) {
            return element.id === activeReport?.id
        })
        // Reset the list as we will be setting a new id
        const resetList = activeReportColorList.map((c, i) => {
            return { id: c.id, color: 'text.primary' }
        })

        // Add or update
        if (found) {
            const newList = resetList.map((c, i) => {
                if (c.id === activeReport?.id) {
                    return { id: c.id, color: 'error' }
                }
                return { id: c.id, color: 'text.primary' }
            })
            setActiveReportColorList(newList)
        } else {
            let newItem = { id: activeReport?.id, color: 'error' }
            let updatedItems = [...resetList, newItem]
            setActiveReportColorList(updatedItems)
        }
    }, [setActiveReportColorList, activeReport])

    return <ReportAppContext.Provider value={contextValue}>{children}</ReportAppContext.Provider>
}

export default ReportAppProvider
