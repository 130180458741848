import React from 'react'
import useAutoNumberApp from '../../hooks/useAutoNumberApp'
import ActiveAutoNumber from '../activeAutoNumber/ActiveAutoNumber'

const AutoNumberAppContent = () => {
    const { activeAutoNumberRef: activeAutoNumberRef } = useAutoNumberApp()
    return <ActiveAutoNumber ref={activeAutoNumberRef} />
}

export default AutoNumberAppContent
