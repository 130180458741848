import React from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import { TabContext, TabPanel } from '@mui/lab'
import AutoNumberList from '../autoNumberList'

const AutoNumberAppSidebar = () => {
    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'info.contrastText'} variant={'h5'}>
                    Auto Number
                </Typography>
            }
            sx={{ m: 0.5, mt: 1 }}
            headerSx={{
                pt: 3,
                height: 40,
                bgcolor: 'secondary.main',
            }}>
            <TabContext value={'AutoNumber'}>
                <JumboScrollbar
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                    className='data-grid-height'>
                    <TabPanel value={'AutoNumber'} sx={{ p: 0 }}>
                        {<AutoNumberList />}
                    </TabPanel>
                </JumboScrollbar>
            </TabContext>
        </JumboCardQuick>
    )
}
export default AutoNumberAppSidebar
