import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    config: { transferAutoDispatch: false, transferAutoReceive: false },
    transfers: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const requestSlice = createSlice({
    name: 'transfers',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getTransfer: (state, action) => {
            state.config = action.payload.config
            state.transfers = { data: action.payload.data, totalCount: action.payload.totalCount }
            state.loading = false
            state.refreshing = false
        },
        getExistingTransferById: (state, action) => {
            const index = state.transfers.data.findIndex(
                (transfer) => transfer.transferInventoryId === action.payload.transferInventoryId
            )
            if (index >= 0) state.transfers.data[index] = action.payload
            else {
                state.transfers.data.push(action.payload)
                state.transfers.totalCount++
            }
            state.loading = false
        },
        getTransferItem: (state, action) => {
            if (action.payload.data.length > 0) {
                const index = state.transfers.data.findIndex(
                    (transfer) => transfer.transferInventoryId === action.payload.data[0].fkTransferInventoryId
                )
                if (index >= 0) state.transfers.data[index].inventoryItems = action.payload
                //We cannot add item if the transfer does not exist
                else {
                    //     state.transfers.data.push(action.payload)
                    //     state.transfers.totalCount++
                }
            }
            state.loading = false
        },
        getTransferActivityLogs: (state, action) => {
            if (action.payload.data.length > 0) {
                const index = state.transfers.data.findIndex(
                    (transfer) => transfer.transferInventoryId === action.payload.data[0].fkTransferInventoryId
                )
                if (index >= 0) state.transfers.data[index].activityLogs = action.payload
                //We cannot add item if the transfer does not exist
                else {
                    //     state.transfers.data.push(action.payload)
                    //     state.transfers.totalCount++
                }
            }
            state.loading = false
        },

        addNewTransfers: (state, action) => {
            state.transfers.data.push(action.payload)
            state.transfers.totalCount++
            state.loading = false
        },
        updateExistingTransferStatus: (state, action) => {
            const index = state.transfers.data.findIndex(
                (transfer) => transfer.transferInventoryId === action.payload.transferInventoryId
            )
            if (index >= 0) {
                // We don't want to loose items when updating status
                const items = state.transfers.data[index].inventoryItems
                state.transfers.data[index] = action.payload
                state.transfers.data[index].inventoryItems = items
            }
            state.loading = false
        },

        updateExistingTransfer: (state, action) => {
            const index = state.transfers.data.findIndex(
                (transfer) => transfer.transferInventoryId === action.payload.transferInventoryId
            )
            if (index >= 0) state.transfers.data[index] = action.payload
            state.loading = false
        },
        removeTransfer: (state, action) => {
            const index = state.transfers.data.findIndex(
                (transfer) => transfer.transferInventoryId === action.payload.transferInventoryId
            )
            if (index >= 0) {
                state.transfers.data.splice(index, 1)
                state.transfers.totalCount--
            }
            state.loading = false
        },
        updateTransferItemList: (state, action) => {
            const index = state.transfers.data.findIndex(
                (item) => item.transferInventoryId === action.payload.transferInventoryId
            )
            if (index >= 0) {
                state.transfers.data[index].inventoryItems = action.payload.inventoryItems
            }
            //We cannot add item if the transaction does not exist
            else {
                //     state.transactions.data.push(action.payload)
                //     state.transactions.totalCount++
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.transferInventoryId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getTransfer,
    getExistingTransferById,
    getTransferItem,
    getTransferActivityLogs,
    addNewTransfers,
    updateExistingTransfer,
    removeTransfer,
    updateTransferItemList,
    updateExistingTransferStatus,
} = requestSlice.actions
export default requestSlice.reducer

export const getTransferList = (filter, refresh = false) => {
    let url = `/transfer/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getTransfer.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransferById = (filter) => {
    let url = `/transfer/${filter.transferInventoryId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingTransferById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransferItemsList = (filter) => {
    let url = `/transfer/${filter.transferInventoryId}/items`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransferItem.type,
        onError: apiRequestedFailed.type,
    })
}

export const getTransferAuditLogsList = (filter) => {
    let url = `/transfer/${filter.transferInventoryId}/activity`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTransferActivityLogs.type,
        onError: apiRequestedFailed.type,
    })
}

export const addTransfers = (transfer) => {
    let url = `/transfer/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: transfer,
        onStart: apiRequested.type,
        onSuccess: addNewTransfers.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateTransferStatus = (transfer) => {
    let url = `/transfer/update/status`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: transfer,
        onStart: apiRequested.type,
        onSuccess: updateExistingTransferStatus.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateTransfer = (transfer) => {
    let url = `/transfer/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: transfer,
        onStart: apiRequested.type,
        onSuccess: updateExistingTransfer.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteTransfer = (transfer) => {
    let url = '/transfer/remove'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: transfer,
        onStart: apiRequested.type,
        onSuccess: removeTransfer.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateTransferItems = (itemList) => {
    let url = `/transfer/update/items`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: itemList,
        onStart: apiRequested.type,
        onSuccess: updateTransferItemList.type,
        onError: apiRequestedFailed.type,
    })
}
