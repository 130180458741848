const reportServices = {}

reportServices.getSalesReportList = async (params) => {
    let reports = [
        {
            id: 'daily-sales-report-epos',
            name: 'Daily Sales  Report',
            category: 'sales',
            branchType: 'epos',
            description: 'This report contains sales summary info',
        },
        {
            id: 'daily-sales-summary-report-epos',
            name: 'Daily Sales Summary Report',
            category: 'sales',
            branchType: 'epos',
            description: 'This report contains sales summary info',
        },
        {
            id: 'customer-receivable-report-epos',
            name: 'Customer Receivable Report',
            category: 'sales',
            branchType: 'epos',
            description: 'This report includes list of all reports',
        },
        {
            id: 'customer-receivable-detail-report-epos',
            name: 'Customer Receivable Detail Report',
            category: 'sales',
            branchType: 'epos',
            description: 'This report includes list of all reports',
        },
        {
            id: 'sale-profit-loss-report-epos',
            name: 'Sale Profit Loss Report',
            category: 'sales',
            branchType: 'epos',
            description: 'This report includes list of all reports',
        },
        {
            id: 'sale-detail-report-epos',
            name: 'Sale Detail Report',
            category: 'sales',
            branchType: 'epos',
            description: 'This report includes list of all reports',
        },
        {
            id: 'daily-sales-report-spiral',
            name: 'Daily Sales  Report',
            category: 'sales',
            branchType: 'spiral',
            description: 'This report contains sales summary info',
        },
        {
            id: 'daily-sales-summary-report-spiral',
            name: 'Daily Sales Summary Report',
            category: 'sales',
            branchType: 'spiral',
            description: 'This report contains sales summary info',
        },
        {
            id: 'sale-detail-report-spiral',
            name: 'Sale Detail Report',
            category: 'sales',
            branchType: 'spiral',
            description: 'This report includes list of all reports',
        },
    ]
    return { reports: reports.filter((f) => f.branchType === params.queryKey[1].queryParams.branchType) }
}

reportServices.getOrderReportList = async (params) => {
    let reports = [
        {
            id: 'order-list-report-epos',
            name: 'Order List Report',
            category: 'order',
            branchType: 'epos',
            description: 'This report includes order list information',
        },
        {
            id: 'supplier-account-report-epos',
            name: 'Supplier Account Report',
            category: 'order',
            branchType: 'epos',
            description: 'This report includes supplier account information',
        },
    ]
    return { reports: reports.filter((f) => f.branchType === params.queryKey[1].queryParams.branchType) }
}

reportServices.getInventoryReportList = async (params) => {
    let reports = [
        {
            id: 'inventory-list-report-epos',
            name: 'Inventory List Report',
            category: 'inventory',
            branchType: 'epos',
            description: 'This report includes purchase order summary information',
        },
        {
            id: 'inventory-load-form-report-epos',
            name: 'Inventory Load Form Report',
            category: 'inventory',
            branchType: 'epos',
            description: 'This report includes purchase order summary information',
        },
    ]
    return { reports: reports.filter((f) => f.branchType === params.queryKey[1].queryParams.branchType) }
}

reportServices.getCashReportList = async (params) => {
    let reports = [
        {
            id: 'daily-cash-detail-report-spiral',
            name: 'Daily Cash Detail Report',
            category: 'cash',
            branchType: 'spiral',
            description: 'This report includes daily-cash-detail-report-spiral',
        },
        {
            id: 'daily-cash-detail-report-epos',
            name: 'Daily Cash Detail Report',
            category: 'cash',
            branchType: 'epos',
            description: 'This report includes daily-cash-detail-report-epos',
        },
        {
            id: 'cash-register-report-epos',
            name: 'Cash Register Report',
            category: 'cash',
            branchType: 'epos',
            description: 'This report includes cash-register-report-epos',
        },
    ]
    return { reports: reports.filter((f) => f.branchType === params.queryKey[1].queryParams.branchType) }
}
export default reportServices
