import React from 'react'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboRqList from '@jumbo/components/JumboReactQuery/JumboRqList/JumboRqList'
import useAutoNumberApp from '../../hooks/useAutoNumberApp'
import AutoNumberViewItem from '../AutoNumberViewItem/AutoNumberViewItem'
import autoNumberServices from '../../../../../../../services/pages/store/settings/autoNumberServices'

const AutoNumberList = () => {
    const { AutoNumberListRef } = useAutoNumberApp()

    const renderAutoNumberView = React.useCallback((AutoNumberItem) => {
        return <AutoNumberViewItem autoNumberViewItem={AutoNumberItem} />
    })

    return (
        <React.Fragment>
            <JumboCardQuick>
                <JumboRqList
                    ref={AutoNumberListRef}
                    service={autoNumberServices.getAutoNumberList}
                    primaryKey={'id'}
                    queryOptions={{
                        queryKey: 'auto-number-list',
                        dataKey: 'autoNumber',
                    }}
                    renderItem={renderAutoNumberView}
                    componentElement={'div'}
                />
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AutoNumberList
