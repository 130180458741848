import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'
import { getCategoryLookup, getExistingCategoryById, getInventories } from './inventory'

const initialState = {
    chartTaxCodes: { data: [], totalCount: 0 },
    chartTaxCodesLookup: { data: [], totalCount: 0 },
    chartOfAccounts: { data: [], totalCount: 0 },
    chartOfAccountsLookup: { data: [], totalCount: 0 },
    uoms: { data: [], totalCount: 0 },
    uomsLookup: { data: [], totalCount: 0 },
    inventoryTypes: { data: [], totalCount: 0 },
    inventoryTypesLookup: { data: [], totalCount: 0 },
    inventoryCategories: { data: [], totalCount: 0 },
    inventoryCategoriesLookup: { data: [], totalCount: 0 },
    inventorySubCategories: { data: [], totalCount: 0 },
    inventorySubCategoriesLookup: { data: [], totalCount: 0 },
    stockStorages: { data: [], totalCount: 0 },
    stockStoragesLookup: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const definitionSlice = createSlice({
    name: 'definitions',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getChartOfTaxCodes: (state, action) => {
            state.chartTaxCodes = action.payload
            state.loading = false
            state.refreshing = false
        },
        getChartOfTaxCodesLookup: (state, action) => {
            state.chartTaxCodesLookup = action.payload
            state.loading = false
        },
        getExistingChartOfTaxCodesLookupById: (state, action) => {
            const index = state.chartTaxCodesLookup.data.findIndex(
                (definition) => definition.chartTaxId === action.payload.chartTaxId
            )
            if (index >= 0) state.chartTaxCodesLookup.data[index] = action.payload
            else {
                state.chartTaxCodesLookup.data.push(action.payload)
                state.chartTaxCodesLookup.totalCount++
            }
            state.loading = false
        },
        getChartOfAccounts: (state, action) => {
            state.chartOfAccounts = action.payload
            state.loading = false
            state.refreshing = false
        },
        getChartOfAccountsLookup: (state, action) => {
            state.chartOfAccountsLookup = action.payload
            state.loading = false
        },
        getExistingChartOfAccountsLookupById: (state, action) => {
            const index = state.chartOfAccountsLookup.data.findIndex(
                (definition) => definition.ChartAccountId === action.payload.ChartAccountId
            )
            if (index >= 0) state.chartOfAccountsLookup.data[index] = action.payload
            else {
                state.chartOfAccountsLookup.data.push(action.payload)
                state.chartOfAccountsLookup.totalCount++
            }
            state.loading = false
        },
        getUoms: (state, action) => {
            state.uoms = action.payload
            state.loading = false
            state.refreshing = false
        },
        getUomsLookup: (state, action) => {
            state.uomsLookup = action.payload
            state.loading = false
        },
        getExistingUomsLookupById: (state, action) => {
            const index = state.uomsLookup.data.findIndex((definition) => definition.uomId === action.payload.uomId)
            if (index >= 0) state.uomsLookup.data[index] = action.payload
            else {
                state.uomsLookup.data.push(action.payload)
                state.uomsLookup.totalCount++
            }
            state.loading = false
        },
        getInventoryTypes: (state, action) => {
            state.inventoryTypes = action.payload
            state.loading = false
            state.refreshing = false
        },
        getInventoryTypesLookup: (state, action) => {
            state.inventoryTypesLookup = action.payload
            state.loading = false
        },
        getExistingInventoryTypesLookupById: (state, action) => {
            const index = state.inventoryTypesLookup.data.findIndex(
                (definition) => definition.invTypeId === action.payload.invTypeId
            )
            if (index >= 0) state.inventoryTypesLookup.data[index] = action.payload
            else {
                state.inventoryTypesLookup.data.push(action.payload)
                state.inventoryTypesLookup.totalCount++
            }
            state.loading = false
        },
        getInventoryCategories: (state, action) => {
            state.inventoryCategories = action.payload
            state.loading = false
            state.refreshing = false
        },
        getInventoryCategoriesLookup: (state, action) => {
            state.inventoryCategoriesLookup = action.payload
            state.loading = false
        },
        getExistingInventoryCategoriesLookupById: (state, action) => {
            const index = state.inventoryCategoriesLookup.data.findIndex(
                (definition) => definition.inventoryCategoryId === action.payload.inventoryCategoryId
            )
            if (index >= 0) state.inventoryCategoriesLookup.data[index] = action.payload
            else {
                state.inventoryCategoriesLookup.data.push(action.payload)
                state.inventoryCategoriesLookup.totalCount++
            }
            state.loading = false
        },
        getInventorySubCategories: (state, action) => {
            state.inventorySubCategories = action.payload
            state.loading = false
            state.refreshing = false
        },
        getInventorySubCategoriesLookup: (state, action) => {
            state.inventorySubCategoriesLookup = action.payload
            state.loading = false
        },
        getExistingInventorySubCategoriesLookupById: (state, action) => {
            const index = state.inventorySubCategoriesLookup.data.findIndex(
                (definition) => definition.invSubCategoryId === action.payload.invSubCategoryId
            )
            if (index >= 0) state.inventorySubCategoriesLookup.data[index] = action.payload
            else {
                state.inventorySubCategoriesLookup.data.push(action.payload)
                state.inventorySubCategoriesLookup.totalCount++
            }
            state.loading = false
        },
        getStockStorages: (state, action) => {
            state.stockStorages = action.payload
            state.loading = false
            state.refreshing = false
        },
        getStockStoragesLookup: (state, action) => {
            state.stockStoragesLookup = action.payload
            state.loading = false
        },
        getExistingStockStoragesLookupById: (state, action) => {
            const index = state.stockStoragesLookup.data.findIndex(
                (definition) => definition.stockStorageId === action.payload.stockStorageId
            )
            if (index >= 0) state.stockStoragesLookup.data[index] = action.payload
            else {
                state.stockStoragesLookup.data.push(action.payload)
                state.stockStoragesLookup.totalCount++
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.definitionId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    apiRequested,
    apiRequestedFailed,
    refreshRequested,
    getChartOfTaxCodes,
    getChartOfTaxCodesLookup,
    getExistingChartOfTaxCodesLookupById,
    getChartOfAccounts,
    getChartOfAccountsLookup,
    getExistingChartOfAccountsLookupById,
    getUoms,
    getUomsLookup,
    getExistingUomsLookupById,
    getInventoryTypes,
    getInventoryTypesLookup,
    getExistingInventoryTypesLookupById,
    getInventoryCategories,
    getInventoryCategoriesLookup,
    getExistingInventoryCategoriesLookupById,
    getInventorySubCategories,
    getInventorySubCategoriesLookup,
    getExistingInventorySubCategoriesLookupById,
    getStockStorages,
    getStockStoragesLookup,
    getExistingStockStoragesLookupById,
} = definitionSlice.actions
export default definitionSlice.reducer

export const getChartOfTaxCodesList = (filter, refresh = false) => {
    let url = `/Accounting/charttax/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getChartOfTaxCodes.type,
        onError: apiRequestedFailed.type,
    })
}
export const getChartOfTaxCodesLookupList = (filter) => {
    let url = filter?.taxName
        ? `/Accounting/charttax/lookup/list?search=${filter.taxName}`
        : `/Accounting/charttax/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getChartOfTaxCodesLookup.type,
        onError: apiRequestedFailed.type,
    })
}
export const getChartOfTaxCodesLookupById = (filter) => {
    let url = `/Accounting/charttax/lookup/${filter.taxName}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingChartOfTaxCodesLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getChartOfAccountsList = (filter, refresh = false) => {
    let url = `/Accounting/chartofaccounts/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getChartOfAccounts.type,
        onError: apiRequestedFailed.type,
    })
}
export const getChartOfAccountsLookupList = (filter) => {
    let url = filter?.accountName
        ? `/Accounting/chartofaccounts/lookup/list?search=${filter.accountName}`
        : `Accounting/chartofaccounts/lookup/list`

    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getChartOfAccountsLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getChartOfAccountsLookupById = (filter) => {
    let url = `/Accounting/chartofaccounts/lookup/${filter.accountName}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingChartOfAccountsLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUomsList = (filter, refresh = false) => {
    let url = `/Inventory/uom/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getUoms.type,
        onError: apiRequestedFailed.type,
    })
}
export const getUomsLookupList = (filter) => {
    let url = filter?.uomName ? `/Inventory/uom/lookup/list?search=${filter.uomName}` : `/Inventory/uom/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getUomsLookup.type,
        onError: apiRequestedFailed.type,
    })
}
export const getUomsLookupById = (filter) => {
    let url = `/Inventory/uom/${filter.uomName}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingUomsLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInventoryTypesList = (filter, refresh = false) => {
    let url = `/Inventory/type/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getInventoryTypes.type,
        onError: apiRequestedFailed.type,
    })
}
export const getInventoryTypesLookupList = (filter) => {
    let url = filter?.typeName ? `/Inventory/type/lookup/list?search=${filter.typeName}` : `Inventory/type/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getInventoryTypesLookup.type,
        onError: apiRequestedFailed.type,
    })
}
export const getInventoryTypesLookupById = (filter) => {
    let url = `/Inventory/type/${filter.typeNam}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingInventoryTypesLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInventoryCategoriesList = (filter, refresh = false) => {
    let url = `/Inventory/category/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getInventoryCategories.type,
        onError: apiRequestedFailed.type,
    })
}
export const getInventoryCategoriesLookupList = (filter) => {
    let url = filter?.categoryName
        ? `/Inventory/category/lookup/list?search=${filter.categoryName}`
        : `/Inventory/category/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getInventoryCategoriesLookup.type,
        onError: apiRequestedFailed.type,
    })
}
export const getInventoryCategoriesLookupById = (filter) => {
    let url = `/Inventory/category/lookup/${filter.categoryId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingInventoryCategoriesLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getInventorySubCategoriesList = (filter, refresh = false) => {
    let url = `/Inventory/subcategory/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getInventorySubCategories.type,
        onError: apiRequestedFailed.type,
    })
}
export const getInventorySubCategoriesLookupList = (filter) => {
    let url = filter?.subCategoryName
        ? `/Inventory/subcategory/lookup/list?search=${filter.subCategoryName}`
        : `/Inventory/subcategory/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getInventorySubCategoriesLookup.type,
        onError: apiRequestedFailed.type,
    })
}
export const getInventorySubCategoriesLookupById = (filter) => {
    let url = `/Inventory/subcategory/lookup/${filter.subCategoryId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingInventorySubCategoriesLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getStockStoragesList = (filter, refresh = false) => {
    let url = `/Inventory/stockstorages/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getStockStorages.type,
        onError: apiRequestedFailed.type,
    })
}
export const getStockStoragesLookupList = (filter) => {
    let url = filter?.storageName
        ? `/Inventory/stockstorages/lookup/list?search=${filter.storageName}`
        : `/Inventory/stockstorages/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getStockStoragesLookup.type,
        onError: apiRequestedFailed.type,
    })
}
export const getStockStoragesLookupById = (filter) => {
    let url = `/Inventory/stockstorages/lookup/${filter.stockStorageId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingStockStoragesLookupById.type,
        onError: apiRequestedFailed.type,
    })
}
