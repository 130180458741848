import React from 'react'
import useDefinitionApp from '../../hooks/useDefinitionApp'
import ActiveDefinition from '../ActiveDefinition/ActiveDefinition'

const DefinitionAppContent = () => {
    const { activeDefinitionRef: activeDefinitionRef } = useDefinitionApp()
    return <ActiveDefinition ref={activeDefinitionRef} />
}

export default DefinitionAppContent
