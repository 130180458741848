import React, { useCallback, useMemo, useState } from 'react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import transactionServices from 'app/services/pages/store/Sales/transactionServices'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { SelectBox } from 'devextreme-react'
import { useNavigate } from 'react-router-dom'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'

const AllTransactionsGridFilter = ({ mutation, display, filterIconColor }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    let initialValues = {
        txnStartDate: DateUtils.getStartDate(10).toISOString().slice(0, 10),
        txnEndDate: DateUtils.getEndDate().toISOString().slice(0, 10),
        customerFirstName: '',
        customerLastName: '',
        onlineTransactions: false,
    }

    const [filter, setFilter] = useState({ posTxId: null, sort: 'TxnStartDate', isDescending: true })

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const transactionLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await transactionServices.getTransactionLookupList({
                    txnNumber: searchTerm,
                    onlineTransactions: initialValues.onlineTransactions,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [transactionServices]
    )

    const transactionLookupById = useCallback(
        async (key) => {
            try {
                return await transactionServices.getTransactionLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [transactionServices]
    )

    const transactionLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'posTxId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await transactionLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return transactionLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = ({ ...filter }, txnStartDate, txnEndDate, customerFirstName, customerLastName) => {
        mutation.mutate({
            ...filter,
            txnStartDate: txnStartDate.toFilterStartDate(),
            txnEndDate: txnEndDate.toFilterEndDate(),
            customerFirstName: customerFirstName,
            customerLastName: customerLastName,
            onlineTransactions: false,
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(
            { ...filter },
            data.txnStartDate,
            data.txnEndDate,
            data.customerFirstName,
            data.customerLastName
        )
        setSubmitting(false)
    }

    const handleClear = () => {
        filterIconColor(false)
        setFilter({ posTxId: null })
    }

    const onTransactionChanged = (value) => {
        setFilter({ ...filter, posTxId: value?.posTxId })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick
                        noWrapper
                        id='filterBody'
                        variant={'primary'}
                        sx={{ display: display, borderRadius: 0 }}>
                        <Divider />
                        <Div style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='txnStartDate'
                                        type='date'
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='txnEndDate'
                                        type='date'
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Txn Number</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={transactionLookupStore}
                                        value={filter.posTxId}
                                        displayExpr={'txnNumber'}
                                        searchEnabled={true}
                                        onValueChange={onTransactionChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>First Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='customerFirstName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Last Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='customerLastName' />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default AllTransactionsGridFilter
