import * as actions from '../actions/api';
import jwtAuthAxios from "../../../services/auth/jwtAuth";

const api = ({ dispatch }) => (next) => async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const { url, method, data, onStart, onSuccess, onError } = action.payload;

    if (onStart) dispatch({ type: onStart });
    next(action);

    try {
        const response = await jwtAuthAxios.request({
            url,
            method,
            data,
        });
        
        dispatch(actions.apiCallSuccess(response.data));

        if (onSuccess)
            dispatch({ type: onSuccess, payload: response.data });
        
    } catch (error) {
        const responseMsg = (error.response?.data) ?
            {
                status: error.response.status,
                detail: error.response.data.detail
            }
            :
            {
                status: 400,
                detail: error.message
            };
        dispatch(actions.apiCallFailed(responseMsg));

        if (onError) dispatch({ type: onError, payload: responseMsg });
    }
};

export default api;