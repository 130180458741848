import React from 'react'
import DefinitionAppProvider from './defintionLayout/DefinitionAppProvider'
import DefinitionAppLayout from './defintionLayout/DefinitionAppLayout'

const DefinitionApp = () => {
    return (
        <div>
            <DefinitionAppProvider>
                <DefinitionAppLayout />
            </DefinitionAppProvider>
        </div>
    )
}

export default DefinitionApp
