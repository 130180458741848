import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    companies: { data: [], totalCount: 0 },
    companyLookups: { data: [], totalCount: 0 },
    companyUsers: { data: [], totalCount: 0 },
    companyConfig: { companyId: null },
    hostingLookups: [],
    loading: false,
    error: null,
    refreshing: false,
}

const companySlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        getCompanyConfig: (state, action) => {
            state.companyConfig = action.payload
        },
        updateCompanyConfig: (state, action) => {
            state.companyConfig = action.payload
        },
        getCompanies: (state, action) => {
            state.companies = action.payload
            state.loading = false
            state.refreshing = false
        },
        getCompanyLookup: (state, action) => {
            state.companyLookups = action.payload
            state.loading = false
        },
        getExistingCompanyLookupById: (state, action) => {
            const index = state.companyLookups.data.findIndex(
                (company) => company.companyId === action.payload.companyId
            )
            if (index >= 0) state.companyLookups.data[index] = action.payload
            else {
                state.companyLookups.data.push(action.payload)
                state.companyLookups.totalCount++
            }
            state.loading = false
        },
        getExistingCompanyById: (state, action) => {
            const index = state.companies.data.findIndex((company) => company.companyId === action.payload.companyId)
            if (index >= 0) state.companies.data[index] = action.payload
            else {
                state.companies.data.push(action.payload)
                state.companies.totalCount++
            }
            state.loading = false
        },
        addNewCompany: (state, action) => {
            state.companies.data.push(action.payload)
            state.companies.totalCount++
            state.loading = false
        },
        updateExistingCompany: (state, action) => {
            const index = state.companies.data.findIndex((company) => company.companyId === action.payload.companyId)
            if (index >= 0) state.companies.data[index] = action.payload
            state.loading = false
        },
        removeCompany: (state, action) => {
            const index = state.companies.data.findIndex((company) => company.companyId === action.payload.companyId)
            if (index >= 0) {
                state.companies.data.splice(index, 1)
                state.companies.totalCount--
            }
            state.loading = false
        },

        getCompanyUsers: (state, action) => {
            state.companyUsers = action.payload
            state.loading = false
        },

        getHostingLookup: (state, action) => {
            state.hostingLookups = action.payload
            state.loading = false
        },
        getExistingHostingLookupById: (state, action) => {
            const index = state.hostingLookups.findIndex(
                (hosting) => hosting.hostingOptionId === action.payload.hostingOptionId
            )
            if (index >= 0) state.hostingLookups[index] = action.payload
            else {
                state.hostingLookups.push(action.payload)
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.companyId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getCompanyConfig,
    updateCompanyConfig,
    getCompanies,
    getCompanyLookup,
    getExistingCompanyLookupById,
    getExistingCompanyById,
    addNewCompany,
    updateExistingCompany,
    removeCompany,
    getCompanyUsers,
    getHostingLookup,
    getExistingHostingLookupById,
} = companySlice.actions
export default companySlice.reducer

export const getCompanyList = (filter, refresh = false) => {
    let url = `/Company/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getCompanies.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCompanyLookupList = (filter) => {
    let url = `/Company/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCompanyLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCompanyLookupById = (filter) => {
    let url = `/Company/lookup/${filter.companyId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingCompanyLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCompanyById = (filter) => {
    let url = `/Company/${filter.companyId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingCompanyById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addCompany = (company) => {
    let url = `/Company/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: company,
        onStart: apiRequested.type,
        onSuccess: addNewCompany.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateCompany = (company) => {
    let url = `/Company/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: company,
        onStart: apiRequested.type,
        onSuccess: updateExistingCompany.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteCompany = (company) => {
    let url = '/Company/remove'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: company,
        onStart: apiRequested.type,
        onSuccess: removeCompany.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCompanyUserList = (filter) => {
    let url = `/Company/${filter.companyId}/users/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCompanyUsers.type,
        onError: apiRequestedFailed.type,
    })
}

export const getHostingLookupList = (filter) => {
    let url = `/Hosting/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getHostingLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getHostingLookupById = (filter) => {
    let url = `/Company/Hosting/${filter.hostingOptionId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingHostingLookupById.type,
        onError: apiRequestedFailed.type,
    })
}
