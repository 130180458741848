let StockOptionService = {}

StockOptionService.StockOptionList = [
    { id: '69F3B433-F661-41D1-A394-23D06870D828', stockOptionId: 'Unit Stock Qty' },
    { id: 'E9AD3151-035B-4664-8772-0BDC6C9A7357', stockOptionId: 'Box Stock Qty' },
]

StockOptionService.getStockOptionInfo = () => {
    return StockOptionService.StockOptionList
}

StockOptionService.getStockOptionInfoData = (row) => {
    const stockOptionId = StockOptionService.StockOptionList.find((item) => item.id === row.stockOptionId)
    return stockOptionId ? stockOptionId.stockOptionId : ''
}

export default StockOptionService
