import React from 'react'
import { Grid } from '@mui/material'
import TransferLeftInfoCard from './TransferLeftInfoCard'
import TransferRightInfoCard from './TransferRightInfoCard'

const TransfersInfoBar = ({ transfer }) => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} sm={12} lg={6}>
                <TransferLeftInfoCard transfer={transfer} />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
                <TransferRightInfoCard transfer={transfer} />
            </Grid>
        </Grid>
    )
}

export default TransfersInfoBar
