import React from 'react'
import TransferTimeLineList from './TransferTimeLineList'

const TransferTimeLineCard = ({ statusList, transfer }) => {
    return (
        <React.Fragment>
            <TransferTimeLineList statusList={statusList} transfer={transfer} />
        </React.Fragment>
    )
}

export default TransferTimeLineCard
