import React from 'react'
import UserGrid from 'app/pages/security/users/UserGrid'
import Permissions from '../pages/security/permissions'
import Page from '@jumbo/shared/Page'
import UpdateUser from 'app/pages/security/users/components/UpdateUser'
import AddUser from 'app/pages/security/users/components/AddUser'

const securityRoutes = [
    {
        path: '/security/users',
        element: <Page component={UserGrid} />,
    },
    {
        path: '/security/users/edit/:userId',
        element: <Page component={UpdateUser} />,
    },
    {
        path: '/security/users/addUser',
        element: <Page component={AddUser} />,
    },
    {
        path: ['/security/permissions', '/security/permissions/:userTypeName'],
        element: <Page component={Permissions} />,
    },
]

export default securityRoutes
