import React, { useState } from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import { ListItemText, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { useNavigate } from 'react-router-dom'
import useDefinitionApp from '../../hooks/useDefinitionApp'

const DefinitionViewItem = ({ definitionViewItem: definitionViewItem }) => {
    const navigate = useNavigate()
    const { setActiveDefinition, activeDefinitionColorList } = useDefinitionApp()
    const [activeDefinitionColor, setActiveDefinitionColor] = useState('text.secondary')

    const handleDefinitionItemClick = () => {
        setActiveDefinition({
            category: definitionViewItem?.category,
            id: definitionViewItem?.id,
        })

        navigate(`/store/definitions/${definitionViewItem?.category}/${definitionViewItem?.id}`)
    }

    React.useEffect(() => {
        activeDefinitionColorList.map((item) => {
            if (item.id === definitionViewItem.id) {
                setActiveDefinitionColor(item.color)
            }
        })
    }, [activeDefinitionColorList])

    return (
        <List>
            <ListItemButton
                onClick={handleDefinitionItemClick}
                component='li'
                sx={{
                    borderRadius: 2,
                    borderLeft: 2,
                    bgcolor: activeDefinitionColor == 'text.secondary' ? 'common.white' : 'background.default',
                    borderColor: activeDefinitionColor == 'text.secondary' ? 'common.white' : activeDefinitionColor,
                }}>
                <ListItemText
                    primary={
                        <Typography variant='h6' mb={0.5} fontSize={15} color={activeDefinitionColor}>
                            {definitionViewItem?.name}
                        </Typography>
                    }
                />
            </ListItemButton>
            <Divider component='li' />
        </List>
    )
}
export default DefinitionViewItem
