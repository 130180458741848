import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddTransferForm from './AddTransferForm'
import { defaultTransfer } from './defaultTransfer'
import { useSnackbar } from 'notistack'
import requestServices from '../../../../../../services/pages/company/requestServices/requestServices'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from '../../../../../../services/util-services'
import { formatDate } from '../../../../../../utils/components/FormatDateTime/FormatDateTime'
import companyAndBranchServices from '../../../../../../services/settings/company/companyAndBranchServices'
import useApp from '../../../../../../hooks/useApp'
import { addTransfers } from '../../../../../../redux/store/features/transfers'
import { useDispatch, useSelector } from 'react-redux'

const AddTransfer = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { companySelection } = useApp()

    const { loading, error, config } = useSelector((state) => state.transfers)
    const [transferData, setTransferData] = useState({
        ...defaultTransfer,
        transferAutoDispatch: config.transferAutoDispatch,
        transferAutoReceive: config.transferAutoReceive,
    })
    const [fkSecondBranchId, setFkSecondBranchId] = useState(transferData?.fkSecondBranchId)
    const [fkTransferRequestId, setFkTransferRequestId] = useState(transferData?.fkTransferRequestId)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Transfer added successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/company/transfers/inventories')
        }
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const transferRequestLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await requestServices.getTransferRequestLookupList({
                    receivingBranchId: fkSecondBranchId,
                    search: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [fkSecondBranchId]
    )

    const transferRequestLookupById = useCallback(async (key) => {
        try {
            return await requestServices.getTransferRequestLookupById(key, {})
        } catch (error) {
            handleError(error)
        }
    }, [])

    const transferRequestLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'transferRequestId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await transferRequestLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await transferRequestLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        [fkSecondBranchId]
    )

    const onRequestValueChanged = useCallback(
        (e) => {
            setFkTransferRequestId(e.value)
        },
        [fkTransferRequestId]
    )

    const transferRequestEditorOptions = {
        dataSource: transferRequestLookupStore,
        valueExpr: 'transferRequestId',
        itemTemplate: function (data) {
            return data
                ? data.transferRequestNo + ', ' + data.sourceBranchName + ', ' + formatDate(data.transferRequestDate)
                : ''
        },
        displayExpr: function (data) {
            return data
                ? data.transferRequestNo + ', ' + data.sourceBranchName + ', ' + formatDate(data.transferRequestDate)
                : ''
        },
        searchEnabled: true,
        showClearButton: true,
        value: fkTransferRequestId,
        onValueChanged: onRequestValueChanged,
    }

    const branchLookupList = useCallback(async (searchTerm) => {
        try {
            return await companyAndBranchServices.getBranchLookupList({
                companyId: companySelection?.companyId,
                branchName: searchTerm,
            })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const branchLookupById = useCallback(async (key) => {
        try {
            return await companyAndBranchServices.getBranchById(companySelection?.companyId, key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const branchLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'branchId',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await branchLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await branchLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onBranchValueChanged = useCallback(
        (e) => {
            setFkSecondBranchId(e.value)
            setFkTransferRequestId(null)
            setTransferData({ ...transferData, fkTransferRequestId: null })
        },
        [fkSecondBranchId]
    )

    const branchEditorOptions = {
        dataSource: branchLookupStore,
        displayExpr: 'branchName',
        valueExpr: 'branchId',
        searchEnabled: true,
        showClearButton: true,
        value: fkSecondBranchId,
        onValueChanged: onBranchValueChanged,
    }

    const onAddTransfer = useCallback(() => {
        setApiCallInProgress(true)
        dispatch(
            addTransfers({
                ...transferData,
                fkTransferRequestId: fkTransferRequestId,
                fkSecondBranchId: fkSecondBranchId,
            })
        )
    }, [fkSecondBranchId, fkTransferRequestId, transferData])

    return (
        <React.Fragment>
            <AddTransferForm
                transferData={transferData}
                onAddTransfer={onAddTransfer}
                transferRequestEditorOptions={transferRequestEditorOptions}
                branchEditorOptions={branchEditorOptions}
            />
        </React.Fragment>
    )
}

export default AddTransfer
