import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import utilServices from 'app/services/util-services'
import { SelectBox } from 'devextreme-react'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import transferStatusService from './Services/TransferStatusService'

const TransferRequestGridFilter = ({ mutation, display, filterIconColor }) => {
    let initialValues = {
        startDate: DateUtils.getStartDate(10).toISOString().slice(0, 10),
        endDate: DateUtils.getEndDate().toISOString().slice(0, 10),
        transferRequestNo: '',
        barcode: '',
    }

    const [selectedStatus, setSelectedStatus] = useState('')

    const onFormSubmit = (startDate, endDate, transferRequestNo, barcode) => {
        mutation.mutate({
            startDate: startDate.toFilterStartDate(),
            endDate: endDate.toFilterEndDate(),
            transferRequestNo: transferRequestNo,
            barcode: barcode,
            transferStatus: utilServices.isNullOrUndefined(selectedStatus) ? null : selectedStatus.id,
        })
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit(data.startDate, data.endDate, data.transferRequestNo, data.barcode)
        setSubmitting(false)
    }

    const handleClear = () => {
        filterIconColor(false)
        setSelectedStatus('')
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick
                        noWrapper
                        id='filterBody'
                        variant={'primary'}
                        sx={{ display: display, borderRadius: 0 }}>
                        <Divider />
                        <Div style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='startDate'
                                        type='date'
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='endDate' type='date' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Status</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={transferStatusService.getTransferStatus()}
                                        displayExpr={'transferStatus'}
                                        searchEnabled={true}
                                        onValueChange={setSelectedStatus}
                                        placeholder='All'
                                        showClearButton={true}
                                        value={selectedStatus}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Request No</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='transferRequestNo' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Bar Code</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='barcode' />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default TransferRequestGridFilter
