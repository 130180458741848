import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    requests: { data: [], totalCount: 0 },
    requestLookups: { data: [], totalCount: 0 },
    loading: false,
    error: null,
    refreshing: false,
}

const requestSlice = createSlice({
    name: 'requests',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getRequests: (state, action) => {
            state.requests = action.payload
            state.loading = false
            state.refreshing = false
        },
        getExistingRequestById: (state, action) => {
            const index = state.requests.data.findIndex(
                (request) => request.transferRequestId === action.payload.transferRequestId
            )
            if (index >= 0) state.requests.data[index] = action.payload
            else {
                state.requests.data.push(action.payload)
                state.requests.totalCount++
            }
            state.loading = false
        },
        getRequestItems: (state, action) => {
            if (action.payload.data.length > 0) {
                const index = state.requests.data.findIndex(
                    (request) => request.transferRequestId === action.payload.data[0].fkTransferRequestId
                )
                if (index >= 0) state.requests.data[index].requestItems = action.payload
                //We cannot add item if the request does not exist
                else {
                    //     state.requests.data.push(action.payload)
                    //     state.requests.totalCount++
                }
            }
            state.loading = false
        },
        getRequestActivityLogs: (state, action) => {
            if (action.payload.data.length > 0) {
                const index = state.requests.data.findIndex(
                    (request) => request.transferRequestId === action.payload.data[0].fkTransferRequestId
                )
                if (index >= 0) state.requests.data[index].activityLogs = action.payload
                //We cannot add item if the request does not exist
                else {
                    //     state.requests.data.push(action.payload)
                    //     state.requests.totalCount++
                }
            }
            state.loading = false
        },
        getRequestLookup: (state, action) => {
            state.requestLookups = action.payload
            state.loading = false
        },
        updateExistingTransferRequestStatus: (state, action) => {
            const index = state.requests.data.findIndex(
                (request) => request.transferRequestId === action.payload.transferRequestId
            )
            if (index >= 0) {
                // We don't want to loose items when updating status
                const items = state.requests.data[index].requestItems
                state.requests.data[index] = action.payload
                state.requests.data[index].requestItems = items
            }
            state.loading = false
        },

        // addNewRequest: (state, action) => {
        //     state.requests.data.push(action.payload)
        //     state.requests.totalCount++
        //     state.loading = false
        // },
        // updateExistingRequest: (state, action) => {
        //     const index = state.requests.data.findIndex(
        //         (request) => request.transferRequestId === action.payload.transferRequestId
        //     )
        //     if (index >= 0) state.requests.data[index] = action.payload
        //     state.loading = false
        // },
        // removeRequest: (state, action) => {
        //     const index = state.requests.data.findIndex(
        //         (request) => request.transferRequestId === action.payload.transferRequestId
        //     )
        //     if (index >= 0) {
        //         state.requests.data.splice(index, 1)
        //         state.requests.totalCount--
        //     }
        //     state.loading = false
        // },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.transferRequestId === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getRequests,
    getRequestItems,
    getRequestActivityLogs,
    getExistingRequestById,
    getRequestLookup,
    updateExistingTransferRequestStatus,
    // addNewRequest,
    // updateExistingRequest,
    // removeRequest,
} = requestSlice.actions
export default requestSlice.reducer

export const getRequestList = (filter, refresh = false) => {
    let url = `/transfer/request/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getRequests.type,
        onError: apiRequestedFailed.type,
    })
}

export const getRequestById = (filter) => {
    let url = `/transfer/request/${filter.transferRequestId}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingRequestById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getRequestItemsList = (filter) => {
    let url = `/transfer/request/${filter.transferRequestId}/items`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getRequestItems.type,
        onError: apiRequestedFailed.type,
    })
}
export const getRequestAuditLogsList = (filter) => {
    let url = `/transfer/request/${filter.transferRequestId}/activity`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getRequestActivityLogs.type,
        onError: apiRequestedFailed.type,
    })
}
export const getRequestLookupList = (filter) => {
    let url = `/transfer/request/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getRequestLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateTransferRequestStatus = (request) => {
    let url = `/transfer/request/update/status`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: request,
        onStart: apiRequested.type,
        onSuccess: updateExistingTransferRequestStatus.type,
        onError: apiRequestedFailed.type,
    })
}

// export const addRequest = (request) => {
//     let url = `/request/request/add`
//     return apiCallBegan({
//         url: url,
//         method: 'post',
//         data: request,
//         onStart: apiRequested.type,
//         onSuccess: addNewRequest.type,
//         onError: apiRequestedFailed.type,
//     })
// }
//
// export const updateRequest = (request) => {
//     let url = `/request/request/update`
//     return apiCallBegan({
//         url: url,
//         method: 'PUT',
//         data: request,
//         onStart: apiRequested.type,
//         onSuccess: updateExistingRequest.type,
//         onError: apiRequestedFailed.type,
//     })
// }
//
// export const deleteTransaction = (request) => {
//     let url = '/request/request/remove'
//     return apiCallBegan({
//         url: url,
//         method: 'DELETE',
//         data: request,
//         onStart: apiRequested.type,
//         onSuccess: removeRequest.type,
//         onError: apiRequestedFailed.type,
//     })
// }
