import React from 'react'
import AutoNumberAppContext from './AutoNumberAppContext'
import { useNavigate, useParams } from 'react-router-dom'

const AutoNumberAppProvider = ({ children }) => {
    const { selectedId, tabTitle } = useParams()
    const [activeAutoNumber, setActiveAutoNumber] = React.useState({
        category: tabTitle,
        id: selectedId ? selectedId : 'inventory',
    })
    const [activeAutoNumberColorList, setActiveAutoNumberColorList] = React.useState([])
    const activeAutoNumberRef = React.useRef()
    const inventoryAutoNumberListRef = React.useRef()
    const shipmentAutoNumberListRef = React.useRef()
    const salesAutoNumberListRef = React.useRef()
    const orderAutoNumberListRef = React.useRef()

    const contextValue = React.useMemo(
        () => ({
            activeAutoNumber: activeAutoNumber,
            setActiveAutoNumber: setActiveAutoNumber,
            activeAutoNumberColorList: activeAutoNumberColorList,
            setColorActiveAutoNumber: setActiveAutoNumberColorList,
            shipmentAutoNumberListRef: shipmentAutoNumberListRef,
            activeAutoNumberRef: activeAutoNumberRef,
            salesAutoNumberListRef: salesAutoNumberListRef,
            inventoryAutoNumberListRef: inventoryAutoNumberListRef,
            orderAutoNumberListRef: orderAutoNumberListRef,
        }),
        [
            activeAutoNumber,
            orderAutoNumberListRef,
            salesAutoNumberListRef,
            activeAutoNumberColorList,
            shipmentAutoNumberListRef,
            activeAutoNumberRef,
            inventoryAutoNumberListRef,
        ]
    )

    React.useEffect(() => {
        const found = activeAutoNumberColorList.find(function (element) {
            return element.id === activeAutoNumber?.id
        })
        // Reset the list as we will be setting a new id
        const resetList = activeAutoNumberColorList.map((c, i) => {
            return { id: c.id, color: 'text.secondary' }
        })

        // Add or update
        if (found) {
            const newList = resetList.map((c, i) => {
                if (c.id === activeAutoNumber?.id) {
                    return { id: c.id, color: 'primary.main' }
                }
                return { id: c.id, color: 'text.secondary' }
            })
            setActiveAutoNumberColorList(newList)
        } else {
            let newItem = { id: activeAutoNumber?.id, color: 'primary.main' }
            let updatedItems = [...resetList, newItem]
            setActiveAutoNumberColorList(updatedItems)
        }
    }, [setActiveAutoNumberColorList, activeAutoNumber])

    return <AutoNumberAppContext.Provider value={contextValue}>{children}</AutoNumberAppContext.Provider>
}

export default AutoNumberAppProvider
