import jwtAuthAxios from '../../../auth/jwtAuth'

const autoNumberServices = {}

autoNumberServices.getAutoNumberList = async () => {
    return {
        autoNumber: [
            {
                id: 'inventory',
                name: 'Inventory',
                category: 'autonumbers',
                description: 'This Auto Number contains Inventory autoNo info',
            },
            {
                id: 'supplier',
                name: 'Supplier',
                category: 'autonumbers',
                description: 'This Auto Number contains Supplier autoNo info',
            },
            {
                id: 'customer',
                name: 'Customer',
                category: 'autonumbers',
                description: 'This Auto Number contains Customer autoNo info',
            },
            {
                id: 'transferRequest',
                name: 'Transfer Request',
                category: 'autonumbers',
                description: 'This Auto Number contains TransferRequest autoNo info',
            },
            {
                id: 'transferInventory',
                name: 'Transfer Inventory',
                category: 'autonumbers',
                description: 'This Auto Number contains Transfer autoNo info',
            },
            {
                id: 'shipment',
                name: 'Shipment',
                category: 'autonumbers',
                description: 'This Auto Number contains Shipment autoNo info',
            },
            {
                id: 'order',
                name: 'Order',
                category: 'autonumbers',
                description: 'This Auto Number contains Order autoNo info',
            },
            {
                id: 'inventoryBatch',
                name: 'Inventory Batch',
                category: 'autonumbers',
                description: 'This Auto Number contains InventoryBatch autoNo info',
            },
            {
                id: 'transaction',
                name: 'Transaction',
                category: 'autonumbers',
                description: 'This Auto Number contains Transaction autoNo info',
            },
            {
                id: 'discount',
                name: 'Discount',
                category: 'autonumbers',
                description: 'This Auto Number contains Discount autoNo info',
            },
        ],
    }
}

export default autoNumberServices
