import React from 'react'
import Div from '@jumbo/shared/Div'
import useReportApp from '../../hooks/useReportApp'

const ActiveReportFooter = () => {
    const { activeReport } = useReportApp()

    return <Div>{/*<h1>I am Active report footer</h1>*/}</Div>
}

export default ActiveReportFooter
