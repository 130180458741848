import jwtAuthAxios from 'app/services/auth/jwtAuth'
import { buildQuery } from '../../../../utils/appHelpers'
const requestServices = {}

requestServices.getTransferRequestLookupList = async (queryParams) => {
    let url = buildQuery(`/transfer/request/lookup/list`, queryParams)
    const { data } = await jwtAuthAxios.get(url).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

requestServices.getTransferRequestLookupById = async (key, queryParams) => {
    let url = buildQuery(`/transfer/request/lookup/${key}`, queryParams)
    const { data } = await jwtAuthAxios.get(url).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default requestServices
