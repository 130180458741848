import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, {
    ButtonItem,
    SimpleItem,
    EmptyItem,
    ColCountByScreen,
    GroupItem,
    TabbedItem,
    TabPanelOptions,
    Tab,
    Label,
} from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'

const UpdateSupplierForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { loading } = useSelector((state) => state.suppliers)

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/store/shipments/suppliers'),
    }

    const validationRules = {
        companyName: [{ type: 'required', message: 'Company name is required.' }],
        firstName: [{ type: 'required', message: 'First name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Email is invalid.' },
        ],
        phoneNumber: [{ type: 'required', message: 'Phone  number is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Update Supplier
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.updateSupplierGrid)} cssClass='form-group-button-width'>
                    <Form
                        mode='form'
                        formData={props.supplierData}
                        showColonAfterLabel={false}
                        cssClass='form-group-button-width'>
                        <GroupItem>
                            <TabbedItem>
                                <TabPanelOptions deferRendering={false} />
                                <Tab title=' Supplier Details'>
                                    <GroupItem>
                                        <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                                        <GroupItem caption='Personal Info' cssClass='form-group-item'>
                                            <GroupItem cssClass='form-group-item-margin'>
                                                <SimpleItem dataField='isTaxExempted' editorType='dxCheckBox'>
                                                    <Label text='Tax Exempted' />
                                                </SimpleItem>
                                                <SimpleItem dataField='supplierCode' />
                                                <SimpleItem
                                                    dataField='companyName'
                                                    validationRules={validationRules.companyName}
                                                />
                                                <SimpleItem
                                                    dataField='firstName'
                                                    validationRules={validationRules.firstName}
                                                />
                                                <SimpleItem dataField='lastName' />
                                                <SimpleItem dataField='barcode' />
                                                <SimpleItem dataField='abn'>
                                                    <Label text='ABN' />
                                                </SimpleItem>
                                                <SimpleItem dataField='email' validationRules={validationRules.email} />
                                                <SimpleItem
                                                    dataField='fkSupplierCompanyId'
                                                    editorType='dxSelectBox'
                                                    editorOptions={props.companyEditorOptions}>
                                                    <Label text='Supplier Company' />
                                                </SimpleItem>
                                            </GroupItem>
                                        </GroupItem>
                                        <GroupItem>
                                            <GroupItem caption='Contact Info' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem
                                                        dataField='phoneNo'
                                                        validationRules={validationRules.phoneNumber}
                                                    />
                                                    <SimpleItem dataField='addressLine1'>
                                                        <Label text='Address Line One' />
                                                    </SimpleItem>
                                                    <SimpleItem dataField='addressLine2'>
                                                        <Label text='Address Line two' />
                                                    </SimpleItem>
                                                </GroupItem>
                                            </GroupItem>
                                            <GroupItem caption='State Info' cssClass='form-group-item '>
                                                <GroupItem cssClass='form-group-item-margin'>
                                                    <SimpleItem dataField='country' />
                                                    <SimpleItem dataField='state' />
                                                    <SimpleItem dataField='suburb' />
                                                    <SimpleItem dataField='postCode' />
                                                </GroupItem>
                                            </GroupItem>
                                        </GroupItem>
                                    </GroupItem>
                                </Tab>
                            </TabbedItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateSupplierForm
