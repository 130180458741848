export const defaultCustomer = {
    customerCode: '',
    firstName: '',
    lastName: '',
    email: '',
    taxFileNumber: null,
    mobileNo: '',
    addressLine1: '',
    addressLine2: '',
    suburb: '',
    state: '',
    postCode: '',
    country: '',
    isWholesaleCustomer: false,
    isTaxExempted: false,
    isLoyaltyEnabled: false,
    comments: '',
    cnIcNumber: '',
    area: '',
    city: '',
    fkCustomerCategoryId: null,
}
