import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { getCompanyLookupList } from 'app/redux/store/features/company'
import { getBranchLookupList } from 'app/redux/store/features/branches'
import { getUserProfile } from 'app/redux/store/features/profile'
import utilServices from '../../../../services/util-services'
import { PatternRule, RequiredRule } from 'devextreme-react/data-grid'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const EditProfileForm = (props) => {
    const dispatch = useDispatch()
    const { handleSubmit } = useForm()
    const { companyLookups } = useSelector((state) => state.companies)
    const { branchLookup } = useSelector((state) => state.branches)
    const { profile } = useSelector((state) => state.profile)
    const { authUser } = useJumboAuth()
    const [selection, setSelection] = useState({
        companyId: null,
        branchId: null,
    })

    useEffect(() => {
        dispatch(getUserProfile())
    }, [])

    const [profileState, setProfileState] = useState({ ...profile })

    useEffect(() => {
        setProfileState({ ...profile })
    }, [profile])

    useEffect(() => {
        if (companyLookups.totalCount <= 0 && authUser != null && !authUser.isAdmin) dispatch(getCompanyLookupList(''))
    }, [authUser])

    useEffect(() => {
        let compId = profileState.currentOrgId
        if (selection.companyId) compId = selection.companyId

        if (!utilServices.isNullOrUndefined(compId)) {
            dispatch(getBranchLookupList(compId))
        }
    }, [profileState.currentOrgId, selection.companyId])

    const companyTypeStore = new DataSource({
        store: new ArrayStore({
            key: 'companyId',
            data: companyLookups.data,
            totalCount: companyLookups.totalCount,
        }),
        reshapeOnPush: true,
    })

    const branchLookupStore = new DataSource({
        store: new ArrayStore({
            key: 'branchId',
            data: branchLookup.data,
            totalCount: branchLookup.length,
        }),
        reshapeOnPush: true,
    })

    const onCompanyValueChanged = (e) => {
        setSelection({
            ...selection,
            companyId: e.value,
        })
    }

    const onBranchValueChanged = (e) => {
        setSelection({
            ...selection,
            branchId: e.value,
        })
    }

    const branchLookupEditorOptions = {
        dataSource: branchLookupStore,
        displayExpr: 'branchName',
        valueExpr: 'branchId',
        searchEnabled: true,
        showClearButton: true,
        onValueChanged: onBranchValueChanged,
    }

    const companyTypeLookupEditorOptions = {
        dataSource: companyTypeStore,
        displayExpr: 'companyName',
        valueExpr: 'companyId',
        searchEnabled: true,
        showClearButton: true,
        onValueChanged: onCompanyValueChanged,
    }

    const saveButtonOptions = {
        text: 'Save Changes',
        type: 'danger',
        width: 180,
        icon: 'check',
        useSubmitBehavior: true,
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }
    const phonePattern = /^\+\d{10,15}$/

    const validationRules = {
        firstName: [{ type: 'required', message: 'First Name is required.' }],
        lastName: [{ type: 'required', message: 'Last Name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Enter a valid email.' },
        ],
        currentOrgId: [{ type: 'required', message: 'Please select a company.' }],
        currentBranchId: [{ type: 'required', message: 'Please select a branch.' }],
    }

    const onSubmit = async () => {
        props.updateProfile(profileState)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Profile Settings
                    </Typography>
                }
                sx={{ m: 0.5, mt: 1 }}
                headerSx={{
                    height: 40,
                    bgcolor: 'secondary.main',
                }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form mode='form' formData={profileState} showColonAfterLabel={false} showValidationSummary={false}>
                        <GroupItem caption='Edit Profile' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <GroupItem>
                                    <SimpleItem dataField='firstName' validationRules={validationRules.firstName} />
                                    <SimpleItem dataField='lastName' validationRules={validationRules.lastName} />
                                    <SimpleItem dataField='phoneNo' editorOptions={phoneEditorOptions}>
                                        <RequiredRule message='Phone number is required.' />
                                        <PatternRule
                                            message='phone number must start with + and have minimum 10 digits'
                                            pattern={phonePattern}
                                        />
                                    </SimpleItem>
                                    <SimpleItem dataField='birthDate' editorType='dxDateBox' />
                                    <SimpleItem dataField='email' validationRules={validationRules.email} />
                                    {!(authUser?.isAdmin ?? false) && (
                                        <SimpleItem
                                            dataField='currentOrgId'
                                            editorType='dxSelectBox'
                                            editorOptions={companyTypeLookupEditorOptions}
                                            validationRules={validationRules.currentOrgId}>
                                            <Label text='Default Company' />
                                        </SimpleItem>
                                    )}
                                    {!(authUser?.isAdmin ?? false) && (
                                        <SimpleItem
                                            dataField='currentBranchId'
                                            editorType='dxSelectBox'
                                            editorOptions={branchLookupEditorOptions}
                                            validationRules={validationRules.currentBranchId}>
                                            <Label text='Default Branch' />
                                        </SimpleItem>
                                    )}
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={1} md={1} sm={1} xs={1} />
                            <ButtonItem horizontalAlignment='center' buttonOptions={saveButtonOptions} />
                        </GroupItem>
                        <EmptyItem />
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}
export default EditProfileForm
