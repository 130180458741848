import React, { useState, useEffect } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from 'recharts'
import { Chip, Typography } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { formatDate } from 'app/utils/components/FormatDateTime/FormatDateTime'

const BannerLeft = () => {
    const { admin } = useSelector((state) => state.dashboard)
    const [yAxisDomain, setYAxisDomain] = useState([0, 100])

    const formatYAxis = (tickItem) => {
        return `${tickItem}`
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip'>
                    <p className='label'>{formatDate(label)}</p>
                    <p className='data'>{`License Count: ${payload[0].value}`}</p>
                </div>
            )
        }

        return null
    }

    useEffect(() => {
        let minTotalCount = Infinity
        let maxTotalCount = -Infinity

        admin.leftPanel.data.forEach((item) => {
            if (item.count < minTotalCount) {
                minTotalCount = item.count
            }
            if (item.count > maxTotalCount) {
                maxTotalCount = item.count
            }
        })

        setYAxisDomain([Math.floor(minTotalCount), Math.ceil(maxTotalCount)])
    }, [admin.leftPanel.data])

    return (
        <Grid container>
            <Grid item xs={12}>
                {admin && admin.leftPanel ? (
                    <JumboCardQuick
                        title={
                            <Typography fontFamily='Lobster, cursive' variant={'h3'} mb={0} color={'info.dark'}>
                                Renewed Branch Licenses
                            </Typography>
                        }
                        action={<Chip label={'Last ' + 30 + ' days'} color={'info'} size={'small'} />}
                        wrapperSx={{ pt: 0 }}>
                        <ResponsiveContainer height={200} debounce={200}>
                            <LineChart data={admin.leftPanel.data}>
                                <Tooltip content={<CustomTooltip />} cursor={false} />
                                <XAxis
                                    dataKey='branchDate'
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={formatDate}
                                />
                                <YAxis domain={yAxisDomain} margin={{ bottom: 20 }} tickFormatter={formatYAxis} />
                                <Legend />
                                <Line
                                    type='monotone'
                                    dataKey='count'
                                    name='License Count'
                                    stroke='#3EC3D0'
                                    strokeWidth={2}
                                    dot={{ stroke: '#3EC3D0', fill: '#fff', strokeWidth: 2, r: 4 }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </JumboCardQuick>
                ) : (
                    <div>Loading...</div>
                )}
            </Grid>
        </Grid>
    )
}

export default BannerLeft
