import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'
import { getInventories } from './inventory'

const initialState = {
    sale: {
        headerBar: [
            {
                id: 1,
                title: 'Total Sale',
                from: null, // '2024-02-18T08:54:36.885148+00:00'
                totalValue: 0,
                orderBy: 1,
            },
            {
                id: 3,
                title: 'Total Payment',
                from: null,
                totalValue: 0,
                orderBy: 2,
            },
            {
                id: 4,
                title: 'Cash Payment',
                from: null,
                totalValue: 0,
                orderBy: 3,
            },
            {
                id: 5,
                title: 'Credit Payment',
                from: null,
                totalValue: 0,
                orderBy: 4,
            },
        ],
        leftPanel: {
            totalCount: 0,
            data: [], // { saleDate: '', totalSale:'', totalPayment: ''}
        },
        rightPanel: {
            totalCount: 0,
            data: [], // { saleDate: '', totalCashSale:'', totalCreditSale: ''}
        },
        shipmentList: {
            totalCount: 0,
            data: [],
        },
        transactionList: {
            totalCount: 0,
            data: [],
        },
    },
    admin: {
        headerBar: [
            {
                id: 1,
                title: 'Total Companies',
                totalValue: 0,
                orderBy: 1,
            },
            {
                id: 2,
                title: 'Epos Branches',
                totalValue: 0,
                orderBy: 2,
            },
            {
                id: 3,
                title: 'Spiral Branches',
                totalValue: 0,
                orderBy: 3,
            },
            {
                id: 4,
                title: 'Licensed Expired',
                totalValue: 0,
                orderBy: 4,
            },
        ],
        leftPanel: {
            // Recently Renewed Branches
            totalCount: 0,
            data: [], // { branchDate: '', count: 0}
        },
        rightPanel: {
            // Expiring Soon Branches
            totalCount: 0,
            data: [], // { branchDate: '', count: 0}
        },
        renewedBranches: {
            totalCount: 0,
            data: [],
        },
        expiringSoon: {
            totalCount: 0,
            data: [], // {}
        },
    },
    loading: false,
    error: null,
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        getSalesDashboard: (state, action) => {
            state.sale = action.payload
            state.loading = false
        },
        getSalesTxn: (state, action) => {
            state.sale.transactionList = action.payload
            state.loading = false
        },
        getSalesShipment: (state, action) => {
            state.sale.shipmentList = action.payload
            state.loading = false
        },
        getAdminDashboard: (state, action) => {
            state.admin = action.payload
            state.loading = false
        },
        getAdminRenewed: (state, action) => {
            state.admin.renewedBranches = action.payload
            state.loading = false
        },
        getAdminExpiring: (state, action) => {
            state.admin.expiringSoon = action.payload
            state.loading = false
        },
    },
})

export const {
    apiRequested,
    apiRequestedFailed,
    getSalesDashboard,
    getSalesTxn,
    getSalesShipment,
    getAdminDashboard,
    getAdminRenewed,
    getAdminExpiring,
} = dashboardSlice.actions

export const getDashboardSales = (filter) => {
    let url = `/Dashboard/sale/summary`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSalesDashboard.type,
        onError: apiRequestedFailed.type,
    })
}

export const getDashboardTxn = (filter) => {
    let url = `/Dashboard/sale/txn/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSalesTxn.type,
        onError: apiRequestedFailed.type,
    })
}

export const getDashboardShipments = (filter) => {
    let url = `/Dashboard/sale/shipment/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getSalesShipment.type,
        onError: apiRequestedFailed.type,
    })
}

export const getAdminDashboardSummary = (filter) => {
    let url = `/Dashboard/admin/summary`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getAdminDashboard.type,
        onError: apiRequestedFailed.type,
    })
}

export const getAdminRenewedList = (filter) => {
    let url = `/Dashboard/admin/renewed/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getAdminRenewed.type,
        onError: apiRequestedFailed.type,
    })
}

export const getAdminExpiringSoon = (filter) => {
    let url = `/Dashboard/admin/expiring/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getAdminExpiring.type,
        onError: apiRequestedFailed.type,
    })
}

export default dashboardSlice.reducer
