import jwtAuthAxios from 'app/services/auth/jwtAuth'
const transactionServices = {}

transactionServices.getTransactionLookupList = async (filter) => {
    let url = filter?.txnNumber
        ? `/sales/Transaction/lookup/list?search=${filter.txnNumber}&onlineTxn=${filter.onlineTransactions}`
        : `/sales/Transaction/lookup/list?onlineTxn=${filter.onlineTransactions}`
    const { data } = await jwtAuthAxios.get(url, filter).catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            console.log(error.request)
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

transactionServices.getTransactionLookupById = async (key) => {
    let url = `/sales/Transaction/lookup/${key}`
    const { data } = await jwtAuthAxios.get(url, '').catch(function (error) {
        if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw Error('request timed out')
        } else {
            console.log('Error', error.message)
        }
        console.log(error.config)
        throw Error(error.message)
    })
    return data
}

export default transactionServices
