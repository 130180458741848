import React from 'react'
import Dashboard from '../pages/home/'
import Page from '@jumbo/shared/Page'

const homeRoutes = [
    {
        path: '/dashboard',
        element: <Page component={Dashboard} />,
    },
]

export default homeRoutes
