import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import OnlineTransactionsGridFilter from './components/OnlineTransactionsGridFilter'
import OnlineTransactionsData from './components/OnlineTransactionsData'
import { useDispatch, useSelector } from 'react-redux'
import { getTransactionList } from 'app/redux/store/features/transactions'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'
import { LoadPanel } from 'devextreme-react/load-panel'
import Div from '@jumbo/shared/Div'

const OnlineTransactions = () => {
    const pageSize = 25
    const position = { of: '#dataGrid' }
    const [filter, setFilter] = useState({
        txnStartDate: DateUtils.getStartDate(10),
        txnEndDate: DateUtils.getEndDate(),
        customerFirstName: '',
        customerLastName: '',
        onlineTransactions: true,
        sort: 'TxnStartDate',
        isDescending: true,
    })

    const [currentPage, setCurrentPage] = useState(0)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const dispatch = useDispatch()
    const { refreshing } = useSelector((state) => state.transactions)

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getTransactionList({ ...filter, skip: 0, take: pageSize }))
    }, [dispatch])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function onlineTransactionReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }
            default:
                return state
        }
    }

    const [onlineTransactionList, setOnlineTransactionList] = React.useReducer(
        onlineTransactionReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            dispatch(getTransactionList({ ...currentfilter, skip: 0, take: pageSize }, true))
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getTransactionList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const columnName = sortOrder[0].columnName.charAt(0).toUpperCase() + sortOrder[0].columnName.slice(1)
        const currentFilter = {
            ...filter,
            sort: columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getTransactionList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getTransactionList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setOnlineTransactionList({ type: 'set-grid-filter', payload: value })
        },
        [setOnlineTransactionList]
    )

    const setFilterIconColor = React.useCallback(
        (value) => {
            setOnlineTransactionList({ type: 'set-filter-icon-color', payload: value })
        },
        [setOnlineTransactionList]
    )

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        onClick={onRefresh}
                        fontSize={'large'}
                        sx={{
                            mb: -1.2,
                            mr: 0.5,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    Online Txns Details
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'secondary.main',
                pl: 1,
            }}
            action={
                <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                    <AddIcon
                        size={'large'}
                        fontSize={'large'}
                        variant={'contained'}
                        color={'#e0e0e0'}
                        sx={{
                            pt: 0,
                            pb: 0,
                            color: '#e0e0e0',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    <FilterAltIcon
                        fontSize='large'
                        sx={{
                            mt: -1.8,
                            color:
                                onlineTransactionList.filterIconColor && !onlineTransactionList.gridFilterVisible
                                    ? 'success.main'
                                    : 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => setGridFilter(!onlineTransactionList.gridFilterVisible)}
                    />
                </Stack>
            }>
            <Grid>
                <OnlineTransactionsGridFilter
                    mutation={applyFilter}
                    display={onlineTransactionList.gridFilterVisible ? '' : 'none'}
                    filterIconColor={(data) => setFilterIconColor(data)}
                />
            </Grid>
            <Div id='dataGrid'>
                <Grid>
                    <OnlineTransactionsData
                        onRefresh={onRefresh}
                        onPageChange={onPageChange}
                        onColumnSort={onColumnSort}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        apiCallInProgress={apiCallInProgress}
                        setApiCallInProgress={setApiCallInProgress}
                    />
                </Grid>
                <LoadPanel shadingColor='rgba(0,0,0,0.4)' position={position} visible={refreshing} />
            </Div>
        </JumboCardQuick>
    )
}

export default OnlineTransactions
