import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import pdfMake from 'pdfmake'
import Div from '@jumbo/shared/Div'
import { Pagination, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import DownloadIcon from '@mui/icons-material/Download'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from 'notistack'
import vfs from '../../../../../../../../fonts/NimbusSanL/nimbus-san'
import '../../../../../reportstyles.css'
import InventoryLoadFormReportFilter from './InventoryLoadFormReportFilter'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'

const InventoryLoadFormReportLayout = ({
    imageData,
    viewMutation,
    pdfMutation,
    pageMutation,
    apiCallInProgress,
    setApiCallInProgress,
    pdfData,
}) => {
    const { inventoryLoadForm, loading, error } = useSelector((state) => state.reports)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [isFilterVisible, setFilterVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pdfDownloading, setPdfDownloading] = useState(false)
    const [pdfTemplate, setpdfTemplate] = useState({})
    const itemsPerPage = 25

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    pdfMake.vfs = vfs
    pdfMake.fonts = {
        NimbusSans: {
            normal: 'NimbusSanL-Reg.otf',
            bold: 'NimbusSanL-Bol.otf',
            italics: 'NimbusSanL-RegIta.otf',
            bolditalics: 'NimbusSanL-BolIta.otf',
        },
    }

    const handlePageChange = async (page) => {
        try {
            await pageMutation.mutate(page)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error updating page:', error)
        }
    }

    const handleFilterClick = () => {
        setFilterVisible(!isFilterVisible)
    }

    const headers = React.useMemo(
        () => ['Product Name', 'Barcode', 'QTY CTN', 'QTY', 'Return Qty CTN', 'Return Qty', 'Sale Item Value'],
        []
    )

    const parseToPdfData = useMemo(
        () => (data) => {
            return data.map((d) => {
                return [
                    d.inventoryName,
                    d.itemBarcode,
                    d.qtyCTN,
                    d.qty,
                    d.returnQtyCTN,
                    d.returnQty,
                    formatCurrency(d.saleItemValue),
                ]
            })
        },
        []
    )

    const pageRow = [
        { text: 'Total', style: 'rowHeading' },
        { text: pdfData.reportSummary?.qtyCTN, style: 'cell' },
        { text: pdfData.reportSummary?.qty, style: 'cell' },
        { text: pdfData.reportSummary?.returnQty, style: 'cell' },
        { text: pdfData.reportSummary?.returnQtyCTN, style: 'cell' },
        { text: formatCurrency(pdfData.reportSummary?.saleItemValue), style: 'rightAlign' },
    ]

    const tableBody =
        pdfData?.data?.length > 0
            ? parseToPdfData(pdfData.data).map((row) =>
                  row.map((cell, index) => ({
                      text: cell,
                      style: [
                          'cell',
                          index === 0 || index === 1
                              ? 'leftAlign'
                              : index === 2 || index === 3 || index === 4 || index === 5
                              ? 'center'
                              : 'rightAlign',
                      ],
                  }))
              )
            : []

    const pdfTemplateDefault = React.useMemo(() => {
        return {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [10, 45, 15, 45],
            footer: function (currentPage, pageCount) {
                const isLastPage = currentPage === pageCount
                if (!isLastPage) {
                    return {
                        columns: [
                            {
                                table: {
                                    headerRows: 0,
                                    widths: [349, 80, 80, 90, 75, 85],
                                    body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                                },
                                margin: [15, 10, 15, 10],
                            },
                        ],
                    }
                }

                return {
                    columns: [
                        {
                            table: {
                                headerRows: 0,
                                widths: [349, 80, 80, 90, 75, 85],
                                body: [pageRow.map((cell) => ({ text: cell.text, style: cell.style }))],
                            },
                            margin: [15, 5, 15, 20],
                        },
                    ],
                }
            },
            content: [
                {
                    image: imageData,
                    width: 70,
                },
                {
                    text: 'Inventory Load Form  Report',
                    style: 'header',
                    alignment: 'center',
                },
                {
                    layout: {
                        fillColor: function (rowIndex) {
                            return rowIndex % 2 === 1 ? '#f2f2f2' : null
                        },
                    },
                    style: 'withMargin',
                    alignment: 'center',
                    table: {
                        headerRows: 1,
                        widths: [240, 100, 80, 80, 90, 75, 85],
                        body: [
                            headers.map((header, index) => {
                                return { text: header, style: 'headerRow' }
                            }),
                            ...tableBody,
                        ],
                    },
                },
            ],
            defaultStyle: {
                font: 'NimbusSans',
            },
            styles: {
                withMargin: {
                    margin: [5, 20, 20, 5],
                },
                header: {
                    fontSize: 18,
                    bold: true,
                },
                headerRow: {
                    fillColor: '#ADD8E6',
                    bold: true,
                    fontSize: 10,
                },
                cell: {
                    fontSize: 9,
                    alignment: 'center',
                },
                rightAlign: {
                    alignment: 'right',
                    fontSize: 9,
                },
                leftAlign: {
                    alignment: 'left',
                    fontSize: 9,
                },
                rowHeading: {
                    alignment: 'center',
                    fontSize: 10,
                },
            },
        }
    }, [imageData, headers, pdfData])

    useEffect(() => {
        const template = { ...pdfTemplateDefault }
        setpdfTemplate(template)
    }, [headers, pdfData, pdfTemplateDefault])

    const create = async () => {
        await pdfMutation.mutate()
        setPdfDownloading(true)
    }

    useEffect(() => {
        if (!pdfDownloading) return
        setPdfDownloading(false)
        const pdfDocGenerator = pdfMake.createPdf(pdfTemplate)
        let today = new Date()
        pdfDocGenerator.download(
            `Inventory_Load_Form_Report_${today.toLocaleDateString()}_${today.toLocaleTimeString()}`
        )
    }, [pdfTemplate])

    return (
        <Div>
            <h1 className='report-heading'>Inventory Load Form Report</h1>
            <LoadingButton
                sx={{ float: 'right', marginRight: '90px', marginTop: '-65px' }}
                onClick={create}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <DownloadIcon sx={{ fontSize: 'medium', marginRight: '2px' }} /> Download Report
            </LoadingButton>
            <LoadingButton
                sx={{ float: 'right', marginRight: '9px', marginTop: '-65px' }}
                onClick={handleFilterClick}
                size={'small'}
                type='submit'
                variant={'contained'}>
                <FilterAltIcon fontSize='medium' />
            </LoadingButton>
            {isFilterVisible && <InventoryLoadFormReportFilter mutation={viewMutation} />}
            <>
                <div className='report-container'>
                    {loading && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    {!loading && inventoryLoadForm.totalCount === 0 ? (
                        <Typography variant={'primary'}>No data. Select suitable filter to view data</Typography>
                    ) : (
                        <div className='custom-table'>
                            <table>
                                <tbody>
                                    <tr>
                                        {headers.map((h, index) => {
                                            return (
                                                <th
                                                    key={h}
                                                    style={{
                                                        whiteSpace: index === 2 || index === 5 ? 'pre' : 'pre-line',
                                                    }}>
                                                    {h}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                                <tbody>
                                    {inventoryLoadForm.data?.map((d, index) => {
                                        return (
                                            <tr key={index} style={{ fontSize: '13px' }}>
                                                <td style={{ width: '300px', textAlign: 'left' }}>
                                                    {d?.inventoryName}
                                                </td>
                                                <td style={{ width: '120px', textAlign: 'left' }}>{d?.itemBarcode}</td>
                                                <td style={{ width: '120px', textAlign: 'center' }}>{d?.qtyCTN}</td>
                                                <td style={{ width: '100px', textAlign: 'center' }}>{d?.qty}</td>
                                                <td style={{ width: '100px', textAlign: 'center' }}>
                                                    {d?.returnQtyCTN}
                                                </td>
                                                <td style={{ width: '120px', textAlign: 'center' }}>{d?.returnQty}</td>
                                                <td style={{ width: '140px', textAlign: 'right' }}>
                                                    {formatCurrency(d?.saleItemValue)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {inventoryLoadForm.reportSummary && (
                                        <tr style={{ fontSize: '13px' }}>
                                            <td colSpan={2} style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                Total
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {inventoryLoadForm.reportSummary.qtyCTN}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {inventoryLoadForm.reportSummary.qty}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {inventoryLoadForm.reportSummary.returnQtyCTN}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {inventoryLoadForm.reportSummary.returnQty}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {formatCurrency(inventoryLoadForm.reportSummary.saleItemValue)}
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td colSpan={headers.length} />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                    <Stack>
                        <Pagination
                            count={Math.ceil(inventoryLoadForm.totalCount / itemsPerPage)}
                            onChange={(event, page) => {
                                handlePageChange(page)
                            }}
                            page={currentPage}
                            siblingCount={1}
                            boundaryCount={1}
                            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px' }}
                            variant='outlined'
                        />
                    </Stack>
                </div>
            </>
        </Div>
    )
}

export default InventoryLoadFormReportLayout
