const today = new Date()

export const defaultTransfer = {
    transferNo: '',
    isSourceBranch: 0,
    barcode: '',
    transferRequiredDate: today,
    comments: '',
    refNumber: '',
    fkTransferRequestId: null,
    hasShippingCost: false,
    shippingCost: 0,
    shippingComments: '',
    fkSecondBranchId: null,
}
