import React from 'react'
import Div from '@jumbo/shared/Div'
import useReportApp from '../../hooks/useReportApp'

const ActiveReportHeader = () => {
    const { activeReport } = useReportApp()

    return <Div>{/*<h1>I am Active report header</h1>*/}</Div>
}

export default ActiveReportHeader
